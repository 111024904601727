import React from 'react'
import { Row, Col, Alert, Glyphicon } from 'react-bootstrap'
import LocalizedStrings from 'react-localization'
import styled from 'styled-components'

const strings = new LocalizedStrings({
  en: {
    exceededOilChangeMileage: 'Exceeded oil change mileage',
    exceededOilChangeDate: 'Exceeded oil change date',
    nearToBeExceededOilChangeMileage: 'Near to be exceeded oil change mileage',
    nearToBeExceededOilChangeDate: 'Near to be exceeded oil change date',
    exceededInspectionMileage: 'Exceeded inspection mileage',
    exceededInspectionDate: 'Exceeded inspection date',
    nearToBeExceededInspectionMileage: 'Near to be exceeded inspection mileage',
    nearToBeExceededInspectionDate: 'Near to be exceeded inspection date',
    exceededFumigationMileage: 'Exceeded fumigation mileage',
    exceededFumigationDate: 'Exceeded fumigation date',
    nearToBeExceededFumigationMileage: 'Near to be exceeded fumigation mileage',
    nearToBeExceededFumigationDate: 'Near to be exceeded fumigation date',
    exceededInsuranceMileage: 'Exceeded insurance mileage',
    exceededInsuranceDate: 'Exceeded insurance date',
    nearToBeExceededInsuranceMileage: 'Near to be exceeded insurance mileage',
    nearToBeExceededInsuranceDate: 'Near to be exceeded insurance date',
    exceededTimingGearMileage: 'Exceeded timing gear mileage',
    exceededTimingGearDate: 'Exceeded timing gear date',
    nearToBeExceededTimingGearMileage: 'Near to be exceeded timing gear mileage',
    nearToBeExceededTimingGearDate: 'Near to be exceeded timing gear date',
    exceededLpgApprovalMileage: 'Exceeded LPG approval gear mileage',
    exceededLpgApprovalDate: 'Exceeded LPG approval gear date',
    nearToBeExceededLpgApprovalMileage: 'Near to be exceeded LPG approval gear mileage',
    nearToBeExceededLpgApprovalDate: 'Near to be exceeded LPG approval gear date',
    kilometers: 'kilometers',
    days: 'days',
    past: 'past',
    left: 'left',
  },
  pl: {
    exceededOilChangeMileage: 'Przekroczono przebieg wymiany oleju',
    exceededOilChangeDate: 'Przekroczono termin wymiany oleju',
    nearToBeExceededOilChangeMileage: 'Blisko przekroczenia przebiegu wymiany oleju',
    nearToBeExceededOilChangeDate: 'Blisko przekroczenia terminu wymiany oleju',
    exceededInspectionMileage: 'Przekroczono przebieg przeglądu',
    exceededInspectionDate: 'Przekroczono termin przeglądu',
    nearToBeExceededInspectionMileage: 'Blisko przekroczenia przebiegu przeglądu',
    nearToBeExceededInspectionDate: 'Blisko przekroczenia terminu przeglądu',
    exceededFumigationMileage: 'Przekroczono przebieg odgrzybiania',
    exceededFumigationDate: 'Przekroczono termin odgrzybiania',
    nearToBeExceededFumigationMileage: 'Blisko przekroczenia przebiegu odgrzybiania',
    nearToBeExceededFumigationDate: 'Blisko przekroczenia terminu odgrzybiania',
    exceededInsuranceMileage: 'Przekroczono przebieg ubezpieczenia',
    exceededInsuranceDate: 'Przekroczono termin ważności ubezpieczenia',
    nearToBeExceededInsuranceMileage: 'Blisko przekroczenia przebiegu ubezpieczenia',
    nearToBeExceededInsuranceDate: 'Blisko przekroczenia terminu ważności ubezpieczenia',
    exceededTimingGearMileage: 'Przekroczono przebieg wymiany rozrządu',
    exceededTimingGearDate: 'Przekroczono termin wymiany rozrządu',
    nearToBeExceededTimingGearMileage: 'Blisko przekroczenia przebiegu wymiany rozrządu',
    nearToBeExceededTimingGearDate: 'Blisko przekroczenia terminu wymiany rozrządu',
    exceededLpgApprovalMileage: 'Przekroczono LPG przebieg homologacji LPG',
    exceededLpgApprovalDate: 'Przekroczono LPG termin ważności homologacji LPG',
    nearToBeExceededLpgApprovalMileage: 'Blisko przekroczenia przebiegu homologacji LPG',
    nearToBeExceededLpgApprovalDate: 'Blisko przekroczenia terminu ważności homologacji LPG',
    kilometers: 'km',
    days: 'dni',
    past: 'po',
    left: 'przed',
  },
  de: {
    exceededOilChangeMileage: 'Ölwechselintervall überschritten',
    exceededOilChangeDate: 'Das Ölwechseldatum wurde überschritten',
    nearToBeExceededOilChangeMileage: 'Der Ölwechsel ist fast überfällig',
    nearToBeExceededOilChangeDate: 'Ein Ölwechsel ist schon fast überfällig',
    exceededInspectionMileage: 'Inspektionszeit überschritten',
    exceededInspectionDate: 'Die Prüffrist wurde überschritten',
    nearToBeExceededInspectionMileage: 'Nahezu die Inspektionskilometerzahl überschritten',
    nearToBeExceededInspectionDate: 'Die Inspektionsfrist wird fast verpasst',
    exceededFumigationMileage: 'Der Pilzentfernungsprozess wurde überschritten',
    exceededFumigationDate: 'Die Frist für die Beseitigung der Begasung wurde überschritten',
    nearToBeExceededFumigationMileage: 'Wir sind kurz davor, den Schimmelbeseitigungsprozess zu übertreffen',
    nearToBeExceededFumigationDate: 'Die Frist für die Begasungsentfernung ist bald überschritten',
    exceededInsuranceMileage: 'Versicherungskilometer überschritten',
    exceededInsuranceDate: 'Die Versicherung ist abgelaufen',
    nearToBeExceededInsuranceMileage: 'Nahezu die Versicherungskilometer überschritten',
    nearToBeExceededInsuranceDate: 'Ihre Versicherung läuft bald aus',
    exceededTimingGearMileage: 'Die Kilometerzahl für den Austausch des Zahnriemens wurde überschritten',
    exceededTimingGearDate: 'Die Frist für den Austausch des Zahnriemens wurde überschritten',
    nearToBeExceededTimingGearMileage: 'Die Laufleistung für den Zahnriemenwechsel ist fast abgelaufen',
    nearToBeExceededTimingGearDate: 'Die Frist für den Zahnriemenwechsel ist fast abgelaufen',
    exceededLpgApprovalMileage: 'Die zulässige LPG-Kilometerzahl wurde überschritten',
    exceededLpgApprovalDate: 'Die Gültigkeitsdauer der LPG-Zulassung wurde überschritten',
    nearToBeExceededLpgApprovalMileage: 'Nahezu die LPG-Zulassungskilometerzahl überschritten',
    nearToBeExceededLpgApprovalDate: 'Das Ablaufdatum der LPG-Zulassung steht kurz vor der Überschreitung',
    kilometers: 'km',
    days: 'Tage',
    past: 'nach',
    left: 'vor',
  },
  fr: {
    exceededOilChangeMileage: "Intervalle de vidange d’huile dépassé",
    exceededOilChangeDate: "La date de vidange d’huile a été dépassée",
    nearToBeExceededOilChangeMileage: 'Proche du dépassement du kilométrage de vidange',
    nearToBeExceededOilChangeDate: "Presque en retard pour les vidanges d’huile",
    exceededInspectionMileage: "Délai d'inspection dépassé",
    exceededInspectionDate: "Le délai d'inspection a été dépassé",
    nearToBeExceededInspectionMileage: 'Presque dépassé le kilométrage d’inspection',
    nearToBeExceededInspectionDate: 'Presque manqué la date limite d’inspection',
    exceededFumigationMileage: 'Le processus d’élimination des champignons a été dépassé',
    exceededFumigationDate: 'Le délai de retrait par fumigation a été dépassé',
    nearToBeExceededFumigationMileage: 'Proche du dépassement du kilométrage de désinfection du système de ventilation',
    nearToBeExceededFumigationDate: 'Nous sommes sur le point de dépasser le processus d’élimination des moisissures',
    exceededInsuranceMileage: 'Kilométrage d’assurance dépassé',
    exceededInsuranceDate: 'L’assurance est expirée',
    nearToBeExceededInsuranceMileage: 'Presque dépassé le kilométrage d’assurance',
    nearToBeExceededInsuranceDate: 'Votre assurance est sur le point d’expirer',
    exceededTimingGearMileage: 'Le kilométrage de remplacement de la courroie de distribution a été dépassé',
    exceededTimingGearDate: 'Le délai de remplacement de la courroie de distribution est dépassé',
    nearToBeExceededTimingGearMileage: 'Le kilométrage de remplacement de la courroie de distribution est presque terminé',
    nearToBeExceededTimingGearDate: 'La date limite de remplacement de la courroie de distribution est presque dépassée',
    exceededLpgApprovalMileage: 'Le kilométrage d’homologation GPL a été dépassé',
    exceededLpgApprovalDate: 'La période de validité de l’homologation GPL a été dépassée',
    nearToBeExceededLpgApprovalMileage: 'Presque dépassé le kilométrage d’homologation GPL',
    nearToBeExceededLpgApprovalDate: 'La date d’expiration de l’agrément GPL est sur le point d’être dépassée',
    kilometers: 'km',
    days: 'jours',
    past: 'après',
    left: 'avant',
  }
});

function composeAlertMessage(messageType, matter, matterType) {
  let translationKey = messageType === 'error' ? 'exceeded' : 'nearToBeExceeded'
  switch (matter) {
    case 'oilChange': translationKey += 'OilChange'; break
    case 'inspection': translationKey += 'Inspection'; break
    case 'fumigation': translationKey += 'Fumigation'; break
    case 'insurance': translationKey += 'Insurance'; break
    case 'timingGear': translationKey += 'TimingGear'; break
    case 'lpgApproval': translationKey += 'LpgApproval'; break
    default: break
  }
  translationKey += matterType === 'mileage' ? 'Mileage' : 'Date'

  return strings.getString(translationKey)
}

function composeRangeInfoMessage(matterType, rangeLeft) {
  let message = rangeLeft <= 0 ? `${rangeLeft * -1} ` : `${rangeLeft} `
  message += matterType === 'mileage' ? strings.kilometers : strings.days
  message += ' '
  message += rangeLeft <= 0 ? strings.past : strings.left
  return message
}

const ClickableAlert = styled(Alert)`
  cursor: pointer;
`

export default function AlertsListItem(props) {
  const { alert } = { ...props }

  return (
    <ClickableAlert
      bsStyle={alert.messageType === 'warning' ? 'warning' : 'danger'}
      onClick={() => props.onAlertClicked(props.alert.carAffected._id)}>
      <Row>
        <Col xs={12}>
          <Glyphicon glyph={alert.matterType === 'mileage' ? 'road' : 'calendar'} /> {' '}
          <span>{composeAlertMessage(alert.messageType, alert.matter, alert.matterType)}</span>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <span><i>{composeRangeInfoMessage(alert.matterType, alert.rangeLeft)}</i></span>
        </Col>
      </Row>
    </ClickableAlert>
  )
}
