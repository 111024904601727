const initState = {
  regulations: [],
  newRegulation: {
    id: 'new',
    title: '',
    content: ''
  },
  isLoading: true
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'REGULATIONS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'REGULATIONS.ADD_REGULATION':
      return {
        ...state,
        regulations: [ ...state.regulations, action.regulation ]
      }
    case 'REGULATIONS.SET_REGULATIONS':
      return {
        ...state,
        regulations: action.regulations
      }
    case 'REGULATIONS.SET_NEW_REGULATION':
      return {
        ...state,
        newRegulation: action.newRegulation
      }
    default: return state
  }
}

export default reducer
