import React from 'react'
import o2d from 'date-from-object-id'
import { ListGroup, ListGroupItem, Badge } from 'react-bootstrap'

export default ({ regulations, onRegulationClicked }) => {
  return (
    <ListGroup>
      {
        regulations.map((regulation) => {
          regulation.revisions.sort((a, b) => a.version - b.version)
          const newestRevision = regulation.revisions[regulation.revisions.length - 1]

          return (
            <ListGroupItem
              key={regulation._id}
              header={regulation.title}
              onClick={() => onRegulationClicked(regulation._id)}>
              Added {o2d(regulation._id).toLocaleDateString()}
              <Badge className='pull-right'>Newest revision: { newestRevision.version } </Badge>
            </ListGroupItem>
          )
        })
      }
    </ListGroup>
  )
}
