import React from 'react'
import Table from './Table'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    date: 'Date',
    mileage: 'Mileage (km)',
    nextDate: 'Next date',
    nextMileage: 'Next mileage (km)',
  },
  pl: {
    date: 'Data',
    mileage: 'Przebieg (km)',
    nextDate: 'Data kolejnej wymiany',
    nextMileage: 'Maksymalny przebieg (km)',
  },
  de: {
    date: 'Datum',
    mileage: 'Kilometerstand (km)',
    nextDate: 'Nächstes Datum',
  },
  fr: {
    date: 'Date',
    mileage: 'Kilométrage (km)',
    nextDate: 'Prochaine date',
  }
})

export default ({ list }) => {
  let counter = 0
  return (
    <Table>
      <thead>
        <tr>
          <th>{strings.date}</th>
          <th>{strings.mileage}</th>
          <th>{strings.date}</th>
          <th>{strings.nextMileage}</th>
        </tr>
      </thead>
      <tbody>
        {
          list ? list.map((element) => {
            counter++
            return (
              <tr key={`${element.date}${counter}`}>
                <td>
                  {new Date(element.date).toLocaleDateString()}
                </td>
                <td>
                  {
                    element.mileage
                  }
                </td>
                <td>
                  {new Date(element.nextChangeDate).toLocaleDateString()}
                </td>
                <td>
                  {element.nextChangeMileage}
                </td>
              </tr>
            )
          }) : null
        }
      </tbody>
    </Table>
  )
}
