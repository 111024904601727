import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'
import LoginPage from './LoginPage/components/LoginPage'
import Dashboard from './Dashboard/components/Dashboard'

class App extends Component {
  render () {
    return (
      <div className='App'>
        <Route exact path='/' render={() => (<Redirect to='/login' />)} />
        <Route path='/login' component={LoginPage} />
        <Route path='/dashboard' component={Dashboard} />
        <Route exact path='/dashboard' render={() => (<Redirect to='/dashboard/cars' />)} />
      </div>
    )
  }
}

export default App
