import React, { Component } from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import withApiUrl from '../../../../components/common/hoc/withApiUrl'

class DocumentScan extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: true }
  }

  render() {
    const { imagePath, apiUrl } = this.props

    return (
      <Grid style={{ width: '100%' }}>
        <Row>
          <Col md={10}>
            <LoadingIcon isLoading={this.state.isLoading} style={{ margin: '24px auto', display: 'block' }} />
            <img
              style={{ width: '100%', display: this.state.isLoading ? 'none' : 'block' }}
              src={`${apiUrl}${imagePath}?width=2000`}
              alt='Document scan'
              onLoad={() => this.setState({ isLoading: false })}
            />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default withApiUrl(DocumentScan)
