export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    setCarManufacturers: (carManufacturers) => {
      dispatch(setCarManufacturers(carManufacturers))
    },
    downloadCarManufacturers: () => {
      dispatch(downloadCarManufacturers())
    },
    downloadCarManufacturer: (id) => {
      dispatch(downloadCarManufacturer(id))
    }
  }
}

export function updateLoadingState (isLoading) {
  return {
    type: 'CAR_MANUFACTURERS.SET_LOADING',
    isLoading
  }
}

export function setCarManufacturers (carManufacturers) {
  return {
    type: 'CAR_MANUFACTURERS.SET_CAR_MANUFACTURERS',
    carManufacturers
  }
}

export function downloadCarManufacturers () {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getCarManufacturers()
      .then((response) => {
        if (!response.error) dispatch(setCarManufacturers(response.data.carManufacturers))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadCarManufacturer (id) {
  return (dispatch, getState, api) => {
    const { carManufacturers } = getState()
    dispatch(updateLoadingState(true))
    return api.getCarManufacturerById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasCarManufacturerFound = false
        const carManufacturersList = carManufacturers.carManufacturers
        // replace existing carManufacturer
        for (let i = 0; i < carManufacturersList.length; i++) {
          if (carManufacturersList[i]._id === id) {
            carManufacturersList[i] = response.data
            wasCarManufacturerFound = true
          }
        }

        // or add new if not existent
        if (!wasCarManufacturerFound) carManufacturersList.push(response.data)

        dispatch(setCarManufacturers(carManufacturersList))
        dispatch(updateLoadingState(false))
      })
  }
}
