import React from 'react'
import { Button, Glyphicon } from 'react-bootstrap'

export default (props) => {
  const { disabled, onDeleteQuestion } = props
  return (
    <Button
      style={{ ...props.style }}
      disabled={disabled}
      onClick={onDeleteQuestion}>
      <Glyphicon glyph='trash' />
    </Button>
  )
}
