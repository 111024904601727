import React from 'react'
import { FormControl, FormGroup, Panel, Collapse } from 'react-bootstrap'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import Logo from '../../components/common/Logo'
import NarrowContainer from '../../components/common/containers/NarrowContainer'
import LoadableButton from '../../components/common/LoadableButton'
import AuthChecker from '../../components/common/AuthChecker'
import getActions from '../actions/loginPageActions'

class LoginPage extends React.Component {
  constructor (props) {
    super(props)
    this.state = { autoAuthPerformed: false }
  }

  onLoginClicked = event => {
    this.props.doLogin()
  }

  handleKeyDownOnInput = ev => {
    if (ev.keyCode === 13) {
      // enter pressed, submit login data
      this.props.doLogin()
    }
  }

  usernameChanged = event => {
    this.props.updateCredentials(event.target.value, this.props.password)
    this.props.setNewPasswordIsRequired(false)
  }

  passwordChanged = event => {
    this.props.updateCredentials(this.props.login, event.target.value)
    this.props.setNewPasswordIsRequired(false)
  }

  newPasswordChanged = event => {
    this.props.setNewPassword(event.target.value)
  }

  onAuthOk = () => {
    this.setState({ autoAuthPerformed: true })
    this.props.history.push('/dashboard')
  }

  onNoAuth = () => {
    this.setState({ autoAuthPerformed: true })
  }

  render () {
    let errorPanel
    if (this.props.errorMessage) {
      errorPanel = (
        <Panel bsStyle='danger'>
          <Panel.Heading>
            <Panel.Title componentClass='h3'>Error</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            {this.props.errorMessage}
          </Panel.Body>
        </Panel>
      )
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}>
        {
          !this.props.auth && !this.state.autoAuthPerformed
            ? <AuthChecker onAuthOk={this.onAuthOk} onNoAuth={this.onNoAuth} /> : null
        }
        {
          this.props.auth
            ? <Redirect to='/dashboard' /> : null
        }

        <NarrowContainer>
          <Logo />
          {errorPanel}
          <FormGroup>
            <FormControl
              type='text'
              placeholder='Enter username'
              value={this.props.login}
              onChange={this.usernameChanged}
              onKeyDown={this.handleKeyDownOnInput}
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type='password'
              placeholder='Enter password'
              value={this.props.password}
              onChange={this.passwordChanged}
              onKeyDown={this.handleKeyDownOnInput}
            />
          </FormGroup>
          <Collapse in={this.props.newPasswordIsRequired}>
            <FormGroup>
              <FormControl
                type='password'
                placeholder='Enter new password'
                value={this.props.newPassword}
                onChange={this.newPasswordChanged}
                onKeyDown={this.handleKeyDownOnInput}
              />
            </FormGroup>
          </Collapse>

          <LoadableButton
            block
            bsStyle='primary'
            onClick={this.onLoginClicked}
            isLoading={this.props.isLoading}
          >
            Login
          </LoadableButton>
        </NarrowContainer>
      </div>
    )
  }
}

function mapStoreStateToProps ({ loginPage }) {
  return loginPage
}

function mapActionsToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapActionsToProps)(LoginPage)
