import { swap, reorderQuestions } from './helper'

export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addQuestionnaire: (questionnaire) => {
      dispatch(addQuestionnaire(questionnaire))
    },
    setQuestionnaires: (questionnaires) => {
      dispatch(setQuestionnaires(questionnaires))
    },
    setNewQuestionnaire: (id, questions, title) => {
      dispatch(setNewQuestionnaire(id, questions, title))
    },
    resetNewQuestionnaire: () => {
      dispatch(resetNewQuestionnaire())
    },
    editQuestionContentInNewQuestionnaire: (questionOrder, newContent, oldQuestionnaire) => {
      dispatch(editQuestionContentInNewQuestionnaire(questionOrder, newContent, oldQuestionnaire))
    },
    editQuestionAnswerTypeInNewQuestionnaire: (questionOrder, newAnswerType, oldQuestionnaire) => {
      dispatch(editQuestionAnswerTypeInNewQuestionnaire(questionOrder, newAnswerType, oldQuestionnaire))
    },
    addNewBlankQuestionToNewQuestionnaire: (oldQuestionnaire) => {
      dispatch(addNewBlankQuestionToNewQuestionnaire(oldQuestionnaire))
    },
    changeQuestionsOrderInNewQuestionnaire: (questionOrder, orderChange, oldQuestionnaire) => {
      dispatch(changeQuestionsOrderInNewQuestionnaire(questionOrder, orderChange, oldQuestionnaire))
    },
    deleteQuestionInNewQuestionnaire: (questionOrder, oldQuestionnaire) => {
      dispatch(deleteQuestionInNewQuestionnaire(questionOrder, oldQuestionnaire))
    },
    changeNewQuestionnaireTitle: (newTitle, oldQuestionnaire) => {
      dispatch(changeNewQuestionnaireTitle(newTitle, oldQuestionnaire))
    },
    downloadQuestionnaires: () => {
      dispatch(downloadQuestionnaires())
    },
    downloadQuestionnaire: (id) => {
      dispatch(downloadQuestionnaire(id))
    },
    postQuestionnaire: () => {
      dispatch(postQuestionnaire())
    },
    putQuestionnaire: () => {
      dispatch(putQuestionnaire())
    }
  }
}

export function updateLoadingState (isLoading) {
  return { type: 'QUESTIONNAIRES.SET_LOADING', isLoading }
}

export function addQuestionnaire (questionnaire) {
  return {
    type: 'QUESTIONNAIRES.ADD_QUESTIONNAIRE',
    questionnaire
  }
}

export function setQuestionnaires (questionnaires) {
  return {
    type: 'QUESTIONNAIRES.SET_QUESTIONNAIRES',
    questionnaires
  }
}

export function setNewQuestionnaire (id, questions, title) {
  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function resetNewQuestionnaire () {
  const newQuestionnaire = {
    id: 'new',
    questions: [
      {
        order: 1,
        content: '',
        answerType: 'boolean'
      }
    ],
    title: ''
  }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function editQuestionContentInNewQuestionnaire (questionOrder, newContent, oldQuestionnaire) {
  const { id, questions, title } = { ...oldQuestionnaire }
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].order === questionOrder) {
      questions[i] = {
        ...questions[i],
        content: newContent
      }
      break
    }
  }

  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function editQuestionAnswerTypeInNewQuestionnaire (questionOrder, newAnswerType, oldQuestionnaire) {
  const { id, questions, title } = { ...oldQuestionnaire }
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].order === questionOrder) {
      questions[i] = {
        ...questions[i],
        answerType: newAnswerType
      }
      break
    }
  }

  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function addNewBlankQuestionToNewQuestionnaire (oldQuestionnaire) {
  const { id, questions, title } = { ...oldQuestionnaire }
  questions.push({
    order: questions.length + 1,
    content: '',
    answerType: 'boolean'
  })

  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function changeQuestionsOrderInNewQuestionnaire (questionOrder, orderChange, oldQuestionnaire) {
  const { id, questions, title } = { ...oldQuestionnaire }
  let questionAIndex, questionBIndex

  for (let i = 0; i < questions.length; i++) {
    if (questions[i].order === questionOrder) questionAIndex = i
    if (questions[i].order === questionOrder + orderChange) questionBIndex = i

    if (questionAIndex && questionBIndex) break
  }

  swap(questions, questionAIndex, questionBIndex)
  reorderQuestions(questions)
  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function deleteQuestionInNewQuestionnaire (questionOrder, oldQuestionnaire) {
  const { id, questions, title } = { ...oldQuestionnaire }
  for (let i = 0; i < questions.length; i++) {
    if (questions[i].order === questionOrder) {
      questions.splice(i, 1)
      break
    }
  }
  reorderQuestions(questions)
  const newQuestionnaire = { id, questions, title }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function changeNewQuestionnaireTitle (newTitle, oldQuestionnaire) {
  const { id, questions } = { ...oldQuestionnaire }
  const newQuestionnaire = { id, questions, title: newTitle }
  return {
    type: 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE',
    newQuestionnaire
  }
}

export function downloadQuestionnaires () {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getQuestionnaires()
      .then((response) => {
        if (!response.error) dispatch(setQuestionnaires(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadQuestionnaire (id) {
  return (dispatch, getState, api) => {
    const { questionnaires } = getState()
    dispatch(updateLoadingState(true))
    return api.getQuestionnaireById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasQuestionnaireFound = false
        const questionnairesList = questionnaires.questionnaires
        // replace existing questionnaire
        for (let i = 0; i < questionnairesList.length; i++) {
          if (questionnairesList[i]._id === id) {
            questionnairesList[i] = response.data
            wasQuestionnaireFound = true
          }
        }

        // or add new if not existent
        if (!wasQuestionnaireFound) questionnairesList.push(response.data)

        dispatch(setQuestionnaires(questionnairesList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postQuestionnaire () {
  return (dispatch, getState, api) => {
    const { newQuestionnaire } = getState().questionnaires
    dispatch(updateLoadingState(true))
    return api.postQuestionnaire(newQuestionnaire)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download questionnaires since user will be
        // pushed from editor to questionnaires list.
        // Also downloadQuestionnaires will handle
        // updateLoadingState
        dispatch(downloadQuestionnaires())
      })
  }
}

export function putQuestionnaire () {
  return (dispatch, getState, api) => {
    const { newQuestionnaire } = getState().questionnaires
    dispatch(updateLoadingState(true))
    return api.putQuestionnaire(newQuestionnaire.id, newQuestionnaire)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download questionnaires since user will be
        // pushed from editor to questionnaires list.
        // Also downloadQuestionnaires will handle
        // updateLoadingState
        dispatch(downloadQuestionnaires())
      })
  }
}
