import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Panel, ControlLabel, Fade, Alert, Button, Checkbox } from 'react-bootstrap'
import { Redirect, Link } from 'react-router-dom'
import FieldGroup from '../../../../components/common/FieldGroup'
import Select from './Select'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import FAB from '../../../../components/common/FAB'
import { MdSave as SaveIcon } from 'react-icons/md'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import 'react-day-picker/lib/style.css'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import Questionnaire from './Questionnaire'
import ImageUpload from '../../../../components/common/ImageUpload'
import CarAvatar from './CarAvatar'
import getCarManufacturersActions from '../../../CarManufacturers/actions/carManufacturerActions'
import getFuelTypesActions from '../../../FuelTypes/actions/fuelTypeActions'
import getCarModelsActions from '../../../CarModels/actions/carModelsActions'
import getQuestionnairesActions from '../../../Questionnaires/actions/questionnairesActions'
import getCarsActions from '../../actions/carsActions'
import getRegulationsActions from '../../../Regulations/actions/regulationsActions'
import api, { apiServerUrl } from '../../../../api'
import DocumentScan from './DocumentScan'

class CreateCar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      readmeEditorState: EditorState.createEmpty(),
      adminReadmeEditorState: EditorState.createEmpty(),
      isLoading: true
    }
  }

  componentDidMount() {
    const isThisNewCar = !this.props.match.params.id

    if (isThisNewCar) this.props.setCarsLoading(isThisNewCar)
    this.setLoadingStateForAllDataSources(true)
    Promise
      .all([
        api.getFuelTypes(),
        api.getCarManufacturers(),
        api.getCarModels(),
        api.getRegulations(),
        api.getQuestionnaires()
      ])
      .then((responses) => {
        this.props.setFuelTypes(responses[0].data.fuelTypes)
        this.props.setCarManufacturers(responses[1].data.carManufacturers)
        this.props.setCarModels(responses[2].data)
        this.props.setRegulations(responses[3].data)
        this.props.setQuestionnaires(responses[4].data)

        this.setLoadingStateForAllDataSources(false)
        // reset car only if previous id is not equal to 'new'
        // If it is equal, that means a clone option is being used so no reset is required
        if (isThisNewCar) {
          if (this.props.newCar.id !== 'cloned') this.props.resetNewCar()
          // apply the content that was cloned
          this.setReadmeTextEditorContent(this.props.newCar.readme)
          this.setAdminReadmeTextEditorContent(this.props.newCar.adminReadme)
          this.props.setCarsLoading(false)
        } else {
          const { id } = this.props.match.params
          api.getCarById(id)
            .then(async (response) => {
              const requestedCar = response.data
              requestedCar.id = id
              requestedCar.currentMileage = requestedCar.mileageRecords[requestedCar.mileageRecords.length - 1].mileage
              requestedCar.inspection = { ...requestedCar.inspectionsHistory[requestedCar.inspectionsHistory.length - 1] }
              requestedCar.inspectionYearsInterval = new Date(requestedCar.inspection.nextInspectionDate).getFullYear() - new Date(requestedCar.inspection.date).getFullYear()
              requestedCar.fumigation = { ...requestedCar.fumigationHistory[requestedCar.fumigationHistory.length - 1] }
              requestedCar.insurance = { ...requestedCar.insuranceHistory[requestedCar.insuranceHistory.length - 1] }
              requestedCar.insuranceDocumentScan = requestedCar.insuranceDocumentScans.length > 0 ? { ...requestedCar.insuranceDocumentScans[requestedCar.insuranceDocumentScans.length - 1] } : {}
              requestedCar.proofOfRegistrationScan = requestedCar.proofOfRegistrationScans.length > 0 ? { ...requestedCar.proofOfRegistrationScans[requestedCar.proofOfRegistrationScans.length - 1] } : {}
              requestedCar.timingGearChange = { ...requestedCar.timingGearChangesHistory[requestedCar.timingGearChangesHistory.length - 1] }
              requestedCar.timingGearChange.changeMileageFrequency = requestedCar.timingGearChange.nextChangeMileage - requestedCar.timingGearChange.mileage
              requestedCar.oilChange = { ...requestedCar.oilChangesHistory[requestedCar.oilChangesHistory.length - 1] }
              requestedCar.oilChange.changeMileageFrequency = requestedCar.oilChange.nextChangeMileage - requestedCar.oilChange.mileage

              if (requestedCar.lpgApprovalHistory) {
                requestedCar.lpgApproval = { ...requestedCar.lpgApprovalHistory[requestedCar.lpgApprovalHistory.length - 1] }
                requestedCar.lpgApproval.approvalMileageFrequency = requestedCar.lpgApproval.nextApprovalMileage - requestedCar.lpgApproval.mileage
              }

              const latestTransfer = await api.getLatestTransferById(requestedCar.transfersHistoryId)
              requestedCar.questionnaireId = latestTransfer.data.questionnaireId

              // set requested revision as new car
              this.props.setNewCar(requestedCar)
              this.setReadmeTextEditorContent(requestedCar.readme)
              this.setAdminReadmeTextEditorContent(requestedCar.adminReadme)
              this.props.setCarsLoading(false)
            })
        }
      })
  }

  setReadmeTextEditorContent = (markdownContent) => {
    this.setState({
      readmeEditorState:
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(markdownContent)
          )
        )
    })
  }

  setAdminReadmeTextEditorContent = (markdownContent) => {
    this.setState({
      adminReadmeEditorState:
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(markdownContent)
          )
        )
    })
  }

  setLoadingStateForAllDataSources = (isLoading) => {
    this.props.setFuelTypesLoading(isLoading)
    this.props.setCarManufacturersLoading(isLoading)
    this.props.setCarModelsLoading(isLoading)
    this.props.setRegulationsLoading(isLoading)
    this.props.setQuestionnairesLoading(isLoading)
    this.setState({ isLoading })
  }

  findCarById = (id) => {
    return this.props.cars.filter((car) => car._id === id)[0]
  }

  findQuestionnaireById = (id) => {
    return this.props.questionnaires.filter((questionnaire) => questionnaire._id === id)[0]
  }

  findFuelTypeById = (id) => {
    return this.props.fuelTypes.find((fuelType) => fuelType._id === id)
  }

  getNewestRevisionOfQuestionnaire = (questionnaire) => {
    return questionnaire.revisions[questionnaire.revisions.length - 1]
  }

  mapCarManufacturersToSelect = (carManufacturers) => {
    return carManufacturers.map((manufacturer) => {
      return { value: manufacturer._id, name: manufacturer.name }
    })
  }

  mapFuelTypesToSelect = (fuelTypes) => {
    return fuelTypes.map((fuelType) => {
      return { value: fuelType._id, name: fuelType.name }
    })
  }

  mapCarModelsToSelect = (carModels) => {
    return carModels.map((carModel) => {
      return { value: carModel._id, name: `${carModel.carBodyType} - ${carModel.carName}` }
    })
  }

  mapRegulationsToSelect = (regulations) => {
    return regulations.map((regulation) => {
      return { value: regulation._id, name: regulation.title }
    })
  }

  mapQuestionnairesToSelect = (questionnaires) => {
    return questionnaires.map((questionnaire) => {
      return { value: questionnaire._id, name: questionnaire.title }
    })
  }

  mapInspectionYearsInterval = () => {
    const names = ['1 year', '2 years', '3 years', '4 years', '5 years']

    return [1, 2, 3, 4, 5].map((years) => {
      return { value: years, name: names[years - 1] }
    })
  }

  getTireTypesForSelect = () => {
    return [
      {
        value: 'summer',
        name: '☀️ Summer'
      }, {
        value: 'winter',
        name: '❄️ Winter'
      }, {
        value: 'all-season',
        name: '♾️ All-season'
      }
    ]
  }

  handleManufacturerChange = (manufacturerId) => {
    const car = { ...this.props.newCar }
    car.manufacturerId = manufacturerId
    this.props.setNewCar(car)
  }

  handleCarModelNameChange = (event) => {
    const car = { ...this.props.newCar }
    car.model = event.target.value
    this.props.setNewCar(car)
  }

  handleFuelTypeChange = (fuelTypeId) => {
    const car = { ...this.props.newCar }
    car.fuelTypeId = fuelTypeId

    if (this.findFuelTypeById(fuelTypeId).isLPG && !car.lpgApproval) {
      car.lpgApproval = {
        'date': new Date(),
        'approvalValidUntil': new Date()
      }
    }

    this.props.setNewCar(car)
  }

  handleCarModelChange = (carModelId) => {
    const car = { ...this.props.newCar }
    car.carModelId = carModelId
    this.props.setNewCar(car)
  }

  handleRegulationChange = (regulationId) => {
    const car = { ...this.props.newCar }
    car.regulationId = regulationId
    this.props.setNewCar(car)
  }

  handleQuestionnaireChange = (questionnaireId) => {
    const car = { ...this.props.newCar }
    car.questionnaireId = questionnaireId
    car.questionnaireAnswers = this.mapQuestionnaireToQuestionnaireAnswersObject(
      this.findQuestionnaireById(questionnaireId)
    )
    this.props.setNewCar(car)
  }

  mapQuestionnaireToQuestionnaireAnswersObject = (questionnaire) => {
    return this.getNewestQuestionnaireRevision(questionnaire).map((question) => {
      let defaultAnswer = ''
      if (question.answerType === 'number') defaultAnswer = 0
      if (question.answerType === 'boolean') defaultAnswer = true

      return {
        questionOrder: question.order,
        content: question.content,
        answerType: question.answerType,
        answer: defaultAnswer
      }
    })
  }

  onAnswerChanged = (event) => {
    const questionOrder = parseInt(
      event.target.id.replace('answer-', ''),
      10
    )

    if (event.target.type !== 'number') {
      const newContent = event.target.value

      if (event.target.type === 'select-one') {
        this.props.editQuestionAnswerInNewCar(
          questionOrder,
          newContent === 'true',
          this.props.newCar
        )
      } else {
        this.props.editQuestionAnswerInNewCar(
          questionOrder,
          newContent,
          this.props.newCar
        )
      }
    } else {
      const number = parseInt(event.target.value, 10)
      if (!isNaN(number)) {
        this.props.editQuestionAnswerInNewCar(
          questionOrder,
          number,
          this.props.newCar
        )
      } else {
        this.props.editQuestionAnswerInNewCar(
          questionOrder,
          0,
          this.props.newCar
        )
      }
    }
  }

  isQuestionnaireAnswersReady = () => {
    const isThisNewCar = !this.props.match.params.id

    if (!isThisNewCar) return true

    const { questionnaireAnswers } = this.props.newCar
    if (!questionnaireAnswers) return false

    for (let i = 0; i < questionnaireAnswers.length; i++) {
      if (questionnaireAnswers[i].answer.toString() === '') {
        return false
      }
    }
    return true
  }

  getNewestQuestionnaireRevision = (questionnaire) => {
    return questionnaire.revisions[questionnaire.revisions.length - 1].questions
  }

  handleColorChange = (event) => {
    const car = { ...this.props.newCar }
    car.color = event.target.value
    this.props.setNewCar(car)
  }

  handleLicensePlateChange = (event) => {
    const car = { ...this.props.newCar }
    car.licensePlate = event.target.value
    this.props.setNewCar(car)
  }

  handleVinChange = (event) => {
    const car = { ...this.props.newCar }
    car.vin = event.target.value.substring(0, 17)
    this.props.setNewCar(car)
  }

  handleEnginePower = (event) => {
    const car = { ...this.props.newCar }
    car.engine.power = parseInt(event.target.value, 10)
    if (!isNaN(car.engine.power)) {
      this.props.setNewCar(car)
    } else {
      car.engine.power = 0
      this.props.setNewCar(car)
    }
  }

  handleEngineCapacity = (event) => {
    const car = { ...this.props.newCar }
    const value = event.target.value.replace(/,/g, '.')
    if (value.indexOf('.') === value.length - 1) {
      car.engine.capacity = value
      this.props.setNewCar(car)
      return
    }
    car.engine.capacity = parseFloat(event.target.value, 10)
    if (!isNaN(car.engine.capacity)) {
      this.props.setNewCar(car)
    } else {
      const carToUpdate = { ...car }
      carToUpdate.engine.capacity = 0
      this.props.setNewCar(carToUpdate)
    }
  }

  handleTiresTypeChange = (tireType) => {
    const car = { ...this.props.newCar }
    car.tires = tireType
    this.props.setNewCar(car)
  }

  handleArchiveChanged = (event) => {
    const car = { ...this.props.newCar }
    car.archived = event.target.checked
    this.props.setNewCar(car)
  }

  handleCarCommonNick = (event) => {
    const car = { ...this.props.newCar }
    car.common = { ...car.common, nick: event.target.value }
    this.props.setNewCar(car)
  }

  handleNewCommonAvatar = (imagePath) => {
    const car = { ...this.props.newCar }
    car.common = { ...car.common, avatar: imagePath }
    this.props.setNewCar(car)
  }

  clearCarCommonAvatar = () => {
    const car = { ...this.props.newCar }
    delete car.common.avatar
    this.props.setNewCar(car)
  }

  handleMileageChange = (event) => {
    const car = { ...this.props.newCar }
    car.currentMileage = parseInt(event.target.value, 10)
    if (!isNaN(car.currentMileage)) this.props.setNewCar(car)
    else {
      car.currentMileage = 0
      this.props.setNewCar(car)
    }
  }

  handleLastOilChangeMileage = (event) => {
    const car = { ...this.props.newCar }
    car.oilChange.mileage = parseInt(event.target.value, 10)
    if (!isNaN(car.oilChange.mileage)) {
      car.oilChange.nextChangeMileage = car.oilChange.mileage + car.oilChange.changeMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.oilChange.mileage = 0
      car.oilChange.nextChangeMileage = car.oilChange.changeMileageFrequency
      this.props.setNewCar(car)
    }
  }

  handleOilChangeMileageFrequency = (event) => {
    const car = { ...this.props.newCar }
    car.oilChange.changeMileageFrequency = parseInt(event.target.value, 10)
    if (!isNaN(car.oilChange.changeMileageFrequency)) {
      // change mileage frequency needs to be multiplied times 1000
      // because of simplification in the form field
      car.oilChange.changeMileageFrequency *= 1000
      car.oilChange.nextChangeMileage = car.oilChange.mileage + car.oilChange.changeMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.oilChange.changeMileageFrequency = 10000
      car.oilChange.nextChangeMileage = car.oilChange.changeMileageFrequency
      this.props.setNewCar(car)
    }
  }

  handleProductionDate = (date) => {
    const car = { ...this.props.newCar }
    car.productionDate = date
    this.props.setNewCar(car)
  }

  handleLastOilChangeDate = (date) => {
    const car = { ...this.props.newCar }
    try {
      const nextChangeDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())
      car.oilChange.date = date
      car.oilChange.nextChangeDate = nextChangeDate
      this.props.setNewCar(car)
    } catch (e) { }
  }

  handleLastInspectionDate = (date) => {
    const car = { ...this.props.newCar }
    try {
      const nextInspectionDate = new Date(date.getFullYear() + car.inspectionYearsInterval, date.getMonth(), date.getDate())
      car.inspection.date = date
      car.inspection.nextInspectionDate = nextInspectionDate
      this.props.setNewCar(car)
    } catch (e) { }
  }

  handleInspectionYearsInterval = (yearsInterval) => {
    const car = { ...this.props.newCar }
    const inspectionYearsInterval = parseInt(yearsInterval)
    const date = new Date(car.inspection.date)
    const nextInspectionDate = new Date(date.getFullYear() + inspectionYearsInterval, date.getMonth(), date.getDate())
    car.inspectionYearsInterval = inspectionYearsInterval
    car.inspection.nextInspectionDate = nextInspectionDate
    this.props.setNewCar(car)
  }

  handleLastFumigationDate = (date) => {
    const car = { ...this.props.newCar }
    try {
      const nextFumigationDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())
      car.fumigation.date = date
      car.fumigation.nextFumigationDate = nextFumigationDate
      this.props.setNewCar(car)
    } catch (e) { }
  }

  handleInsuranceDate = (date) => {
    const car = { ...this.props.newCar }
    try {
      const validUntilDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate() - 1)
      car.insurance.date = date
      car.insurance.validUntilDate = validUntilDate
      this.props.setNewCar(car)
    } catch (e) { }
  }

  handleLastTimingGearChangeMileage = (event) => {
    const car = { ...this.props.newCar }
    car.timingGearChange.mileage = parseInt(event.target.value, 10)
    if (!isNaN(car.timingGearChange.mileage)) {
      car.timingGearChange.nextChangeMileage = car.timingGearChange.mileage + car.timingGearChange.changeMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.timingGearChange.mileage = 0
      car.timingGearChange.nextChangeMileage = 30000
      this.props.setNewCar(car)
    }
  }

  handleTimingGearChangeMileageFrequency = (event) => {
    const car = { ...this.props.newCar }
    car.timingGearChange.changeMileageFrequency = parseInt(event.target.value, 10)
    if (!isNaN(car.timingGearChange.changeMileageFrequency)) {
      // change mileage frequency needs to be multiplied times 1000
      // because of simplification in the form field
      car.timingGearChange.changeMileageFrequency *= 1000
      car.timingGearChange.nextChangeMileage = car.timingGearChange.mileage + car.timingGearChange.changeMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.timingGearChange.changeMileageFrequency = 100000
      car.timingGearChange.nextChangeMileage = car.timingGearChange.changeMileageFrequency
      this.props.setNewCar(car)
    }
  }

  handleLpgApprovalDate = (date) => {
    const car = { ...this.props.newCar }
    try {
      const approvalValidUntil = new Date(date.getFullYear() + 5, date.getMonth(), date.getDate())
      car.lpgApproval.date = date
      car.lpgApproval.approvalValidUntil = approvalValidUntil
      this.props.setNewCar(car)
    } catch (e) { }
  }

  handleLastLpgApprovalMileage = (event) => {
    const car = { ...this.props.newCar }
    car.lpgApproval.mileage = parseInt(event.target.value, 10)
    if (!isNaN(car.lpgApproval.mileage)) {
      car.lpgApproval.nextApprovalMileage = car.lpgApproval.mileage + car.lpgApproval.approvalMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.lpgApproval.mileage = 0
      car.lpgApproval.nextApprovalMileage = car.lpgApproval.approvalMileageFrequency
      this.props.setNewCar(car)
    }
  }

  handleOilLpgApprovalFrequency = (event) => {
    const car = { ...this.props.newCar }
    car.lpgApproval.approvalMileageFrequency = parseInt(event.target.value, 10)
    if (!isNaN(car.lpgApproval.approvalMileageFrequency)) {
      // approval mileage frequency needs to be multiplied times 1000
      // because of simplification in the form field
      car.lpgApproval.approvalMileageFrequency *= 1000
      car.lpgApproval.nextApprovalMileage = car.lpgApproval.mileage + car.lpgApproval.approvalMileageFrequency
      this.props.setNewCar(car)
    } else {
      car.lpgApproval.approvalMileageFrequency = 10000
      car.lpgApproval.nextApprovalMileage = car.lpgApproval.approvalMileageFrequency
      this.props.setNewCar(car)
    }
  }

  handleNewInsuranceDocumentScan = (imagePath) => {
    const car = { ...this.props.newCar }
    car.insuranceDocumentScan = { scan: imagePath }
    this.props.setNewCar(car)
  }

  clearInsuranceDocumentScan = () => {
    const car = { ...this.props.newCar }
    delete car.insuranceDocumentScan.scan
    this.props.setNewCar(car)
  }

  handleNewProofOfRegistrationDocumentScan = (imagePath) => {
    const car = { ...this.props.newCar }
    car.proofOfRegistrationScan = { scan: imagePath }
    this.props.setNewCar(car)
  }

  clearProofOfRegistrationDocumentScan = (imagePath) => {
    const car = { ...this.props.newCar }
    delete car.proofOfRegistrationScan.scan
    this.props.setNewCar(car)
  }

  onReadmeEditorChanged = (readmeEditorState) => {
    this.setState({ readmeEditorState })
    this.onReadmeContentChanged(
      draftToMarkdown(
        convertToRaw(
          readmeEditorState.getCurrentContent()
        )
      )
    )
  }

  onReadmeContentChanged = (content) => {
    const car = { ...this.props.newCar }
    car.readme = content
    this.props.setNewCar(car)
  }

  onAdminReadmeEditorChanged = (adminReadmeEditorState) => {
    this.setState({ adminReadmeEditorState })
    this.onAdminReadmeContentChanged(
      draftToMarkdown(
        convertToRaw(
          adminReadmeEditorState.getCurrentContent()
        )
      )
    )
  }

  onAdminReadmeContentChanged = (content) => {
    const car = { ...this.props.newCar }
    car.adminReadme = content
    this.props.setNewCar(car)
  }

  isCarLpg = () => {
    const selectedFuelType = this.findFuelTypeById(this.props.newCar.fuelTypeId)
    return selectedFuelType ? selectedFuelType.isLPG : false
  }

  isCarDataComplete = () => {
    const { newCar } = this.props
    return (
      newCar.model.length > 0 &&
      newCar.color.length > 0 &&
      newCar.licensePlate.length > 0 &&
      newCar.currentMileage.toString().length > 0 &&
      this.isQuestionnaireAnswersReady()
    )
  }

  saveCar = () => {
    const { id } = this.props.newCar
    if (id === 'new' || id === 'cloned') {
      this.addNewCar()
    } else {
      this.updateCar()
    }
  }

  addNewCar = () => {
    this.props.postCar()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewCar()
  }

  updateCar = () => {
    this.props.putCar()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewCar()
  }

  buildMissingMessage(isQuestionnaireMissing, isRegulationMissing, isCarModelMissing) {
    return (<Alert bsStyle='warning' style={{ maxWidth: 320, width: '100%', margin: 'auto', height: '40vh' }}>
      <h3>
        No {isQuestionnaireMissing ? 'questionnaires, ' : ''} {isRegulationMissing ? 'regulations, ' : ''} {isCarModelMissing ? isQuestionnaireMissing || isRegulationMissing ? 'and carModels' : 'carModels' : ''} found.
      </h3>
      Those are essential for making a car. Please create at least one of each missing element and try creating a car again.
      Here're some quick links for you:
      <ul>
        {isQuestionnaireMissing ? <li><Link to='/dashboard/questionnaires'>Questionnaires</Link></li> : ''}
        {isRegulationMissing ? <li><Link to='/dashboard/regulations'>Regulations</Link></li> : ''}
        {isCarModelMissing ? <li><Link to='/dashboard/carModels'>Car models</Link></li> : ''}
      </ul>
    </Alert>)
  }

  render() {
    const { newCar, carManufacturers, fuelTypes, carModels, regulations, questionnaires } = this.props
    // if mileage records are defined do not allow to edit
    // current mileage input
    const isThisNewCar = !this.props.match.params.id

    const isQuestionnaireMissing = questionnaires.length === 0
    const isRegulationMissing = regulations.length === 0
    const isCarModelMissing = carModels.length === 0

    if (!this.state.isLoading) {
      if (isQuestionnaireMissing || isRegulationMissing || isCarModelMissing) return this.buildMissingMessage(isQuestionnaireMissing, isRegulationMissing, isCarModelMissing)
      else {
        return (
          <Grid>

            {
              newCar.created
                ? <Redirect to={`/dashboard/car/${this.props.match.params.id || this.props.newCar._id}`} /> : null
            }

            <Row>
              <h1>{isThisNewCar ? 'Create' : 'Edit'} a car</h1>
              <Col md={6}>
                {
                  newCar.error && newCar.error.length > 0
                    ? (<Panel bsStyle='warning'>
                      <Panel.Heading>
                        <Panel.Title>
                          Error
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        {newCar.error}
                      </Panel.Body>
                    </Panel>) : null
                }
              </Col>
            </Row>
            <Row>
              <h4>Car details</h4>
              <Col md={3}>
                <Select
                  label='Car manufacturer'
                  values={this.mapCarManufacturersToSelect(carManufacturers)}
                  currentValue={newCar.manufacturerId}
                  onSelect={this.handleManufacturerChange}
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='Car model name'
                  placeholder='eg. Octavia'
                  value={newCar.model}
                  onChange={this.handleCarModelNameChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Select
                  label='Fuel type'
                  values={this.mapFuelTypesToSelect(fuelTypes)}
                  currentValue={newCar.fuelTypeId}
                  onSelect={this.handleFuelTypeChange}
                />
              </Col>
              <Col md={3}>
                <Select
                  label='Car model'
                  values={this.mapCarModelsToSelect(carModels)}
                  currentValue={newCar.carModelId}
                  onSelect={this.handleCarModelChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Select
                  label='Regulation'
                  values={this.mapRegulationsToSelect(regulations)}
                  currentValue={newCar.regulationId}
                  onSelect={this.handleRegulationChange}
                />
              </Col>
              <Col md={3}>
                <Select
                  label='Questionnaire'
                  values={this.mapQuestionnairesToSelect(questionnaires)}
                  currentValue={newCar.questionnaireId}
                  onSelect={this.handleQuestionnaireChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FieldGroup
                  label='Color'
                  placeholder='eg. Red'
                  onChange={this.handleColorChange}
                  value={newCar.color}
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='License plate'
                  placeholder='eg. SGL 123AB'
                  onChange={this.handleLicensePlateChange}
                  value={newCar.licensePlate}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <ControlLabel>Production date</ControlLabel>
                <br />
                { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                <DayPickerInput
                  type='number'
                  onDayChange={debounce(this.handleProductionDate, 1000)}
                  value={new Date(newCar.productionDate)}
                  dateFormat='d MMMM yyyy'
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='VIN (optional)'
                  placeholder='eg. ZAMGJ45A390047326'
                  onChange={this.handleVinChange}
                  value={newCar.vin}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FieldGroup
                  label='Engine power (horsepower)'
                  placeholder='eg. 210'
                  onChange={this.handleEnginePower}
                  value={newCar.engine.power}
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='Engine capacity'
                  placeholder='eg. 2.2'
                  onChange={this.handleEngineCapacity}
                  value={newCar.engine.capacity}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <Select
                  label='Tires'
                  values={this.getTireTypesForSelect()}
                  currentValue={newCar.tires}
                  onSelect={this.handleTiresTypeChange}
                />
              </Col>
              {!isThisNewCar && <Col md={3}>
                <Checkbox
                  readOnly
                  checked={newCar.archived}
                  onChange={this.handleArchiveChanged}>
                  Archive
                </Checkbox>
              </Col>}
            </Row>
            <Row>
              <Col md={6}>
                <Panel>
                  <Panel.Heading>Car aliases</Panel.Heading>
                  <Panel.Body>
                    <FieldGroup
                      label='Nickname (optional)'
                      placeholder='eg. Lightning McQueen'
                      onChange={this.handleCarCommonNick}
                      value={newCar.common ? newCar.common.nick : ''}
                    />
                    <h5>Avatar (optional)</h5>
                    {
                      newCar.common !== undefined &&
                        newCar.common.avatar &&
                        newCar.common.avatar.length > 0
                        ? <CarAvatar imagePath={newCar.common.avatar} /> : <ImageUpload
                          uploadEndpoint={`${apiServerUrl}/api/upload`}
                          onUploadFinished={this.handleNewCommonAvatar} />
                    }
                    {
                      newCar.common &&
                        newCar.common.avatar &&
                        newCar.common.avatar.length > 0
                        ? <Button
                          style={{ marginTop: '12px' }}
                          onClick={this.clearCarCommonAvatar}>
                          Upload new avatar </Button> : null
                    }
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            <Row>
              <h4>Car current state</h4>
              <Col md={3}>
                <FieldGroup
                  label='Current mileage (km)'
                  placeholder='eg. 100256'
                  type='number'
                  onChange={this.handleMileageChange}
                  value={newCar.currentMileage}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FieldGroup
                  label='Last oil change mileage (km)'
                  placeholder='eg. 100256'
                  type='number'
                  onChange={this.handleLastOilChangeMileage}
                  value={newCar.oilChange.mileage}
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='Oil change mileage frequency (1000 km)'
                  placeholder='eg. 10'
                  type='number'
                  onChange={this.handleOilChangeMileageFrequency}
                  value={newCar.oilChange.changeMileageFrequency / 1000}
                  help='Note that the value provided is multiplied by 1000'
                />
              </Col>
              <Col md={3}>
                <ControlLabel>Last oil change date</ControlLabel>
                <br />
                { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                <DayPickerInput
                  type='number'
                  onDayChange={this.handleLastOilChangeDate}
                  value={new Date(newCar.oilChange.date)}
                  dateFormat='d MMMM yyyy'
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <ControlLabel>Last inspection date</ControlLabel>
                <br />
                { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                <DayPickerInput
                  type='number'
                  onDayChange={this.handleLastInspectionDate}
                  value={new Date(newCar.inspection.date)}
                  dateFormat='d MMMM yyyy'
                />
              </Col>
              <Col md={3}>
                <Select
                  label='Inspection interval'
                  values={this.mapInspectionYearsInterval()}
                  currentValue={newCar.inspectionYearsInterval}
                  onSelect={this.handleInspectionYearsInterval}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <ControlLabel>Last fumigation date</ControlLabel>
                <br />
                { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                <DayPickerInput
                  type='number'
                  onDayChange={this.handleLastFumigationDate}
                  value={new Date(newCar.fumigation.date)}
                  dateFormat='d MMMM yyyy'
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <ControlLabel>Current insurance validation period</ControlLabel>
                <br />
                { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                <DayPickerInput
                  type='number'
                  onDayChange={this.handleInsuranceDate}
                  value={new Date(newCar.insurance.date)}
                  dateFormat='d MMMM yyyy'
                />
              </Col>
            </Row>
            {
              this.isCarLpg()
                ? <Row>
                  <Col md={3}>
                    <ControlLabel>Current LPG approval date</ControlLabel>
                    <br />
                    { /* class DayPickerInput is manually styled in globals.less to match bootstrap theme */}
                    <DayPickerInput
                      type='number'
                      onKeyUp={(e) => e.preventDefault()}
                      onDayChange={this.handleLpgApprovalDate}
                      value={new Date(newCar.lpgApproval.date)}
                      dateFormat='d MMMM yyyy'
                    />
                  </Col>
                  <Col md={3}>
                    <FieldGroup
                      label='Last approval mileage (km)'
                      placeholder='eg. 100256'
                      type='number'
                      onChange={this.handleLastLpgApprovalMileage}
                      value={newCar.lpgApproval.mileage}
                    />
                  </Col>
                  <Col md={3}>
                    <FieldGroup
                      label='Approval revalidation frequency (1000 km)'
                      placeholder='eg. 10'
                      type='number'
                      onChange={this.handleOilLpgApprovalFrequency}
                      value={newCar.lpgApproval.approvalMileageFrequency / 1000}
                      help='Note that the value provided is multiplied by 1000'
                    />
                  </Col>
                </Row> : null
            }
            <Row>
              <Col md={3}>
                <FieldGroup
                  label='Last timing gear change mileage (km)'
                  placeholder='eg. 100256'
                  type='number'
                  onChange={this.handleLastTimingGearChangeMileage}
                  value={newCar.timingGearChange.mileage}
                />
              </Col>
              <Col md={3}>
                <FieldGroup
                  label='Timing gear change frequency (1000 km)'
                  placeholder='eg. 10'
                  type='number'
                  onChange={this.handleTimingGearChangeMileageFrequency}
                  value={newCar.timingGearChange.changeMileageFrequency / 1000}
                  help='Note that the value provided is multiplied by 1000'
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Panel>
                  <Panel.Heading>Document scans</Panel.Heading>
                  <Panel.Body>
                    <h5>Insurance scan</h5>
                    {
                      newCar.insuranceDocumentScan !== undefined &&
                        newCar.insuranceDocumentScan.scan &&
                        newCar.insuranceDocumentScan.scan.length > 0
                        ? <DocumentScan imagePath={newCar.insuranceDocumentScan.scan} /> : <ImageUpload
                          uploadEndpoint={`${apiServerUrl}/api/upload`}
                          onUploadFinished={this.handleNewInsuranceDocumentScan} />
                    }
                    {
                      newCar.insuranceDocumentScan &&
                        newCar.insuranceDocumentScan.scan &&
                        newCar.insuranceDocumentScan.scan.length > 0
                        ? <Button
                          style={{ marginTop: '12px' }}
                          onClick={this.clearInsuranceDocumentScan}>
                          Upload new insurance scan </Button> : null
                    }
                    <h5>Proof of registration scan</h5>
                    {
                      newCar.proofOfRegistrationScan !== undefined &&
                        newCar.proofOfRegistrationScan.scan &&
                        newCar.proofOfRegistrationScan.scan.length > 0
                        ? <DocumentScan imagePath={newCar.proofOfRegistrationScan.scan} /> : <ImageUpload
                          uploadEndpoint={`${apiServerUrl}/api/upload`}
                          onUploadFinished={this.handleNewProofOfRegistrationDocumentScan} />
                    }
                    {
                      newCar.proofOfRegistrationScan &&
                        newCar.proofOfRegistrationScan.scan &&
                        newCar.proofOfRegistrationScan.scan.length > 0
                        ? <Button
                          style={{ marginTop: '12px' }}
                          onClick={this.clearProofOfRegistrationDocumentScan}>
                          Upload new proof of registration scan </Button> : null
                    }
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            <Row>
              <h4>Readme</h4>
              <Col md={6}>
                <p>Brief information about known issues and how to handle them. <br />It is an optional field.</p>
                <Panel>
                  <Panel.Body>
                    <Editor
                      editorState={this.state.readmeEditorState}
                      placeholder='Content goes here'
                      onEditorStateChange={this.onReadmeEditorChanged}
                      toolbar={{
                        options: ['blockType', 'inline', 'list', 'emoji', 'link', 'history'],
                        inline: {
                          inDropdown: false,
                          options: ['bold', 'italic']
                        },
                        list: {
                          inDropdown: false,
                          options: ['unordered', 'ordered']
                        },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                        blockType: {
                          options: ['Normal', 'H1', 'H3', 'Blockquote']
                        }
                      }}
                    />
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            <Row>
              <h4>Admin-only notes</h4>
              <Col md={6}>
                <p>Contents like repairs or other issues that <b>should not be seen by users</b>. <br />It is an optional field.</p>
                <Panel>
                  <Panel.Body>
                    <Editor
                      editorState={this.state.adminReadmeEditorState}
                      placeholder='Content goes here'
                      onEditorStateChange={this.onAdminReadmeEditorChanged}
                      toolbar={{
                        options: ['blockType', 'inline', 'list', 'emoji', 'link', 'history'],
                        inline: {
                          inDropdown: false,
                          options: ['bold', 'italic']
                        },
                        list: {
                          inDropdown: false,
                          options: ['unordered', 'ordered']
                        },
                        link: { inDropdown: false },
                        history: { inDropdown: false },
                        blockType: {
                          options: ['Normal', 'H1', 'H3', 'Blockquote']
                        }
                      }}
                    />
                  </Panel.Body>
                </Panel>
              </Col>
            </Row>
            {isThisNewCar
              ? (
                <Row>
                  <Col md={6}>
                    <Questionnaire
                      questionnaire={this.props.newCar.questionnaireAnswers}
                      onContentChange={this.onAnswerChanged}
                    />
                  </Col>
                </Row>
              ) : null
            }

            <Fade in={this.isCarDataComplete()}>
              <FAB
                style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
                onClick={this.saveCar}
                title='Save a car'>
                <SaveIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
              </FAB>
            </Fade>
          </Grid>
        )
      }
    } else {
      return (
        <Grid>

          {
            newCar.created
              ? <Redirect to='/dashboard/cars' /> : null
          }

          <Row>
            <h1>Create a car  {' '}
              <LoadingIcon isLoading={this.props.isLoading} />
            </h1>
          </Row>
        </Grid>
      )
    }
  }
}

const debounce = (func, delay) => {
  let inDebounce
  return function () {
    const context = this
    const args = arguments
    clearTimeout(inDebounce)
    inDebounce = setTimeout(() => func.apply(context, args), delay)
  }
}

function mapStoreStateToProps({ cars, carModels, carManufacturers, fuelTypes, regulations, questionnaires }) {
  return {
    ...cars,
    ...carModels,
    ...carManufacturers,
    ...fuelTypes,
    ...regulations,
    ...questionnaires,
    isLoading: cars.isLoading || carModels.isLoading ||
      carManufacturers.isLoading || fuelTypes.isLoading ||
      regulations.isLoading || questionnaires.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...getCarsActions(dispatch),
    ...getCarModelsActions(dispatch),
    ...getCarManufacturersActions(dispatch),
    ...getFuelTypesActions(dispatch),
    ...getRegulationsActions(dispatch),
    ...getQuestionnairesActions(dispatch),
    setCarsLoading: (isLoading) => getCarsActions(dispatch).updateLoadingState(isLoading),
    setCarModelsLoading: (isLoading) => getCarModelsActions(dispatch).updateLoadingState(isLoading),
    setCarManufacturersLoading: (isLoading) => getCarManufacturersActions(dispatch).updateLoadingState(isLoading),
    setFuelTypesLoading: (isLoading) => getFuelTypesActions(dispatch).updateLoadingState(isLoading),
    setRegulationsLoading: (isLoading) => getRegulationsActions(dispatch).updateLoadingState(isLoading),
    setQuestionnairesLoading: (isLoading) => getQuestionnairesActions(dispatch).updateLoadingState(isLoading)
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CreateCar)
