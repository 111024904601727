import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Panel, Label, Alert } from 'react-bootstrap'
import CarModelElement from './CarModelElement'
import CarModelIssueMini from './CarModelIssueMini'
import CarModelRotationArrows from './CarModelRotationArrows'
import getTransfersActions from '../../Transfers/actions/actions'
import getUsersActions from '../../Users/actions/actions'
import getQuestionnairesActions from '../../Questionnaires/actions/questionnairesActions'
import getCarModelsActions from '../../CarModels/actions/carModelsActions'
import api, { apiServerUrl } from '../../../api'
import LoadingIcon from '../../../components/common/LoadingIcon'
import QuestionnaireAnswersList from './QuestionnaireAnswersList'
import TransfersPagination from './Pagination'

class TransferDetails extends Component {
  constructor (props) {
    super(props)
    this.state = { isLoading: true }
  }

  componentWillMount () {
    this.downloadData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.match.params.entryNumber !== this.props.match.params.entryNumber) this.downloadData()
  }

  downloadData = () => {
    this.setLoadingStateForAllDataSources(true)

    // download a car and other dependent data
    const desiredEntryNumber = parseInt(this.props.match.params.entryNumber)
    const { id } = this.props.match.params
    api.getCarByTransfersHistoryId(id)
      .then(async (response) => {
        const car = response.data

        const transfer = (await api.getTransferByIdAndEntryNumber(car.transfersHistoryId, desiredEntryNumber)).data
        const transferDetails = transfer.transfersHistory[0]
        const secondLevelPromises = []
        secondLevelPromises.push(api.getQuestionnaireById(transferDetails.questionnaireId))
        secondLevelPromises.push(api.getCarModelById(transferDetails.carModelId))
        if (transferDetails.previousUserId) {
          secondLevelPromises.push(api.getUserById(transferDetails.previousUserId))
        }

        if (transferDetails.nextUserId) {
          secondLevelPromises.push(api.getUserById(transferDetails.nextUserId))
        }

        Promise.all(secondLevelPromises)
          .then((responses) => {
            const questionnaire = responses.splice(0, 1)[0].data
            const carModel = responses.splice(0, 1)[0].data
            this.props.setCarModels([carModel])
            this.props.setTransfers([transfer])
            this.props.setQuestionnaires([questionnaire])
            this.props.setUsers(responses.map((response) => response.data))

            this.setLoadingStateForAllDataSources(false)
          })
      })
  }

  setLoadingStateForAllDataSources = (isLoading) => {
    if (isLoading) { this.setState({ isLoading }) }

    this.props.setCarModelsLoading(isLoading)
    this.props.setQuestionnairesLoading(isLoading)
    this.props.setTransfersLoading(isLoading)
    this.props.setUsersLoading(isLoading)

    if (!isLoading) { this.setState({ isLoading }) }
  }

  findTransferHistoryById = (id) => {
    return this.props.transfers.filter((transfer) => transfer._id === id)[0]
  }

  findCarModelById = (id) => {
    return this.props.carModels.filter((carModel) => carModel._id === id)[0]
  }

  findUserById = (id) => {
    return this.props.users.filter((user) => user._id === id)[0]
  }

  findQuestionnaireById = (id) => {
    return this.props.questionnaires.filter((questionnaire) => questionnaire._id === id)[0]
  }

  getRevisionFromQuestionnaire = (questionnaire, revision) => {
    const revisionNumber = parseInt(revision, 10)
    return questionnaire.revisions.filter((revision) => revision.version === revisionNumber)[0]
  }

  getRevisionFromCarModel = (carModel, revision) => {
    const revisionNumber = parseInt(revision, 10)
    return carModel.revisions.filter((revision) => revision.version === revisionNumber)[0]
  }

  getElementFromCarModelRevision = (carModelRevision, element) => {
    const elementNumber = parseInt(element, 10)
    return carModelRevision.elements.filter((element) => element.order === elementNumber)[0]
  }

  getIssuesForCarModelSide = (transferDetails, carModelSide) => {
    const carModelSideNumber = parseInt(carModelSide)
    return transferDetails.carModelIssues.filter((issue) => issue.elementOrder === carModelSideNumber)
  }

  canCarModelSideGoBack = (carModelRevision, currentCarModelSide) => {
    return carModelRevision.elements[0].order < parseInt(currentCarModelSide)
  }

  canCarModelSideGoFurther = (carModelRevision, currentCarModelSide) => {
    return carModelRevision.elements[carModelRevision.elements.length - 1].order > parseInt(currentCarModelSide)
  }

  pushCarModelSideBack = () => {
    this.props.history.push(`/dashboard/transfer/${this.props.match.params.id}/${this.props.match.params.entryNumber}/carModelSide/${parseInt(this.props.match.params.carModelSideNumber) - 1}`)
  }

  pushCarModelSideFurther = () => {
    this.props.history.push(`/dashboard/transfer/${this.props.match.params.id}/${this.props.match.params.entryNumber}/carModelSide/${parseInt(this.props.match.params.carModelSideNumber) + 1}`)
  }

  pushToEntryNumber = (newEntryNumber) => {
    this.props.history.push(`/dashboard/transfer/${this.props.match.params.id}/${newEntryNumber}/carModelSide/1`)
  }

  wheelPositionToString = (position) => {
    switch (position) {
      case 'front_left': return 'Front left'
      case 'front_right': return 'Front right'
      case 'rear_left': return 'Rear left'
      case 'rear_right': return 'Rear right'
      default: return 'Unknown'
    }
  }

  issueTypeToString = (issueType) => {
    switch (issueType) {
      case 'scratch': return 'Scratch'
      case 'dent': return 'Dent'
      case 'corrosion': return 'Corrosion'
      case 'chip of paint': return 'Chip of paint'
      default: return 'Unknown'
    }
  }

  render () {
    const transfer = this.findTransferHistoryById(this.props.match.params.id)
    let content = (
      <Panel.Body>
        <LoadingIcon isLoading />
      </Panel.Body>
    )

    if (!this.state.isLoading && !this.props.isLoading && transfer) {
      const transferDetails = transfer.transfersHistory[0]
      const carModel = this.findCarModelById(transferDetails.carModelId)
      const carModelElement = this.getElementFromCarModelRevision(
        this.getRevisionFromCarModel(carModel, transferDetails.carModelVersion),
        this.props.match.params.carModelSideNumber
      )

      let userFrom
      if (transferDetails.previousUserId) {
        userFrom = this.findUserById(transferDetails.previousUserId)
      // if else, it means that a previousUserDetails object is present
      } else {
        userFrom = {
          username: transferDetails.previousUserDetails.name,
          signaturePath: transferDetails.previousUserDetails.signaturePath,
          confirmationSignaturePath: transferDetails.previousUserDetails.confirmationSignaturePath,
          isOutsideUser: true
        }
      }

      let userTo
      if (transferDetails.nextUserId) {
        userTo = this.findUserById(transferDetails.nextUserId)
      // if else, it means that a previousUserDetails object is present
      } else {
        userTo = {
          username: transferDetails.nextUserDetails.name,
          signaturePath: transferDetails.nextUserDetails.signaturePath,
          isOutsideUser: true
        }
      }

      const questions = this.getRevisionFromQuestionnaire(this.findQuestionnaireById(transferDetails.questionnaireId), transferDetails.questionnaireVersion).questions
      const answers = transferDetails.questionnaireAnswers

      content = (
        <Panel.Body>
          <Row>
            <Col>
              <Panel.Body>
                {
                  transferDetails.forcedByAdmin
                    ? <Alert bsStyle={'info'}>
                      <span>This transfer was forced by admin</span>
                    </Alert> : null
                }
                {
                  transferDetails.weeklyInspection
                    ? <Alert bsStyle={'info'}>
                      <span>This is weekly inspection</span>
                    </Alert> : null
                }
                <p><b>Transfer date:</b> { new Date(transferDetails.date).toLocaleString() }</p>
                <p><b>Previous user:</b> { userFrom.username } { userFrom.isOutsideUser ? <>
                  <Label>Outside user</Label>
                  { ' ' }
                  <a target='_blank' rel='noopener noreferrer' href={`${apiServerUrl}${userFrom.signaturePath}`}>
                   Signature
                  </a>
                  {' '}
                  <a target='_blank' rel='noopener noreferrer' href={`${apiServerUrl}${userFrom.confirmationSignaturePath}`}>
                   Confirmation signature
                  </a>
                   </> : null}
                </p>
                <p><b>Next user:</b> { userTo.username } { userTo.isOutsideUser
                  ? <>
                    <Label>Outside user</Label>
                    { ' ' }
                    <a target='_blank' rel='noopener noreferrer' href={`${apiServerUrl}${userTo.signaturePath}`}>
                   Signature
                    </a>
                   </> : null }
                </p>
              </Panel.Body>
            </Col>
          </Row>
          <Row>
            <h3 style={{ paddingLeft: '15px' }}>Issues list</h3>
          </Row>
          <Row>
            <Col md={6}>
              <CarModelElement carModelElement={carModelElement} />
              <CarModelRotationArrows
                isPrevAllowed={
                  this.canCarModelSideGoBack(
                    this.getRevisionFromCarModel(
                      carModel, transferDetails.carModelVersion),
                    this.props.match.params.carModelSideNumber
                  )
                }
                isNextAllowed={
                  this.canCarModelSideGoFurther(
                    this.getRevisionFromCarModel(
                      carModel, transferDetails.carModelVersion),
                    this.props.match.params.carModelSideNumber
                  )
                }
                onPrevClicked={this.pushCarModelSideBack}
                onNextClicked={this.pushCarModelSideFurther}
              />
            </Col>
            <Col md={6}>
              <Row>
                {
                  this
                    .getIssuesForCarModelSide(transferDetails, this.props.match.params.carModelSideNumber)
                    .map((issue) => (
                      <Col xl={3} md={3} sm={6} xs={6} key={`${issue.filePath}`}>
                        <CarModelIssueMini
                          issueImageUrl={`${apiServerUrl}${issue.filePath}`}
                          issueType={this.issueTypeToString(issue.issue.type)} />
                      </Col>
                    ))
                }
                {
                  this
                    .getIssuesForCarModelSide(transferDetails, this.props.match.params.carModelSideNumber).length === 0
                    ? <Col md={6}><Alert>No issues found</Alert></Col> : null
                }
              </Row>
            </Col>
          </Row>
          <Row>
            <h3 style={{ paddingLeft: '15px' }}>Wheel issues</h3>
          </Row>
          <Row>
            {
              transferDetails.wheelState
                ? transferDetails.wheelState
                  .map((wheelState, index) => (
                    <Col xl={2} md={2} sm={6} xs={6} key={`${wheelState.filePath}-${index}`}>
                      <CarModelIssueMini
                        issueImageUrl={`${apiServerUrl}${wheelState.filePath}`}
                        issueType={this.wheelPositionToString(wheelState.position)} />
                    </Col>
                  ))
                : <Col md={6}><Alert>No wheel issues found</Alert></Col>
            }
          </Row>
          <Row>
            <h3 style={{ paddingLeft: '15px' }}>Interior issues</h3>
          </Row>
          <Row>
            {
              transferDetails.interiorState
                ? transferDetails.interiorState
                  .map((interiorState, index) => (
                    <Col xl={2} md={2} sm={6} xs={6} key={`${interiorState.filePath}-${index}`}>
                      <CarModelIssueMini
                        issueImageUrl={`${apiServerUrl}${interiorState.filePath}`} />
                    </Col>
                  ))
                : <Col md={6}><Alert>No interior issues found</Alert></Col>
            }
          </Row>
          <Row>
            <h3 style={{ paddingLeft: '15px' }}>Questionnaire answers</h3>
          </Row>
          <QuestionnaireAnswersList questions={questions} answers={answers} />
          <Row>
            <Col>
              <TransfersPagination
                currentPage={parseInt(this.props.match.params.entryNumber, 10) + 1}
                allPages={transfer.meta.entriesCount}
                onPageClicked={this.pushToEntryNumber} />
            </Col>
          </Row>
        </Panel.Body>
      )
    }

    return (
      <Grid>
        <Row>
          <Col>
            <Panel>
              <Panel.Heading>
                <Panel.Title>
                  Transfer details
                </Panel.Title>
              </Panel.Heading>
              {content}
            </Panel>
          </Col>
        </Row>

      </Grid>
    )
  }
}

function mapStoreStateToProps ({ carModels, users, transfers, questionnaires }) {
  return {
    ...carModels,
    ...users,
    ...transfers,
    ...questionnaires,
    isLoading: carModels.isLoading ||
    users.isLoading ||
    transfers.isLoading ||
    questionnaires.isLoading
  }
}

function mapDispatchToProps (dispatch) {
  return {
    ...getCarModelsActions(dispatch),
    ...getUsersActions(dispatch),
    ...getTransfersActions(dispatch),
    ...getQuestionnairesActions(dispatch),
    setCarModelsLoading: (isLoading) => getCarModelsActions(dispatch).updateLoadingState(isLoading),
    setUsersLoading: (isLoading) => getUsersActions(dispatch).updateLoadingState(isLoading),
    setTransfersLoading: (isLoading) => getTransfersActions(dispatch).updateLoadingState(isLoading),
    setQuestionnairesLoading: isLoading => getQuestionnairesActions(dispatch).updateLoadingState(isLoading)
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(TransferDetails)
