import React from 'react'
import { ButtonGroup, Button, Glyphicon } from 'react-bootstrap'

export default function ({ onBackPressed, onNextPressed, backActive, nextActive }) {
  return (
    <ButtonGroup>
      <Button
        disabled={!backActive}
        onClick={onBackPressed}>
        <Glyphicon glyph='triangle-left' />
      </Button>
      <Button
        disabled={!nextActive}
        onClick={onNextPressed}>
        <Glyphicon glyph='triangle-right' />
      </Button>
    </ButtonGroup>
  )
}
