import React, { Component } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

class Select extends Component {
  render () {
    const { values, currentValue, onSelect, label, disabled } = this.props

    if (!currentValue) onSelect(values[0].value)

    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          disabled={disabled}
          componentClass='select'
          value={currentValue}
          onChange={(event) => onSelect(event.target.value)}>
          {
            values.map((element) => {
              return (
                <option key={element.value} value={element.value}>
                  {element.name}
                </option>
              )
            })
          }
        </FormControl>
      </FormGroup>
    )
  }
}

export default Select
