import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import AlertsListItem from './AlertsListItem'
import LoadingIcon from '../../../components/common/LoadingIcon'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    alerts: 'Alerts',
  },
  pl: {
    alerts: 'Powiadomienia',
  },
  de: {
    alerts: 'Benachrichtigungen',
  },
  fr: {
    alerts: 'Avis',
  }
})

class Alerts extends Component {
  groupByCarIds = (alerts) => {
    const uniqueCars = []
    alerts.forEach((alert) => !uniqueCars.includes(alert.carAffected._id) ? uniqueCars.push(alert.carAffected._id) : null)

    return uniqueCars.map((element) => {
      return {
        _id: element,
        alerts: alerts.filter((alert) => alert.carAffected._id === element)
      }
    })
  }

  pushToCarDetails = (carId) => {
    this.props.history.push(`/dashboard/car/${carId}`)
  }

  createAlertsList = (alerts) => {
    return (
      alerts.map((alert) => <Col md={4} key={`${alert.carAffected._id}-alert-${alert.matterType}-${alert.matter}`}>
        <AlertsListItem alert={alert} onAlertClicked={(clickedId) => this.pushToCarDetails(clickedId)} />
      </Col>)
    )
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={12}>
            <h1>{strings.alerts} {' '} {this.props.isLoading ? <LoadingIcon isLoading /> : null}</h1>
          </Col>
        </Row>
        {
          this.groupByCarIds(this.props.alerts).map((alertsForCar) => (
            <Row key={alertsForCar._id}>
              <Col md={12}>
                <h3>{`${alertsForCar.alerts[0].carAffected.model} ${alertsForCar.alerts[0].carAffected.licensePlate}`}
                  {alertsForCar.alerts[0].carAffected.common &&
                    alertsForCar.alerts[0].carAffected.common.nick ? ` - ${alertsForCar.alerts[0].carAffected.common.nick}` : ''}</h3>
              </Col>
              {this.createAlertsList(alertsForCar.alerts)}
            </Row>
          ))
        }
      </Grid>
    )
  }
}

function mapStoreStateToProps({ alerts }) {
  return {
    ...alerts
  }
}

export default connect(mapStoreStateToProps)(Alerts)
