import React from 'react'
import StyledIcon from './StyledIcon'

export default function LoadingIcon (props) {
  const { isLoading } = props
  const safeProps = { ...props }
  delete safeProps.isLoading
  if (isLoading) return <StyledIcon spin center size='small' {...safeProps} />
  return null
}
