import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import o2d from 'date-from-object-id'
import getActions from '../../actions/regulationsActions'
import { cloneDeep } from 'lodash'
import { Grid, Row, Col } from 'react-bootstrap'
import FAB from '../../../../components/common/FAB'
import { MdModeEdit as EditIcon, MdContentCopy as CloneIcon } from 'react-icons/md'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import RevisionsChooser from './RevisionsChooser'
import RegulationContent from './RegulationContent'

class RegulationDetails extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    this.props.downloadRegulation(id)
  }

  getRegulationById = (id) => {
    return this.props.regulations.filter((regulation) => regulation._id === id)[0]
  }

  getNewestRegulationRevision (regulation) {
    regulation.revisions.sort((a, b) => a.version - b.version)
    return regulation.revisions[regulation.revisions.length - 1].version
  }

  getRequestedRevisionNumber (routerMatch, regulation) {
    let requestedRevision = routerMatch.params.revision
    if (requestedRevision === 'latest') {
      requestedRevision = this.getNewestRegulationRevision(regulation)
    } else requestedRevision = parseInt(requestedRevision, 10)
    return requestedRevision
  }

  getRequestedRevisionContent (regulation, revisionNumber) {
    return regulation.revisions.filter((revision) => revision.version === revisionNumber)[0]
  }

  handleRevisionVersionClick = (requestedRevisionNumber) => {
    const { id } = this.props.match.params
    this.props.history.push(`/dashboard/regulation/${id}/${requestedRevisionNumber}`)
  }

  pushToEdit = () => {
    let { id, revision } = this.props.match.params
    if (revision === 'latest') revision = this.getNewestRegulationRevision(this.getRegulationById(id))
    this.props.history.push(`/dashboard/regulation/${id}/${revision}/edit`)
  }

  pushToClone = () => {
    let { id, revision } = this.props.match.params
    const regulation = this.getRegulationById(id)
    if (revision === 'latest') revision = this.getNewestRegulationRevision(regulation)
    this.props.setNewRegulation(
      'cloned',
      '',
      cloneDeep(this.getRequestedRevisionContent(regulation, parseInt(revision)).content)
    )
    this.props.history.push(`/dashboard/regulation/add`)
  }

  render () {
    const requestedId = this.props.match.params.id

    let details = (
      <Row>
        <h1>Regulation details <LoadingIcon isLoading={this.props.isLoading} /></h1>
      </Row>
    )

    if (!this.props.isLoading) {
      const regulation = this.getRegulationById(requestedId)
      const requestedRevisionNumber = this.getRequestedRevisionNumber(this.props.match, regulation)
      const requestedRevision = this.getRequestedRevisionContent(regulation, requestedRevisionNumber)
      details = (
        <Fragment>
          <Row>
            <h1>Regulation details <LoadingIcon isLoading={this.props.isLoading} /></h1>
            <p>Date added: {o2d(regulation._id).toLocaleDateString()}</p>
            <span>
              <p style={{ display: 'inline' }}>Revisions: {' '}</p>
              <RevisionsChooser
                revisions={regulation.revisions}
                requestedRevisionNumber={requestedRevisionNumber}
                onRevisionClicked={this.handleRevisionVersionClick} />
            </span>
          </Row>
          <br />
          <Row>
            <Col md={6}>
              <RegulationContent title={regulation.title} content={requestedRevision.content} />
            </Col>
          </Row>
        </Fragment>
      )
    }

    return (
      <Grid>
        {details}

        {
          !this.props.isLoading
            ? (
              <Fragment>
                <FAB
                  mini
                  style={{ 'position': 'fixed', right: '96px', bottom: '28px' }}
                  onClick={this.pushToClone}
                  title='Create new regulation out of this one'>
                  <CloneIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px', maxWidth: '24px' }} />
                </FAB>

                <FAB
                  style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
                  onClick={this.pushToEdit}
                  title='Edit regulation'>
                  <EditIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
                </FAB>
              </Fragment>
            ) : null
        }
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ regulations }) {
  return regulations
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(RegulationDetails)
