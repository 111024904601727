import React from 'react'
import Table from './Table'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    date: 'Date',
    nextFumigationDate: 'Next fumigation date',
  },
  pl: {
    date: 'Data',
    nextFumigationDate: 'Data następnego odgrzybiania',
  },
  de: {
    date: 'Datum',
    nextFumigationDate: 'Nächstes Datum für die Schädlingsbekämpfung',
  },
  fr: {
    date: 'Date',
    nextFumigationDate: 'Prochaine date de fumigation',
  }
})

export default ({ list }) => {
  let counter = 0
  return (
    <Table>
      <thead>
        <tr>
          <th>{strings.date}</th>
          <th>{strings.nextFumigationDate}</th>
        </tr>
      </thead>
      <tbody>
        {
          list ? list.map((element) => {
            counter++
            return (
              <tr key={`${element.date}${counter}`}>
                <td>
                  {new Date(element.date).toLocaleDateString()}
                </td>
                <td>
                  {new Date(element.nextFumigationDate).toLocaleDateString()}
                </td>
              </tr>
            )
          }) : null
        }
      </tbody>
    </Table>
  )
}
