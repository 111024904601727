import React, { Component } from 'react'
import { apiServerUrl } from '../../../api'

const apiUrl = apiServerUrl

function withApiUrl (WrappedComponent) {
  return class extends Component {
    render () {
      return <WrappedComponent apiUrl={apiUrl} {...this.props} />
    }
  }
}

export default withApiUrl
