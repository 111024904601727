import React, { Component } from 'react'
import { Grid, Row, Col, Alert, Well, Button, Collapse } from 'react-bootstrap'
import { withRouter } from 'react-router'

class DashboardErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = { error: null, errorInfo: null, errorDetailsShown: false }
  }

  componentDidCatch (error, errorInfo) {
    // catch errors that occured below in the render tree
    // and render proper message for the user
    this.setState({
      error, errorInfo
    })
  }

  handleProceedClicked = () => {
    this.setState({ error: null, errorInfo: null, errorDetailsShown: false })
    this.props.history.push('/dashboard')
  }

  handleRevealDetails = () => {
    this.setState({ errorDetailsShown: !this.state.errorDetailsShown })
  }

  render () {
    // if no error is catched just proceed normally
    if (!this.state.error) return this.props.children

    // if error occurred, display the message below
    return (
      <Grid>
        <Row>
          <Col mdPush={1} md={10} style={{ marginTop: '12px' }}>
            <Alert bsStyle='danger'>
              <h1>An error occurred</h1>
              <p>
                While you were using this app an critical error occurred.
                <br />You can try to refresh the page or click the <b>Proceed</b> button below to continue your work.
                <br /><br />
                If you have a minute, please report the error details to the IT departament.
                Here goes the error details:
              </p>
              { this.state.errorDetailsShown ? null : <Button onClick={this.handleRevealDetails}>Reveal details</Button> } {' '}
              <Collapse in={this.state.errorDetailsShown}>
                <Well>
                  <h5>{ this.state.error && this.state.error.toString() }</h5>
                  <pre>
                    { this.state.errorInfo.componentStack }
                  </pre>
                </Well>
              </Collapse>
              <Button bsStyle='danger' onClick={this.handleProceedClicked}>Proceed</Button>
            </Alert>
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default withRouter(DashboardErrorBoundary)
