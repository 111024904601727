import React, { Component } from 'react'
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap'
import styled from 'styled-components'

const NonClickableButtons = styled(Button)`
  &:hover {
    cursor: default;
  }
`

class QuestionnaireAnswersList extends Component {
  getAnswerComponent = (answerType, answer) => {
    if (answerType === 'boolean') {
      const isAnswerTrue = answer === 'true'
      return (
        <ButtonGroup>
          <NonClickableButtons bsStyle={isAnswerTrue ? 'success' : 'default'} disabled={!isAnswerTrue}>Yes</NonClickableButtons>
          <NonClickableButtons bsStyle={!isAnswerTrue ? 'danger' : 'default'} disabled={isAnswerTrue}>No</NonClickableButtons>
        </ButtonGroup>
      )
    }

    return (
      <p>{answer}</p>
    )
  }

  render () {
    const { questions, answers } = this.props
    const questionsWithAnswers = []
    for (let i = 0; i < questions.length; i++) {
      questionsWithAnswers.push({
        question: questions[i].content,
        answerType: questions[i].answerType,
        answer: answers[i].answer
      })
    }

    return (
      <Row>
        {
          questionsWithAnswers.map((questionWithAnswer, index) =>
            (
              <Col md={4} key={`${questionWithAnswer.question}${questionsWithAnswers.answer}-${index}`} style={{ paddingTop: '24px' }}>
                <b>{questionWithAnswer.question}</b>
                <div>{this.getAnswerComponent(questionWithAnswer.answerType, questionWithAnswer.answer)}</div>
              </Col>
            )
          )
        }
      </Row>
    )
  }
}
export default QuestionnaireAnswersList
