const nowYearAfter = new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate(), 12)
const nowFiveYearsAfter = new Date(new Date().getFullYear() + 5, new Date().getMonth(), new Date().getDate(), 12)
// below is a today's date without current hour
const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
const initState = {
  isLoading: true,
  cars: [],
  carState: {
    mileage: null,
    oilChange: {
      mileage: null,
      date: null
    },
    timingGearChange: {
      mileage: null,
      date: null
    },
    inspection: {
      date: null
    },
    fumigation: {
      date: null
    },
    error: '',
    isSaved: false
  },
  newCar: {
    id: 'new',
    carModelId: '',
    manufacturerId: '',
    fuelTypeId: '',
    regulationId: '',
    transfersHistoryId: '',
    model: '',
    color: '',
    licensePlate: '',
    readme: '',
    adminReadme: '',
    vin: '',
    productionDate: today,
    engine: {
      power: 0,
      capacity: 0.0
    },
    currentMileage: 0,
    oilChange: {
      date: today,
      mileage: 0,
      nextChangeDate: nowYearAfter,
      nextChangeMileage: 10000,
      changeMileageFrequency: 10000
    },
    inspection: {
      date: today,
      nextInspectionDate: nowYearAfter
    },
    inspectionYearsInterval: 1,
    fumigation: {
      date: today,
      nextFumigationDate: nowYearAfter
    },
    insurance: {
      date: today,
      // business logic here:
      // insurance is valid for a year but insurance begin date is excluded in next year, eg:
      // insurance is valid for 8.03.2019 - 7.03.2020
      validUntilDate: new Date(nowYearAfter.getFullYear(), nowYearAfter.getMonth(), nowYearAfter.getDate() - 1, 12)
    },
    timingGearChange: {
      mileage: 0,
      nextChangeMileage: 100000,
      changeMileageFrequency: 100000
    },
    lpgApproval: {
      date: today,
      mileage: 0,
      approvalValidUntil: nowFiveYearsAfter,
      nextChangeMileage: 10000,
      approvalMileageFrequency: 10000
    },
    questionnaireAnswers: [],
    created: false,
    error: ''
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'CARS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'CARS.ADD_CAR':
      return {
        ...state,
        cars: [...state.cars, action.car]
      }
    case 'CARS.SET_CARS':
      return {
        ...state,
        cars: action.cars
      }
    case 'CARS.SET_CAR_STATE':
      return {
        ...state,
        carState: action.carState
      }
    case 'CARS.CLEAR_CAR_STATE':
      return {
        ...state,
        carState: { ...initState.carState, isSaved: action.withSuccess }
      }
    case 'CARS.SET_NEW_CAR':
      return {
        ...state,
        newCar: action.newCar
      }
    case 'CARS.SET_NEW_CAR_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          id: action.id
        }
      }
    case 'CARS.SET_NEW_CAR_MANUFACTURER_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          manufacturerId: action.manufacturerId
        }
      }
    case 'CARS.SET_NEW_CAR_FUEL_TYPE_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          fuelTypeId: action.fuelTypeId
        }
      }
    case 'CARS.SET_NEW_CAR_CAR_MODEL_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          carModelId: action.carModelId
        }
      }
    case 'CARS.SET_NEW_CAR_REGULATION_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          regulationId: action.regulationId
        }
      }
    case 'CARS.SET_NEW_CAR_QUESTIONNAIRE_ID':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          questionnaireId: action.questionnaireId
        }
      }
    case 'CARS.SET_NEW_CAR_COMMON_NICK':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          common: {
            ...state.newCar.common,
            nick: action.nick
          }
        }
      }
    case 'CARS.SET_NEW_CAR_COMMON_AVATAR':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          common: {
            ...state.newCar.common,
            nick: action.avatar
          }
        }
      }
    case 'CARS.SET_NEW_CAR_MODEL_NAME':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          model: action.model
        }
      }
    case 'CARS.SET_NEW_CAR_COLOR':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          color: action.color
        }
      }
    case 'CARS.SET_NEW_LICENSE_PLATE':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          licensePlate: action.licensePlate
        }
      }
    case 'CARS.SET_NEW_CAR_CURRENT_MILEAGE':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          currentMileage: action.currentMileage
        }
      }
    case 'CARS.SET_NEW_CAR_OIL_CHANGE_DETAILS':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          oilChange: { ...action.details }
        }
      }
    case 'CARS.SET_NEW_CAR_INSPECTION_DETAILS':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          inspection: { ...action.details }
        }
      }
    case 'CARS.SET_NEW_CAR_FUMIGATION_DETAILS':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          fumigation: { ...action.details }
        }
      }
    case 'CARS.SET_NEW_CAR_INSURANCE_DETAILS':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          insurance: { ...action.details }
        }
      }
    case 'CARS.SET_NEW_CAR_TIMING_GEAR_DETAILS':
      return {
        ...state,
        newCar: {
          ...state.newCar,
          timingGearChange: { ...action.details }
        }
      }
    default: return state
  }
}

export default reducer
