export default function getActions(dispatch) {
  return {
    updateCredentials: (login, password) => {
      dispatch(updateCredentials(login, password))
    },
    setLoading: (isLoading) => {
      dispatch(setLoading(isLoading))
    },
    setErrorMessage: (errorMessage) => {
      dispatch(setErrorMessage(errorMessage))
    },
    doLogin: () => {
      dispatch(doLogin())
    },
    doLogout: () => {
      dispatch(doLogout())
    },
    setAuthData: (userData) => {
      dispatch(setAuthData(userData))
    },
    setOwnedCars: (cars = []) => {
      dispatch(setOwnedCars(cars))
    },
    setNewPasswordIsRequired: (newPasswordIsRequired) => {
      dispatch(setNewPasswordIsRequired(newPasswordIsRequired))
    },
    setNewPassword: (newPassword) => {
      dispatch(setNewPassword(newPassword))
    }
  }
}

export function doLogin() {
  return async (dispatch, getState, api) => {
    dispatch(setLoading(true))
    const { loginPage } = getState()
    try {
      const loginResponse = await api.login(
        loginPage.login,
        loginPage.password,
        loginPage.newPasswordIsRequired ? loginPage.newPassword : undefined
      )
      const meResponse = await api.me()
      handleLoginSuccessResult(loginResponse.data.user, meResponse.data.cars, dispatch)
    } catch (error) {
      handleLoginErrorResult(error, dispatch)
    }
  }
}

function handleLoginSuccessResult(user, cars, dispatch) {
  dispatch(setAuthData(user))
  dispatch(setOwnedCars(cars))
  dispatch(updateCredentials('', ''))
  dispatch(setErrorMessage(''))

  dispatch(setLoading(false))
  dispatch(setNewPasswordIsRequired(false))
}

function handleLoginErrorResult(error, dispatch) {
  if (error.response && error.response.status === 401) {
    const errorMessage = error.response.data.message
    if (!errorMessage.toLowerCase().includes('new password is required')) {
      dispatch(setErrorMessage(errorMessage || 'Invalid credentials'))
      dispatch(setNewPasswordIsRequired(false))
    } else {
      dispatch(setErrorMessage('New password is required'))
      dispatch(setNewPasswordIsRequired(true))
    }
  } else {
    dispatch(setErrorMessage(error.toString()))
    dispatch(setNewPasswordIsRequired(false))
  }

  dispatch(setLoading(false))
}

export function doLogout() {
  return (dispatch, getState, api) => {
    dispatch(setLoading(true))
    return api.logout()
      .then((response) => {
        dispatch(setAuthData(null))
        dispatch(setOwnedCars([]))
        dispatch(setLoading(false))
      })
  }
}

export function updateCredentials(login, password) {
  return {
    type: 'LOGIN_PAGE.UPDATE_CREDENTIALS',
    login,
    password
  }
}

export function setLoading(isLoading) {
  return {
    type: 'LOGIN_PAGE.SET_LOADING',
    isLoading
  }
}

export function setErrorMessage(errorMessage) {
  return {
    type: 'LOGIN_PAGE.SET_ERROR_MESSAGE',
    errorMessage
  }
}

export function setAuthData(userData) {
  return {
    type: 'LOGIN_PAGE.SET_AUTHENTICATED_USER',
    auth: userData
  }
}

export function setOwnedCars(cars = []) {
  return {
    type: 'LOGIN_PAGE.SET_OWNED_CARS',
    ownedCars: cars
  }
}

export function setNewPasswordIsRequired(isNewPasswordRequired) {
  return {
    type: 'LOGIN_PAGE.SET_NEW_PASSWORD_REQUIRED',
    newPasswordIsRequired: isNewPasswordRequired
  }
}

export function setNewPassword(newPassword) {
  return {
    type: 'LOGIN_PAGE.SET_NEW_PASSWORD',
    newPassword
  }
}
