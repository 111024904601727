import React from 'react'
import Table from './Table'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    date: 'Date',
    nextInspectionDate: 'Next inspection date',
  },
  pl: {
    date: 'Data',
    nextInspectionDate: 'Data następnego przeglądu',
  },
  de: {
    date: 'Datum',
    nextInspectionDate: 'Nächster Prüftermin',
  },
  fr: {
    date: 'Date',
    nextInspectionDate: "Prochaine date d'inspection",
  }
})

export default ({ list }) => {
  let counter = 0
  return (
    <Table>
      <thead>
        <tr>
          <th>{strings.date}</th>
          <th>{strings.nextInspectionDate}</th>
        </tr>
      </thead>
      <tbody>
        {
          list ? list.map((element) => {
            counter++
            return (
              <tr key={`${element.date}${counter}`}>
                <td>
                  {new Date(element.date).toLocaleDateString()}
                </td>
                <td>
                  {new Date(element.nextInspectionDate).toLocaleDateString()}
                </td>
              </tr>
            )
          }) : null
        }
      </tbody>
    </Table>
  )
}
