import React from 'react'
import withApiUrl from '../../../../components/common/hoc/withApiUrl'
import styled from 'styled-components';
import LocalizedStrings from 'react-localization';

const AnimatedLink = styled.a`
  transition: .3s;  
  display: inline-block;
  box-sizing: border-box;
  border: ${p => p.isCurrent ? '2px solid #193f77' : 'none'};
  border-radius: 4px;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(.95);
  }
`

const SquareImage = styled.img`
  max-width: 100%;
  width: 100px;
  padding: 4px;
  box-sizing: border-box;
  aspect-ratio: 1;
  object-fit: contain;
`

const strings = new LocalizedStrings({
  en: {
    latestScan: 'Latest scan',
    documentScan: 'Document scan',
  },
  pl: {
    latestScan: 'Ostatni skan',
    documentScan: 'Skan dokumentu',
  },
  de: {
    latestScan: 'Letzter Scan',
    documentScan: 'Dokumentenscan',
  },
  fr: {
    latestScan: 'Dernière numérisation',
    documentScan: 'Numérisation de document',
  }
})

function DocumentScanPreview({ scan, isCurrent, apiUrl }) {
  const url = `${apiUrl}${scan}`;
  return <AnimatedLink isCurrent={isCurrent} href={url} target='_blank'>
    <SquareImage
      src={`${apiUrl}${scan}?width=200`}
      alt={isCurrent ? strings.latestScan : strings.documentScan}
      title={isCurrent ? strings.latestScan : undefined}
      loading='lazy'
    />
  </AnimatedLink>
}

export default withApiUrl(DocumentScanPreview)