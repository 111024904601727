import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import styled from 'styled-components'

const AutocompleteItem = styled.span`
  padding: 6px 12px;
  display: block;
`

class UsersList extends Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }
  }

  handleSelect = (value) => {
    this.setState({ value })
    if (this.props.onSelect) {
      this.props.onSelect(this.props.users.find((user) => user.username.toLowerCase() === value.toLowerCase()))
    }
  } 

  renderInput = (props) => {
    return (<div style={{ textAlign: 'center', maxWidth: 250, margin: '12px auto' }} className={'form-group'}>
      <div className={'control-label'}>New user name</div>
      <input className={'form-group form-control'} {...props} />
    </div>)
  }

  render() {
    return (
      <Autocomplete
        items={this.props.users}
        shouldItemRender={(item, value) => item.username.toLowerCase().indexOf(value.toLowerCase()) > -1}
        getItemValue={item => item.username}
        wrapperStyle={{ display: 'block' }}
        renderInput={this.renderInput}
        renderItem={(item, highlighted) =>
          <div
            key={item._id}
            style={{ backgroundColor: highlighted ? '#eee' : 'transparent'}}
          >
            <AutocompleteItem>{item.username}</AutocompleteItem>
          </div>
        }
        value={this.state.value}
        onChange={e => this.setState({ value: e.target.value })}
        onSelect={this.handleSelect}
      />
    )
  }
}
export default UsersList
