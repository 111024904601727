import logoURL from '../../resources/logoSmall.png'
import styled from 'styled-components'

export default styled.img.attrs({
  alt: 'RES Logo',
  src: logoURL
})`
    margin: 20px 0 20px 10px;
    min-width: 52px;
`
