import React, { Component } from 'react'
import { connect } from 'react-redux'
import getActions from '../actions/regulationsActions'
import { Grid, Row, Col, Alert } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'
import RegulationsList from './RegulationsList'

class Regulations extends Component {
  handleRegulationClick = (id) => {
    this.props.history.push(`/dashboard/regulation/${id}/latest`)
  }

  openRegulationEditor = () => {
    this.props.history.push('/dashboard/regulation/add')
  }

  componentDidMount () {
    this.props.downloadRegulations()
  }

  render () {
    const { regulations, isLoading } = this.props

    let componentToRender
    if (!regulations || regulations.length === 0) {
      componentToRender = (
        <Alert bsStyle='warning'>
        No regulations found.
        </Alert>
      )
    } else {
      componentToRender = (
        <RegulationsList
          regulations={regulations}
          onRegulationClicked={this.handleRegulationClick} />
      )
    }

    return (
      <Grid>
        <Row>
          <h1>
          Regulations {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>

        <Row>
          <Col md={6}>
            { componentToRender }
          </Col>
        </Row>

        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.openRegulationEditor}
          title='Create new regulation'>
          <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ regulations }) {
  return regulations
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(Regulations)
