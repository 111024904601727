import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import getActions from '../../actions/actions'
import { Grid, Row, Col, Panel, Fade } from 'react-bootstrap'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import FabLoadableIcon from '../../../../components/common/FabLoadableIcon'
import { getGroupById } from '../../../../api'

class CreateGroup extends Component {
  componentDidMount() {
    const isThisNewGroup = !this.props.match.params.id
    if (!isThisNewGroup) {
      const { id } = this.props.match.params
      getGroupById(id)
        .then((response) => {
          const { _id, name } = response.data
          this.props.setNewGroup(_id, name)
          this.props.updateLoadingState(false)
        })
    } else {
      this.props.updateLoadingState(false)
    }
  }

  handleNameChange = (event) => {
    const { _id } = this.props.newGroup
    this.props.setNewGroup(_id, event.target.value)
  }

  shouldSaveButtonBeShown = () => {
    const { newGroup } = this.props
    return newGroup.name.length > 0
  }

  saveGroup = () => {
    const isThisNewGroup = !this.props.match.params.id
    if (isThisNewGroup) this.props.postGroup()
    else this.props.putGroup()
  }

  render() {
    const { newGroup } = this.props
    const isThisNewGroup = !this.props.match.params.id

    return (
      <Grid>

        {
          newGroup.created
            ? <Redirect to='/dashboard/groups' /> : null
        }

        <Row>
          <h1>{isThisNewGroup ? 'Create' : 'Edit'} group</h1>
          <Col md={6}>
            {
              newGroup.error.length > 0
                ? (<Panel bsStyle='warning'>
                  <Panel.Heading>
                    <Panel.Title>
                      Warning
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {newGroup.error}
                  </Panel.Body>
                </Panel>) : null
            }
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FieldGroup
              label='Name'
              placeholder='eg. France'
              value={newGroup.name}
              onChange={this.handleNameChange}
              minLength={1}
              maxLength={64}
              autoComplete='off'
            />
          </Col>
        </Row>

        <Fade in={this.shouldSaveButtonBeShown()}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.saveGroup}
            title='Save group'>
            <FabLoadableIcon isLoading={this.props.isLoading} />
          </FAB>
        </Fade>
      </Grid>
    )
  }
}

function mapStoreStateToProps({ groups }) {
  return groups
}

function mapDispatchToProps(dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CreateGroup)
