import React from "react";
import { Button } from "react-bootstrap";
import StyledIcon from "./StyledIcon";

export default function LoadableButton(props) {
    let propsToPass = {};
    Object.keys(props).filter(k => k !== "isLoading").forEach(k => propsToPass[k] = props[k]);
    return (
        <Button {...propsToPass} disabled={props.disabled || props.isLoading}>
            {props.isLoading ? <StyledIcon spin /> : props.children}
        </Button>
    );
}
