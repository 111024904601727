const initState = {
  login: '',
  password: '',
  newPasswordIsRequired: false,
  newPassword: '',
  isLoading: false,
  auth: null,
  ownedCars: [],
  errorMessage: ''
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_PAGE.UPDATE_CREDENTIALS':
      return {
        ...state,
        login: action.login,
        password: action.password
      }
    case 'LOGIN_PAGE.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'LOGIN_PAGE.SET_ERROR_MESSAGE':
      return {
        ...state,
        errorMessage: action.errorMessage
      }
    case 'LOGIN_PAGE.SET_AUTHENTICATED_USER':
      return {
        ...state,
        auth: action.auth
      }
    case 'LOGIN_PAGE.SET_OWNED_CARS':
      return {
        ...state,
        ownedCars: action.ownedCars
      }
    case 'LOGIN_PAGE.SET_NEW_PASSWORD_REQUIRED':
      return {
        ...state,
        newPasswordIsRequired: action.newPasswordIsRequired
      }
    case 'LOGIN_PAGE.SET_NEW_PASSWORD':
      return {
        ...state,
        newPassword: action.newPassword
      }
    default: return state
  }
}

export default reducer
