import React from 'react'
import { Col, Panel } from 'react-bootstrap'
import OwnedCars from './OwnedCars'

export default function ({ outsideUsers, onCarClicked }) {
  return (
    <React.Fragment>
      {
        outsideUsers.map((user) =>
          (
            <Col md={3} key={`${user.details.name}-${user.details.signaturePath}`}>
              <Panel bsStyle='warning'>
                <Panel.Heading>
                  <b>{user.details.name}</b>{' '} {'outside user'}
                </Panel.Heading>
                <Panel.Body>
                  <OwnedCars cars={user.cars} onCarClicked={onCarClicked} />
                </Panel.Body>
              </Panel>
            </Col>
          )
        )
      }
    </React.Fragment>
  )
}
