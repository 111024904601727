import React, { useCallback, useState } from 'react'
import DocumentScanPreview from './DocumentScanPreview'
import { Button } from 'react-bootstrap'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    hide: 'Hide',
    showAll: 'Show all ({0})',
  },
  pl: {
    hide: 'Ukryj',
    showAll: 'Pokaż wszystkie ({0})',
  },
  de: {
    hide: 'Verstecken',
    showAll: 'Alle anzeigen ({0})',
  },
  fr: {
    hide: 'Cacher',
    showAll: 'Tout afficher ({0})',
  }
})

function DocumentScansList({ scans = [{ scan: '' }] }) {
  const [showAll, setShowAll] = useState(false)
  const handleShowToggle = useCallback(() => {
    setShowAll(!showAll)
  }, [showAll])

  return <>
    <div>
      {[...scans].reverse().slice(0, !showAll ? 1 : undefined).map(({ scan }, index) => {
        return (<DocumentScanPreview
          key={`${scan}-${index}`}
          isCurrent={showAll && index === 0}
          scan={scan}
        />)
      })
      }

    </div>
    {scans.length > 1 &&
      <Button
        onClick={handleShowToggle}
        style={{ marginTop: '1em' }}
      >
        {showAll ? strings.hide : strings.formatString(strings.showAll, scans.length)}
      </Button >
    }
  </>
}

export default DocumentScansList