import logoURL from '../../resources/logo.png';
import styled from 'styled-components';

export default styled.img.attrs({
  alt: 'RES Logo',
  src: logoURL
})`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
`
