import React, { Component } from 'react'
import { connect } from 'react-redux'
import getActions from '../actions/actions'
import getUserActions from '../../Users/actions/actions'
import { Grid, Row, Col, Alert, Label, Button } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import UsersList from './UsersList'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'

class Groups extends Component {
  pushToAdd = () => {
    this.props.history.push('/dashboard/group/add')
  }

  onCarClicked = (id) => {
    this.props.history.push(`/dashboard/car/${id}`)
  }

  onUserClicked = (id) => {
    this.props.history.push(`/dashboard/user/${id}/edit?back=/dashboard/groups`)
  }

  onEditClicked = (id) => {
    this.props.history.push(`/dashboard/group/${id}/edit`)
  }

  componentDidMount() {
    this.props.userActions.downloadUsers()
    this.props.downloadGroups()
  }

  groupUsers = () => {
    const groups = []
    for (const group of this.props.groups) {
      groups.push({
        ...group,
        users: [...this.props.users].filter((user) => user.meta.groupId === group._id)
      })
    }
    return groups
  }

  render() {
    const { groups, currentUser, isLoading } = this.props
    const groupedUsers = this.groupUsers()

    let componentToRender
    if (!isLoading) {
      if (!groups) {
        componentToRender = (
          <Col md={6}>
            <Alert bsStyle='warning'>
              No groups found.
            </Alert>
          </Col>
        )
      } else {
        componentToRender = (
          groupedUsers.map((group) => {
            const { _id = '', name = '', users = [] } = group
            const activeUsers = (users || []).filter((user) => !user.archived)

            return (<span key={_id}>
              <Row>
                <h2 style={{ marginLeft: '.5em' }}>
                  <Label>
                    {name}
                    {' '}
                    <Button bsStyle='link' style={{ color: 'white' }} onClick={() => this.onEditClicked(_id)}>Edit</Button>
                  </Label>
                </h2>
              </Row>
              <Row>

                <UsersList
                  users={activeUsers}
                  currentUser={currentUser}
                  onCarClicked={this.onCarClicked}
                  onEditClicked={this.onUserClicked} />
              </Row>
            </span>)
          })
        )
      }
    }

    return (
      <Grid>
        <Row>
          <h1>
            Groups {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>
        <Row>
          {componentToRender}
        </Row>

        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.pushToAdd}
          title='Create new group'>
          <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>
      </Grid>
    )
  }
}

function mapStoreStateToProps({ groups, users, loginPage }) {
  return {
    users: users.users,
    groups: groups.groups,
    isLoading: users.isLoading || groups.isLoading,
    currentUser: { ...loginPage.auth }
  }
}

function mapDispatchToProps(dispatch) {
  return { ...getActions(dispatch), userActions: getUserActions(dispatch) }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(Groups)
