export default function getActions(dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addUser: (user) => {
      dispatch(addUser(user))
    },
    setUsers: (users) => {
      dispatch(setUsers(users))
    },
    setOutsideUsers: (outsideUsers) => {
      dispatch(setOutsideUsers(outsideUsers))
    },
    setNewUser: (id, username, password, role, meta, passwordChangeAtNextLogin) => {
      dispatch(setNewUser(id, username, password, role, meta, passwordChangeAtNextLogin))
    },
    setNewUserCreationState: (wasCreated, error, oldUser) => {
      dispatch(setNewUserCreationState(wasCreated, error, oldUser))
    },
    resetNewUser: () => {
      dispatch(resetNewUser())
    },
    setUserDataBeforeEdit: (username, role, meta, passwordChangeAtNextLogin) => {
      dispatch(setUserDataBeforeEdit(username, role, meta, passwordChangeAtNextLogin))
    },
    downloadUsers: () => {
      dispatch(downloadUsers())
    },
    downloadOutsideUsers: () => {
      dispatch(downloadOutsideUsers())
    },
    downloadUser: (id) => {
      dispatch(downloadUser(id))
    },
    postUser: () => {
      dispatch(postUser())
    },
    putUser: () => {
      dispatch(putUser())
    },
    toggleUserArchiveState: (userId) => {
      dispatch(toggleUserArchiveState(userId))
    }
  }
}

export function updateLoadingState(isLoading) {
  return { type: 'USERS.SET_LOADING', isLoading }
}

export function addUser(user) {
  return {
    type: 'USERS.ADD_USER',
    user
  }
}

export function setUsers(users) {
  return {
    type: 'USERS.SET_USERS',
    users
  }
}

export function setOutsideUsers(outsideUsers) {
  return {
    type: 'USERS.SET_OUTSIDE_USERS',
    outsideUsers
  }
}

export function setNewUser(id, username, password, role, meta, passwordChangeAtNextLogin) {
  const newUser = {
    id, username, password, role, passwordChangeAtNextLogin,
    meta,
    created: false,
    error: '',
  }
  return {
    type: 'USERS.SET_NEW_USER',
    newUser
  }
}

export function resetNewUser() {
  const newUser = {
    id: '',
    username: '',
    password: '',
    role: 'user',
    meta: { groupId: '' },
    passwordChangeAtNextLogin: false,
    created: false,
    error: ''
  }
  return {
    type: 'USERS.SET_NEW_USER',
    newUser
  }
}

export function setUserDataBeforeEdit(username, role, meta, passwordChangeAtNextLogin) {
  const userData = { username, role, meta, passwordChangeAtNextLogin }
  return {
    type: 'USERS.SET_USER_DATA_BEFORE_EDIT',
    userData
  }
}

export function setNewUserCreationState(wasCreated, error, oldUser) {
  const newUser = {
    ...oldUser,
    created: wasCreated,
    error
  }
  return {
    type: 'USERS.SET_NEW_USER',
    newUser
  }
}

export function downloadUsers() {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getUsers()
      .then((response) => {
        if (!response.error) dispatch(setUsers(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadOutsideUsers() {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getOnlyOutsideCars()
      .then((response) => {
        const mappedData = response.data.map((carWithUser) => {
          return { ...carWithUser.user, cars: [{ ...carWithUser, user: undefined }] }
        })
        if (!response.error) dispatch(setOutsideUsers(mappedData))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadUser(id) {
  return (dispatch, getState, api) => {
    const { users } = getState()
    dispatch(updateLoadingState(true))
    return api.getUserById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasUserFound = false
        const usersList = users.users
        // replace existing user
        for (let i = 0; i < usersList.length; i++) {
          if (usersList[i]._id === id) {
            usersList[i] = response.data
            wasUserFound = true
          }
        }

        // or add new if not existent
        if (!wasUserFound) usersList.push(response.data)

        dispatch(setUsers(usersList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postUser() {
  return (dispatch, getState, api) => {
    const { newUser } = getState().users
    console.debug({ newUser })
    dispatch(updateLoadingState(true))
    return api.postUser(newUser)
      .then((response) => {
        if (response.error) {
          dispatch(setNewUserCreationState(false, response.exception.response.data.message, newUser))
          dispatch(updateLoadingState(false))
          return
        }

        // Download users since user will be
        // pushed from editor to users list.
        // Also downloadUsers will handle
        // updateLoadingState
        dispatch(downloadUsers())
        dispatch(setNewUserCreationState(true, '', newUser))
        dispatch(resetNewUser())
      })
  }
}

export function putUser() {
  return (dispatch, getState, api) => {
    const { newUser, userDataBeforeEdit } = getState().users
    const updateObject = { ...newUser }
    dispatch(updateLoadingState(true))

    if (newUser.password.length === 0) delete updateObject.password
    if (newUser.username === userDataBeforeEdit.username) delete updateObject.username
    if (newUser.role === userDataBeforeEdit.role) delete updateObject.role
    if (newUser.passwordChangeAtNextLogin === userDataBeforeEdit.passwordChangeAtNextLogin) delete updateObject.passwordChangeAtNextLogin
    if (newUser.meta.groupId === userDataBeforeEdit.meta.groupId) delete updateObject.meta

    return api.putUser(newUser.id, updateObject)
      .then((response) => {
        if (response.error) {
          dispatch(setNewUserCreationState(false, response.exception.response.data.message, newUser))
          dispatch(updateLoadingState(false))
          return
        }

        // Download users since user will be
        // pushed from editor to users list.
        // Also downloadUsers will handle
        // updateLoadingState
        dispatch(downloadUsers())
        dispatch(setNewUserCreationState(true, '', newUser))
        dispatch(resetNewUser())
      })
  }
}

export function toggleUserArchiveState(user) {
  return (dispatch, getState, api) => {
    const updateObject = { archived: !user.archived }
    dispatch(updateLoadingState(true))

    return api.putUser(user._id, updateObject)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download users since user will be
        // pushed from editor to users list.
        // Also downloadUsers will handle
        // updateLoadingState
        dispatch(downloadUsers())
      })
  }
}