import React from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import FieldGroup from '../../../../components/common/FieldGroup'

export default ({ question, onContentChange, onAnswerTypeChange, onEnterClicked, autoFocus }) => {
  const wasEnterClicked = (event) =>
    event.keyCode === 13 || event.which === 13

  return (
    <React.Fragment>
      <FieldGroup
        id={`question-content-${question.order.toString()}`}
        questionid={question.order}
        label={`Question ${question.order}`}
        type='text'
        help='Eg. Does turn signals work?'
        value={question.content}
        onChange={onContentChange}
        onKeyPress={(e) => wasEnterClicked(e) ? onEnterClicked() : null}
        autoFocus={autoFocus}
      />
      <FormGroup
        controlId={`question-answer-type-${question.order.toString()}`}
        style={{ width: '30%', display: 'inline-block' }}>
        <ControlLabel>Answer type</ControlLabel>
        <FormControl componentClass='select' onChange={onAnswerTypeChange} value={question.answerType}>
          <option value='string'>Text</option>
          <option value='number'>Number</option>
          <option value='boolean'>Yes/No</option>
        </FormControl>
      </FormGroup>
    </React.Fragment>
  )
}
