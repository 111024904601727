import { swap, reorderElements } from './helper'

export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addCarModel: (carModel) => {
      dispatch(addCarModel(carModel))
    },
    setCarModels: (carModels) => {
      dispatch(setCarModels(carModels))
    },
    setNewCarModel: (id, carBodyType, carName, elements) => {
      dispatch(setNewCarModel(id, carBodyType, carName, elements))
    },
    updateElementOfNewCarModel: (element, oldCarModel) => {
      dispatch(updateElementOfNewCarModel(element, oldCarModel))
    },
    addElementToNewCarModel: (element, oldCarModel) => {
      dispatch(addElementToNewCarModel(element, oldCarModel))
    },
    changeElementsOrderInNewCarModel: (elementOrder, orderChange, oldCarModel) => {
      dispatch(changeElementsOrderInNewCarModel(elementOrder, orderChange, oldCarModel))
    },
    deleteElementInNewCarModel: (elementOrder, oldCarModel) => {
      dispatch(deleteElementInNewCarModel(elementOrder, oldCarModel))
    },
    resetNewCarModel: () => {
      dispatch(resetNewCarModel())
    },
    downloadCarModels: () => {
      dispatch(downloadCarModels())
    },
    downloadCarModel: (id) => {
      dispatch(downloadCarModel(id))
    },
    postCarModel: () => {
      dispatch(postCarModel())
    },
    putCarModel: () => {
      dispatch(putCarModel())
    }
  }
}

export function updateLoadingState (isLoading) {
  return { type: 'CAR_MODELS.SET_LOADING', isLoading }
}

export function addCarModel (carModel) {
  return {
    type: 'CAR_MODELS.ADD_CAR_MODEL',
    carModel
  }
}

export function setCarModels (carModels) {
  return {
    type: 'CAR_MODELS.SET_CAR_MODELS',
    carModels
  }
}

export function setNewCarModel (id, carBodyType, carName, elements) {
  const newCarModel = { id, carBodyType, carName, elements }
  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function updateElementOfNewCarModel (element, oldCarModel) {
  const newCarModel = { ...oldCarModel }
  // look for given element in newCarModel
  for (let i = 0; i < oldCarModel.elements.length; i++) {
    if (newCarModel.elements[i].order === element.order) {
      newCarModel.elements[i] = element
    }
  }

  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function addElementToNewCarModel (element, oldCarModel) {
  const newCarModel = { ...oldCarModel }
  newCarModel.elements.push(element)

  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function changeElementsOrderInNewCarModel (elementOrder, orderChange, oldCarModel) {
  const { id, elements, carBodyType, carName } = oldCarModel
  let elementAIndex, elementBIndex

  for (let i = 0; i < elements.length; i++) {
    if (elements[i].order === elementOrder) elementAIndex = i
    if (elements[i].order === elementOrder + orderChange) elementBIndex = i

    if (elementAIndex && elementBIndex) break
  }

  swap(elements, elementAIndex, elementBIndex)
  reorderElements(elements)
  const newCarModel = { id, elements, carBodyType, carName }
  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function deleteElementInNewCarModel (elementOrder, oldCarModel) {
  const { id, elements, carBodyType, carName } = oldCarModel
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].order === elementOrder) {
      elements.splice(i, 1)
      break
    }
  }

  reorderElements(elements)
  const newCarModel = { id, elements, carBodyType, carName }
  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function resetNewCarModel () {
  const newCarModel = {
    id: 'new',
    carBodyType: 'Hatchback',
    carName: '',
    elements: [
      {
        order: 1,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 2,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 3,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 4,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      }
    ]
  }
  return {
    type: 'CAR_MODELS.SET_NEW_CAR_MODEL',
    newCarModel
  }
}

export function downloadCarModels () {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getCarModels()
      .then((response) => {
        if (!response.error) dispatch(setCarModels(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadCarModel (id) {
  return (dispatch, getState, api) => {
    const { carModels } = getState()
    dispatch(updateLoadingState(true))
    return api.getCarModelById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasCarModelFound = false
        const carModelsList = carModels.carModels
        // replace existing carModel
        for (let i = 0; i < carModelsList.length; i++) {
          if (carModelsList[i]._id === id) {
            carModelsList[i] = response.data
            wasCarModelFound = true
          }
        }

        // or add new if not existent
        if (!wasCarModelFound) carModelsList.push(response.data)

        dispatch(setCarModels(carModelsList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postCarModel () {
  return (dispatch, getState, api) => {
    const { newCarModel } = getState().carModels

    newCarModel.model = { elements: newCarModel.elements }

    dispatch(updateLoadingState(true))
    return api.postCarModel(newCarModel)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download carModels since user will be
        // pushed from editor to carModels list.
        // Also downloadCarModels will handle
        // updateLoadingState
        dispatch(downloadCarModels())
      })
  }
}

export function putCarModel () {
  return (dispatch, getState, api) => {
    const { newCarModel } = getState().carModels

    newCarModel.model = { elements: newCarModel.elements }
    delete newCarModel.elements

    dispatch(updateLoadingState(true))
    return api.putCarModel(newCarModel.id, newCarModel)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download carModels since user will be
        // pushed from editor to carModels list.
        // Also downloadCarModels will handle
        // updateLoadingState
        dispatch(downloadCarModels())
      })
  }
}
