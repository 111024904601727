import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

export default ({ questions }) => {
  return (
    <ListGroup>
      {
        questions.map((question) => {
          return (
            <ListGroupItem
              key={question.order}
              header={question.content}>
            Answer type: {answerTypeToHumanReadableString(question.answerType)}
            </ListGroupItem>
          )
        })
      }
    </ListGroup>
  )
}

function answerTypeToHumanReadableString (answerType) {
  switch (answerType) {
    case 'number': return 'Number'
    case 'string': return 'Text'
    case 'boolean': return 'Yes/No'
    default: return 'Unknown'
  }
}
