import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { Glyphicon } from 'react-bootstrap'

const spin = keyframes`
    from {
        transform: scale(1) rotate(0deg);
    }
    to {
        transform: scale(1) rotate(360deg);
    }
`
// eslint-disable-next-line no-unused-vars
export default styled(({ spin, center, size, color, ...rest }) => <Glyphicon {...rest} />).attrs({
  glyph: props => props.glyph || 'refresh'
})`
    ${props =>
    props.spin &&
        css`
            animation: ${spin} 1s infinite linear;
        `}
    ${props =>
    props.center &&
        css`
            
            text-align: center;
        `}
    ${props =>
    props.size === 'big' &&
        css`
            font-size: 30px;
        `}
    ${props =>
    props.size === 'small' &&
        css`
            font-size: 24px;
        `}
    color: ${props => props.color || 'auto'};
`
