import React, { Component } from 'react'
import Button from 'react-bootstrap/lib/Button'

class CarModelRotationArrows extends Component {
  render () {
    const { isPrevAllowed, isNextAllowed } = this.props

    return (
      <div>
        <Button
          disabled={!isPrevAllowed}
          onClick={this.props.onPrevClicked}>
        Prev
        </Button>
        <Button
          disabled={!isNextAllowed}
          onClick={this.props.onNextClicked}
          style={{ float: 'right' }}>
        Next
        </Button>
      </div>
    )
  }
}
export default CarModelRotationArrows
