import React, { Component } from 'react'
import { connect } from 'react-redux'
import getActions from '../actions/carModelsActions'
import { Grid, Col, Row, Alert } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import CarModelsList from './CarModelList'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'

class CarModels extends Component {
  handleCarModelClick = (_id) => {
    this.props.history.push(`/dashboard/carModel/${_id}/latest/element/1`)
  }

  addCarModel = () => {
    this.props.history.push(`/dashboard/carModel/add/element/1`)
  }

  componentDidMount () {
    this.props.downloadCarModels()
  }

  render () {
    const { carModels, isLoading } = this.props

    let componentToRender
    if (!carModels || carModels.length === 0) {
      componentToRender = (
        <Alert bsStyle='warning'>
        No car models found.
        </Alert>
      )
    } else {
      componentToRender = (
        <CarModelsList
          carModels={carModels}
          onCarModelClicked={this.handleCarModelClick} />
      )
    }

    return (
      <Grid>
        <Row>
          <h1>
            Car models {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>
        <Row>
          <Col md={8}>
            { componentToRender }
          </Col>
        </Row>
        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.addCarModel}
          title='Add car model'>
          <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ carModels }) {
  return carModels
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CarModels)
