import React, { Component } from 'react'
import styled from 'styled-components'

const Aspect1By1Div = styled.div`
  position: relative;
  background: blue;
  margin-bottom: 16px;
  background-size: contain;
  box-sizing: border-box;
  cursor: pointer;
  transition: .2s;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
  &:hover {
    transform: scale(1.05);
  }
`

const IssueLabel = styled.p`
background-color: rgba(0, 0, 0, 0.65);
text-align: center;
color: white;
box-sizing: border-box;
padding: 1em .7em;
margin-top: -2em;
`

class CarModelIssueMini extends Component {
  openInNewTab = () => {
    window.open(this.props.issueImageUrl, 'Car issue fullscreen')
  }

  render () {
    return (
      <Aspect1By1Div title='Click to see entire picture' onClick={this.openInNewTab} style={{
        background: `url(${this.props.issueImageUrl}?width=450)`,
        backgroundSize: 'cover' }}>
        {
          this.props.issueType ? <IssueLabel>{ this.props.issueType }</IssueLabel> : null
        }

      </Aspect1By1Div>
    )
  }
}
export default CarModelIssueMini
