import { combineReducers } from 'redux'
import loginPageReducer from '../LoginPage/reducers/loginPageReducer'
import dashboardReducer from '../Dashboard/reducers/dashboardReducer'
import fuelTypesReducer from '../Dashboard/FuelTypes/reducers/fuelTypesReducer'
import carManufacturersReducer from '../Dashboard/CarManufacturers/reducers/carManufacturerReducer'
import questionnairesReducer from '../Dashboard/Questionnaires/reducers/questionnairesReducer'
import regulationsReducer from '../Dashboard/Regulations/reducers/regulationReducer'
import carModelsReducer from '../Dashboard/CarModels/reducers/carModelsReducer'
import carsReducer from '../Dashboard/Cars/reducers/carsReducer'
import usersReducer from '../Dashboard/Users/reducers/usersReducer'
import transfersReducer from '../Dashboard/Transfers/reducers/transferReducers'
import alertsReducer from '../Dashboard/Alerts/reducers/reducers'
import alertTemplatesReducer from '../Dashboard/AlertTemplates/reducers/alertTemplatesReducer'
import groupsReducer from '../Dashboard/Groups/reducers/groupsReducer'

const reducer = combineReducers({
  loginPage: loginPageReducer,
  dashboard: dashboardReducer,
  fuelTypes: fuelTypesReducer,
  carManufacturers: carManufacturersReducer,
  questionnaires: questionnairesReducer,
  regulations: regulationsReducer,
  carModels: carModelsReducer,
  cars: carsReducer,
  users: usersReducer,
  transfers: transfersReducer,
  alerts: alertsReducer,
  alertTemplates: alertTemplatesReducer,
  groups: groupsReducer
})

export default reducer
