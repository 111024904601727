const initState = {
  showMainMenu: false,
  showUserMenu: false,
  isUserMenuAnimating: false

}

const reducer = (state = initState, action) => {
  if (action.type === 'DASHBOARD.TOGGLE_MAIN_MENU') {
    return {
      ...state,
      showMainMenu: !state.showMainMenu
    }
  }

  if (action.type === 'DASHBOARD.CHANGE_USER_MENU_STATE') {
    return {
      ...state,
      showUserMenu: action.shouldBeOpened
    }
  }

  if (action.type === 'DASHBOARD.CHANGE_USER_MENU_ANIMATION_STATE') {
    return {
      ...state,
      isUserMenuAnimating: action.isAnimating
    }
  }

  return state
}

export default reducer
