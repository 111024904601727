import { downloadAlerts } from '../../Alerts/actions/actions'
import { setAuthData, setOwnedCars } from '../../../LoginPage/actions/loginPageActions'

export default function getActions(dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addCar: (car) => {
      dispatch(addCar(car))
    },
    setCars: (car) => {
      dispatch(setCars(car))
    },
    setCarState: (carState) => {
      dispatch(setCarState(carState))
    },
    sendCarStateUpdate: (carId) => {
      dispatch(sendCarStateUpdate(carId))
    },
    clearCarState: () => {
      dispatch(clearCarState())
    },
    setNewCar: (car) => {
      dispatch(setNewCar(car))
    },
    setNewCarCreationState: (wasCreated, error, oldCar) => {
      dispatch(setNewCarCreationState(wasCreated, error, oldCar))
    },
    editQuestionAnswerInNewCar: (questionOrder, newContent, oldCar) => {
      dispatch(editQuestionAnswerInNewCar(questionOrder, newContent, oldCar))
    },
    resetNewCar: () => {
      dispatch(resetNewCar())
    },
    downloadCars: () => {
      dispatch(downloadCars())
    },
    downloadCar: (id) => {
      dispatch(downloadCar(id))
    },
    postCar: () => {
      dispatch(postCar())
    },
    putCar: () => {
      dispatch(putCar())
    },
    setNewCarId: (id) => {
      dispatch(setNewCarId(id))
    },
    setNewCarManufacturerId: (id) => {
      dispatch(setNewCarManufacturerId(id))
    },
    setNewCarFuelTypeId: (id) => {
      dispatch(setNewCarFuelTypeId(id))
    },
    setNewCarCarModelId: (id) => {
      dispatch(setNewCarCarModelId(id))
    },
    setNewCarRegulationId: (id) => {
      dispatch(setNewCarRegulationId(id))
    },
    setNewCarQuestionnaireId: (id) => {
      dispatch(setNewCarQuestionnaireId(id))
    },
    setNewCarCommonNick: (nick) => {
      dispatch(setNewCarCommonNick(nick))
    },
    setNewCarCommonAvatar: (avatar) => {
      dispatch(setNewCarCommonAvatar(avatar))
    },
    setNewCarModelName: (model) => {
      dispatch(setNewCarModelName(model))
    },
    setNewCarColor: (color) => {
      dispatch(setNewCarColor(color))
    },
    setNewCarLicensePlate: (licensePlate) => {
      dispatch(setNewCarLicensePlate(licensePlate))
    },
    setNewCarCurrentMileage: (currentMileage) => {
      dispatch(setNewCarCurrentMileage(currentMileage))
    },
    setNewCarOilChangeDetails: (details) => {
      dispatch(setNewCarOilChangeDetails(details))
    },
    setNewCarInspectionDetails: (details) => {
      dispatch(setNewCarInspectionDetails(details))
    },
    setNewCarFumigationDetails: (details) => {
      dispatch(setNewCarFumigationDetails(details))
    },
    setNewCarInsuranceDetails: (details) => {
      dispatch(setNewCarInsuranceDetails(details))
    },
    setNewCarTimingGearDetails: (details) => {
      dispatch(setNewCarTimingGearDetails(details))
    }
  }
}

export function updateLoadingState(isLoading) {
  return { type: 'CARS.SET_LOADING', isLoading }
}

export function addCar(car) {
  return {
    type: 'CARS.ADD_CAR',
    car
  }
}

export function setCars(cars) {
  return {
    type: 'CARS.SET_CARS',
    cars
  }
}

export function setCarState(carState) {
  return {
    type: 'CARS.SET_CAR_STATE',
    carState
  }
}

export function clearCarState(withSuccess = false) {
  return {
    type: 'CARS.CLEAR_CAR_STATE',
    withSuccess
  }
}

export function setNewCar(newCar) {
  return {
    type: 'CARS.SET_NEW_CAR',
    newCar
  }
}

export function setNewCarId(id) {
  return {
    type: 'CARS.SET_NEW_CAR_ID',
    manufacturerId: id
  }
}

export function setNewCarManufacturerId(newManufacturerId) {
  return {
    type: 'CARS.SET_NEW_CAR_MANUFACTURER_ID',
    manufacturerId: newManufacturerId
  }
}

export function setNewCarFuelTypeId(newFuelTypeId) {
  return {
    type: 'CARS.SET_NEW_CAR_FUEL_TYPE_ID',
    fuelTypeId: newFuelTypeId
  }
}

export function setNewCarCarModelId(newCarModelId) {
  return {
    type: 'CARS.SET_NEW_CAR_CAR_MODEL_ID',
    carModelId: newCarModelId
  }
}

export function setNewCarRegulationId(newRegulationId) {
  return {
    type: 'CARS.SET_NEW_CAR_REGULATION_ID',
    regulationId: newRegulationId
  }
}

export function setNewCarQuestionnaireId(newQuestionnaireId) {
  return {
    type: 'CARS.SET_NEW_CAR_QUESTIONNAIRE_ID',
    questionnaireId: newQuestionnaireId
  }
}

export function setNewCarCommonNick(nick) {
  return {
    type: 'CARS.SET_NEW_CAR_COMMON_NICK',
    nick
  }
}

export function setNewCarCommonAvatar(avatar) {
  return {
    type: 'CARS.SET_NEW_CAR_COMMON_AVATAR',
    avatar
  }
}

export function setNewCarModelName(model) {
  return {
    type: 'CARS.SET_NEW_CAR_MODEL_NAME',
    model
  }
}

export function setNewCarColor(color) {
  return {
    type: 'CARS.SET_NEW_CAR_COLOR',
    color
  }
}

export function setNewCarLicensePlate(licensePlate) {
  return {
    type: 'CARS.SET_NEW_LICENSE_PLATE',
    licensePlate
  }
}

export function setNewCarCurrentMileage(currentMileage) {
  return {
    type: 'CARS.SET_NEW_CAR_CURRENT_MILEAGE',
    currentMileage
  }
}

export function setNewCarOilChangeDetails(details) {
  return {
    type: 'CARS.SET_NEW_CAR_OIL_CHANGE_DETAILS',
    details
  }
}

export function setNewCarInspectionDetails(details) {
  return {
    type: 'CARS.SET_NEW_CAR_INSPECTION_DETAILS',
    details
  }
}

export function setNewCarFumigationDetails(details) {
  return {
    type: 'CARS.SET_NEW_CAR_FUMIGATION_DETAILS',
    details
  }
}

export function setNewCarInsuranceDetails(details) {
  return {
    type: 'CARS.SET_NEW_CAR_INSURANCE_DETAILS',
    details
  }
}

export function setNewCarTimingGearDetails(details) {
  return {
    type: 'CARS.SET_NEW_CAR_TIMING_GEAR_DETAILS',
    details
  }
}

export function editQuestionAnswerInNewCar(questionOrder, newContent, oldCar) {
  const { questionnaireAnswers } = { ...oldCar }
  for (let i = 0; i < questionnaireAnswers.length; i++) {
    if (questionnaireAnswers[i].questionOrder === questionOrder) {
      questionnaireAnswers[i] = {
        ...questionnaireAnswers[i],
        answer: newContent
      }
      break
    }
  }

  const newCar = { ...oldCar, questionnaireAnswers }
  return {
    type: 'CARS.SET_NEW_CAR',
    newCar
  }
}

export function resetNewCar() {
  const nowYearAfter = new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate(), 12)
  const nowFiveYearsAfter = new Date(new Date().getFullYear() + 5, new Date().getMonth(), new Date().getDate(), 12)
  // below is a today's date without current hour
  const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
  return {
    type: 'CARS.SET_NEW_CAR',
    newCar: {
      id: 'new',
      carModelId: '',
      manufacturerId: '',
      fuelTypeId: '',
      regulationId: '',
      transfersHistoryId: '',
      model: '',
      color: '',
      licensePlate: '',
      readme: '',
      adminReadme: '',
      vin: '',
      productionDate: today,
      engine: {
        power: 0,
        capacity: 0.0
      },
      currentMileage: 0,
      oilChange: {
        date: today,
        mileage: 0,
        nextChangeDate: nowYearAfter,
        nextChangeMileage: 10000,
        changeMileageFrequency: 10000
      },
      inspection: {
        date: today,
        nextInspectionDate: nowYearAfter
      },
      fumigation: {
        date: today,
        nextFumigationDate: nowYearAfter
      },
      insurance: {
        date: today,
        // business logic here:
        // insurance is valid for a year but insurance begin period is excluded in next year, eg:
        // insurance is valid for 8.03.2019 - 7.03.2020
        validUntilDate: new Date(nowYearAfter.getFullYear(), nowYearAfter.getMonth(), nowYearAfter.getDate() - 1, 12)
      },
      timingGearChange: {
        mileage: 0,
        nextChangeMileage: 100000,
        changeMileageFrequency: 100000
      },
      lpgApproval: {
        date: today,
        mileage: 0,
        approvalValidUntil: nowFiveYearsAfter,
        nextApprovalMileage: 10000,
        approvalMileageFrequency: 10000
      },
      questionnaireAnswers: [],
      created: false,
      error: ''
    }
  }
}

export function setNewCarCreationState(wasCreated, error, oldCar) {
  const newCar = {
    ...oldCar,
    created: wasCreated,
    error
  }
  return {
    type: 'CARS.SET_NEW_CAR',
    newCar
  }
}

export function downloadCars() {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getCars()
      .then((response) => {
        if (!response.error) dispatch(setCars(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadCar(id) {
  return (dispatch, getState, api) => {
    const { cars } = getState()
    dispatch(updateLoadingState(true))
    return api.getCarById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasCarFound = false
        const carsList = cars.cars
        // replace existing car
        for (let i = 0; i < carsList.length; i++) {
          if (carsList[i]._id === id) {
            carsList[i] = response.data
            wasCarFound = true
          }
        }

        // or add new if not existent
        if (!wasCarFound) carsList.push(response.data)

        dispatch(setCars(carsList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postCar() {
  return (dispatch, getState, api) => {
    const { newCar } = getState().cars
    dispatch(updateLoadingState(true))

    // feature not planned for this front app
    // but required by the backend -
    // make it a default value at every API call
    newCar.carModelIssues = []
    if (newCar.common && !newCar.common.avatar && !newCar.common.nick) delete newCar.common
    if (newCar.insuranceDocumentScan.scan) newCar.insuranceDocumentScan = newCar.insuranceDocumentScan.scan
    if (newCar.proofOfRegistrationScan.scan) newCar.proofOfRegistrationScan = newCar.proofOfRegistrationScan.scan

    return api.postCar(newCar)
      .then((response) => {
        if (response.error) {
          dispatch(setNewCarCreationState(false, prepareErrorMessageWithConflicts(response.exception.response.data), newCar))
          dispatch(updateLoadingState(false))
          return
        }

        const createdCarId = response.data.createdCarId
        newCar._id = createdCarId

        // Download cars since user will be
        // pushed from editor to cars list.
        // Also downloadCars will handle
        // updateLoadingState
        dispatch(downloadCars())
        dispatch(setNewCarCreationState(true, '', newCar))
        dispatch(downloadAlerts())
        dispatch(resetNewCar())
      })
  }
}

export function putCar() {
  return async (dispatch, getState, api) => {
    const { newCar } = getState().cars
    const oldCar = (await api.getCarById(newCar.id)).data
    const changes = findChangesInCar(oldCar, newCar)
    dispatch(updateLoadingState(true))

    if (!changes.common) changes.common = {}

    return api.putCar(newCar.id, changes)
      .then((response) => {
        if (response.error) {
          dispatch(setNewCarCreationState(false, prepareErrorMessageWithConflicts(response.exception.response.data), newCar))
          dispatch(updateLoadingState(false))
          return
        }

        // Download cars since user will be
        // pushed from editor to cars list.
        // Also downloadCars will handle
        // updateLoadingState
        dispatch(downloadCars())
        dispatch(setNewCarCreationState(true, '', newCar))
        dispatch(downloadAlerts())
        dispatch(resetNewCar())
      })
  }
}

function prepareErrorMessageWithConflicts(errorResponseData) {
  let message = errorResponseData.message + '\n'
  errorResponseData.conflicts.forEach((conflict) => {
    message += `${conflict.error}\n`
  })

  return message
}

function findChangesInCar(oldCar, newCar) {
  const changes = {
    manufacturerId: newCar.manufacturerId,
    fuelTypeId: newCar.fuelTypeId,
    carModelId: newCar.carModelId,
    questionnaireId: newCar.questionnaireId,
    common: newCar.common,
    model: newCar.model,
    color: newCar.color,
    licensePlate: newCar.licensePlate,
    readme: newCar.readme,
    adminReadme: newCar.adminReadme,
    tires: newCar.tires,
    archived: newCar.archived
  }

  if (oldCar.regulationId !== newCar.regulationId) changes.regulationId = newCar.regulationId

  const currentCarState = getCarState(oldCar)
  const newCarState = { ...newCar }

  if (currentCarState.productionDate !== newCarState.productionDate) changes.productionDate = newCarState.productionDate
  if (currentCarState.vin !== newCarState.vin) changes.vin = newCarState.vin
  if (currentCarState.engine.power !== newCarState.engine.power ||
    currentCarState.engine.capacity !== newCarState.engine.capacity) {
    changes.engine = newCarState.engine
  }
  if (currentCarState.currentMileage !== newCarState.currentMileage) changes.currentMileage = newCarState.currentMileage
  if (currentCarState.oilChange.mileage !== newCarState.oilChange.mileage ||
    currentCarState.oilChange.nextChangeMileage !== newCarState.oilChange.nextChangeMileage ||
    new Date(currentCarState.oilChange.date).getTime() !== new Date(newCarState.oilChange.date).getTime() ||
    new Date(currentCarState.oilChange.nextChangeDate).getTime() !== new Date(newCarState.oilChange.nextChangeDate).getTime()) {
    changes.oilChange = { ...newCarState.oilChange }
  }
  if (new Date(currentCarState.insurance.date).getTime() !== new Date(newCarState.insurance.date).getTime() ||
    new Date(currentCarState.insurance.validUntilDate).getTime() !== new Date(newCarState.insurance.validUntilDate).getTime()) {
    changes.insurance = { ...newCarState.insurance }
  }
  if (new Date(currentCarState.fumigation.date).getTime() !== new Date(newCarState.fumigation.date).getTime() ||
    new Date(currentCarState.fumigation.nextFumigationDate).getTime() !== new Date(newCarState.fumigation.nextFumigationDate).getTime()) {
    changes.fumigation = { ...newCarState.fumigation }
  }
  if (new Date(currentCarState.inspection.date).getTime() !== new Date(newCarState.inspection.date).getTime() ||
    new Date(currentCarState.inspection.nextInspectionDate).getTime() !== new Date(newCarState.inspection.nextInspectionDate).getTime()) {
    changes.inspection = { ...newCarState.inspection }
  }
  if (currentCarState.timingGearChange.mileage !== newCarState.timingGearChange.mileage ||
    currentCarState.timingGearChange.nextChangeMileage !== newCarState.timingGearChange.nextChangeMileage) {
    changes.timingGearChange = { ...newCarState.timingGearChange }
  }
  if (newCarState.lpgApproval && currentCarState.lpgApproval) {
    if (new Date(currentCarState.lpgApproval.date).getTime() !== new Date(newCarState.lpgApproval.date).getTime() ||
      new Date(currentCarState.lpgApproval.approvalValidUntil).getTime() !== new Date(newCarState.lpgApproval.approvalValidUntil).getTime() ||
      currentCarState.lpgApproval.mileage !== newCarState.lpgApproval.mileage ||
      currentCarState.lpgApproval.nextApprovalMileage !== newCarState.lpgApproval.nextApprovalMileage) {
      changes.lpgApproval = { ...newCarState.lpgApproval }
    }
  } else if (newCarState.lpgApproval) changes.lpgApproval = { ...newCarState.lpgApproval }
  if (currentCarState.insuranceDocumentScan.scan !== newCarState.insuranceDocumentScan.scan) {
    changes.insuranceDocumentScan = { ...newCarState.insuranceDocumentScan }
  }
  if (currentCarState.proofOfRegistrationScan.scan !== newCarState.proofOfRegistrationScan.scan) {
    changes.proofOfRegistrationScan = { ...newCarState.proofOfRegistrationScan }
  }

  return changes
}

function getCarState(car) {
  const currentCarState = { ...car }
  currentCarState.currentMileage = currentCarState.mileageRecords[currentCarState.mileageRecords.length - 1].mileage
  currentCarState.inspection = { ...currentCarState.inspectionsHistory[currentCarState.inspectionsHistory.length - 1] }
  currentCarState.fumigation = { ...currentCarState.fumigationHistory[currentCarState.fumigationHistory.length - 1] }
  currentCarState.insurance = { ...currentCarState.insuranceHistory[currentCarState.insuranceHistory.length - 1] }
  currentCarState.timingGearChange = { ...currentCarState.timingGearChangesHistory[currentCarState.timingGearChangesHistory.length - 1] }
  currentCarState.timingGearChange.changeMileageFrequency = currentCarState.timingGearChange.nextChangeMileage - currentCarState.timingGearChange.mileage
  currentCarState.oilChange = { ...currentCarState.oilChangesHistory[currentCarState.oilChangesHistory.length - 1] }
  currentCarState.oilChange.changeMileageFrequency = currentCarState.oilChange.nextChangeMileage - currentCarState.oilChange.mileage

  if (currentCarState.lpgApprovalHistory) {
    currentCarState.lpgApproval = { ...currentCarState.lpgApprovalHistory[currentCarState.lpgApprovalHistory.length - 1] }
  }

  currentCarState.insuranceDocumentScan = car.insuranceDocumentScans.length > 0 ? { ...car.insuranceDocumentScans[car.insuranceDocumentScans.length - 1] } : {}
  currentCarState.proofOfRegistrationScan = car.proofOfRegistrationScans.length > 0 ? { ...car.proofOfRegistrationScans[car.proofOfRegistrationScans.length - 1] } : {}

  return currentCarState
}

export function sendCarStateUpdate(carId) {
  return async (dispatch, getState, api) => {
    const { carState } = getState().cars
    dispatch(updateLoadingState(true))

    const oldCar = (await api.getCarById(carId)).data
    const changes = {}

    if (carState.mileage) {
      changes.currentMileage = carState.mileage
    }

    if (carState.oilChange.mileage || carState.oilChange.date) {
      const lastOilChange = oldCar.oilChangesHistory.reverse()[0]
      const mileageFrequency = lastOilChange.nextChangeMileage - lastOilChange.mileage
      const yearFrequency = (new Date(lastOilChange.nextChangeDate).getFullYear() - new Date(lastOilChange.date).getFullYear()) || 1

      const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
      const oilChangeDate = carState.oilChange.date || today
      const oilChangeMileage = carState.oilChange.mileage || carState.mileage || oldCar.mileageRecords.reverse()[0].mileage
      changes.oilChange = {
        date: oilChangeDate,
        nextChangeDate: addDays(oilChangeDate, 365 * yearFrequency),
        mileage: oilChangeMileage,
        nextChangeMileage: oilChangeMileage + mileageFrequency,
      }
    }

    if (carState.timingGearChange.mileage || carState.timingGearChange.date) {
      const lastTimingGearChange = oldCar.timingGearChangesHistory.reverse()[0]
      const mileageFrequency = lastTimingGearChange.nextChangeMileage - lastTimingGearChange.mileage
      const yearFrequency = (new Date(lastTimingGearChange.nextChangeDate).getFullYear() - new Date(lastTimingGearChange.date).getFullYear()) || 10

      const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
      const timingGearChangeDate = carState.timingGearChange.date || today
      const timingGearChangeMileage = carState.timingGearChange.mileage || carState.mileage || oldCar.mileageRecords.reverse()[0].mileage
      changes.timingGearChange = {
        date: timingGearChangeDate,
        nextChangeDate: addDays(timingGearChangeDate, 365 * yearFrequency),
        mileage: timingGearChangeMileage,
        nextChangeMileage: timingGearChangeMileage + mileageFrequency,
      }
    }

    if (carState.inspection.date) {
      const lastInspection = oldCar.inspectionsHistory.reverse()[0]
      const yearFrequency = (new Date(lastInspection.nextInspectionDate).getFullYear() - new Date(lastInspection.date).getFullYear()) || 1

      const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
      const inspectionDate = carState.inspection.date || today
      changes.inspection = {
        date: inspectionDate,
        nextInspectionDate: addDays(inspectionDate, 365 * yearFrequency),
      }
    }

    if (carState.fumigation.date) {
      const lastFumigation = oldCar.fumigationHistory.reverse()[0]
      const yearFrequency = (new Date(lastFumigation.nextFumigationDate).getFullYear() - new Date(lastFumigation.date).getFullYear()) || 1

      const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 12)
      const fumigationDate = carState.fumigation.date || today
      changes.fumigation = {
        date: fumigationDate,
        nextFumigationDate: addDays(fumigationDate, 365 * yearFrequency),
      }
    }

    return api.updateCarState(carId, changes)
      .then(async (response) => {
        if (response.error) {
          if (response.exception.response) {
            dispatch(setCarState({ ...carState, error: prepareErrorMessageWithConflicts(response.exception.response.data) }))
          }
          dispatch(updateLoadingState(false))
          return
        }

        dispatch(downloadCar(carId))
        dispatch(updateLoadingState(false))
        const meResponse = await api.me()
        if (!meResponse.error) {
          setAuthData(meResponse.data.user)
          setOwnedCars(meResponse.data.cars)
        }
        dispatch(downloadAlerts())
        dispatch(clearCarState(true))
      })
  }
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

