import React from 'react'
import Table from './Table'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    mileage: 'Mileage (km)',
    nextMileage: 'Next mileage (km)',
  },
  pl: {
    mileage: 'Przebieg (km)',
    nextMileage: 'Maksymalny przebieg (km)',
  },
  de: {
    mileage: 'Kilometerstand (km)',
    nextMileage: 'Nächster Kilometerstand (km)',
  },
  fr: {
    mileage: 'Kilométrage (km)',
    nextMileage: 'Prochain kilométrage (km)',
  }
})

export default ({ list }) => {
  let i = 0

  return (
    <Table>
      <thead>
        <tr>
          <th>{strings.mileage}</th>
          <th>{strings.nextMileage}</th>
        </tr>
      </thead>
      <tbody>
        {
          list ? list.map((element) => {
            i++
            return (
              <tr key={`timing-gear-changes-list-element-${i}`}>
                <td>
                  {element.mileage}
                </td>
                <td>
                  {element.nextChangeMileage}
                </td>
              </tr>
            )
          }) : null
        }
      </tbody>
    </Table>
  )
}
