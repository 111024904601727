import React from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, ListGroup, ListGroupItem, Alert } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import getActions from '../actions/fuelTypeActions'

const FuelTypes = function ({ fuelTypes, isLoading, updateLoadingState }) {
  let componentToRender
  if (!fuelTypes) {
    componentToRender = (
      <Alert bsStyle='warning'>
        No fuel types found.
      </Alert>
    )
  } else {
    componentToRender = (
      <ListGroup>
        {
          fuelTypes.map((fuelType) => {
            return (
              <ListGroupItem key={fuelType._id}>
                {fuelType.name}
              </ListGroupItem>
            )
          })
        }
      </ListGroup>
    )
  }

  return (
    <Grid>
      <Row>
        <h1>
          Fuel types {' '}
          <LoadingIcon isLoading={isLoading} />
        </h1>
      </Row>

      <Row>
        <Col md={4}>
          { componentToRender }
        </Col>
      </Row>
    </Grid>
  )
}

function mapStoreStateToProps ({ fuelTypes }) {
  return fuelTypes
}

function mapActionsToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapActionsToProps)(FuelTypes)
