export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addTransfer: (transfer) => {
      dispatch(addTransfer(transfer))
    },
    setTransfers: (transfers) => {
      dispatch(setTransfers(transfers))
    },
    downloadTransfer: (id) => {
      dispatch(downloadTransfer(id))
    }
  }
}

export function updateLoadingState (isLoading) {
  return { type: 'TRANSFERS.SET_LOADING', isLoading }
}

export function addTransfer (transfer) {
  return {
    type: 'TRANSFERS.ADD_TRANSFER',
    transfer
  }
}

export function setTransfers (transfers) {
  return {
    type: 'TRANSFERS.SET_TRANSFERS',
    transfers
  }
}

export function downloadTransfer (id) {
  return (dispatch, getState, api) => {
    const { transfers } = getState()
    dispatch(updateLoadingState(true))
    return api.getTransferById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasTransferFound = false
        const transfersList = transfers.transfers
        // replace existing transfer
        for (let i = 0; i < transfersList.length; i++) {
          if (transfersList[i]._id === id) {
            transfersList[i] = response.data
            wasTransferFound = true
          }
        }

        // or add new if not existent
        if (!wasTransferFound) transfersList.push(response.data)

        dispatch(setTransfers(transfersList))
        dispatch(updateLoadingState(false))
      })
  }
}
