import React, { Component } from 'react'
import { connect } from 'react-redux'
import { me } from '../../api'
import getActions from '../../LoginPage/actions/loginPageActions'

class AuthChecker extends Component {
  isCurrentlyAuthenticated = () => {
    return this.props.user.auth
  }

  tryAuthWithSessionId = async () => {
    const response = await me()
    if (!response.error) {
      this.props.setAuthData(response.data.user)
      this.props.setOwnedCars(response.data.cars)
    }

    return !response.error
  }

  render() {
    if (!this.isCurrentlyAuthenticated()) {
      this.tryAuthWithSessionId()
        .then((isAuthenticated) => {
          if (!isAuthenticated) {
            if (this.props.onNoAuth) this.props.onNoAuth()
          } else {
            if (this.props.onAuthOk) this.props.onAuthOk()
          }
        })
    }

    return (
      <React.Fragment />
    )
  }
}

function mapStoreStateToProps({ loginPage }) {
  return { user: { ...loginPage } }
}

function mapDispatchToProps(dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(AuthChecker)
