import styled from "styled-components";

export default styled.div`
    width: 20%;
    margin: 0 auto;
    @media (max-width: 1300px) {
        width: 50%;
    }
    @media (max-width: 800px) {
        width: 99%;
    }
   
`;
