import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Panel, FormGroup, ControlLabel, FormControl, Alert } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import '../../../../styles/react-draft-wysiwyg-custom.css'
import { cloneDeep } from 'lodash'
import getActions from '../../actions/alertTemplatesActions'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import { MdSave as SaveIcon } from 'react-icons/md'
import LoadingIcon from '../../../../components/common/LoadingIcon'

class EditAlertTemplates extends Component {
  lastKnownQueryParams = new URLSearchParams()

  constructor(props) {
    super(props)
    this.state = {
      initialContentLoaded: false,
      dateAlertEditorState: EditorState.createEmpty(),
      mileageAlertEditorState: EditorState.createEmpty(),
    }
  }

  componentDidMount() {
    const { language } = this.props.match.params
    this.props.downloadAlertTemplates(language)
  }

  componentDidUpdate() {
    const currentQuery = new URLSearchParams(this.props.location.search)
    if (this.state.initialContentLoaded && this.lastKnownQueryParams.toString() === currentQuery.toString()) {
      return
    }
    this.lastKnownQueryParams = currentQuery

    this.matchRichEditorContentToState()
  }

  matchRichEditorContentToState = () => {
    const { notificationType, alertType, matter } = this.targetAlert()
    const { date, mileage } = this.props.alertTemplates[notificationType][alertType][matter]
    this.setDateAlertEditorText(date.body)
    this.setMileageAlertEditorText(mileage.body)
  }

  setDateAlertEditorText = (markdownContent) => {
    console.debug({ markdownContent })
    this.setState({
      initialContentLoaded: true,
      dateAlertEditorState:
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(markdownContent)
          )
        )
    })
  }

  setMileageAlertEditorText = (markdownContent) => {
    this.setState({
      initialContentLoaded: true,
      mileageAlertEditorState:
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(markdownContent)
          )
        )
    })
  }

  handleDateAlertEditorStateChange = (editorState) => {
    this.setState({ dateAlertEditorState: editorState })
    this.onBodyChanged(
      draftToMarkdown(
        convertToRaw(
          editorState.getCurrentContent()
        )
      ),
      'date'
    )
  }

  handleMileageAlertEditorStateChange = (editorState) => {
    this.setState({ mileageAlertEditorState: editorState })
    this.onBodyChanged(
      draftToMarkdown(
        convertToRaw(
          editorState.getCurrentContent()
        )
      ),
      'mileage'
    )
  }

  handleNotificationTypeChange = ({ target }) => {
    const { value } = target
    const currentPath = this.props.location.pathname
    const currentQuery = new URLSearchParams(this.props.location.search)
    currentQuery.set('notificationType', value)
    this.props.history.push(`${currentPath}?${currentQuery.toString()}`)
  }

  handleMatterChange = ({ target }) => {
    const { value } = target
    const currentPath = this.props.location.pathname
    const currentQuery = new URLSearchParams(this.props.location.search)
    currentQuery.set('matter', value)
    this.props.history.push(`${currentPath}?${currentQuery.toString()}`)
  }

  handleAlertTypeChange = ({ target }) => {
    const { value } = target
    const currentPath = this.props.location.pathname
    const currentQuery = new URLSearchParams(this.props.location.search)
    currentQuery.set('alertType', value)
    this.props.history.push(`${currentPath}?${currentQuery.toString()}`)
  }

  handleDateAlertTitleChange = ({ target }) => {
    const { value } = target
    this.onTitleChanged(value, 'date')
  }

  handleMileageAlertTitleChange = ({ target }) => {
    const { value } = target
    this.onTitleChanged(value, 'mileage')
  }

  onBodyChanged = (body, matterType) => {
    const { language } = this.props.alertTemplates
    const newTemplates = cloneDeep(this.props.alertTemplates)
    const { notificationType, alertType, matter } = this.targetAlert()
    newTemplates[notificationType][alertType][matter][matterType].body = body
    this.props.setAlertTemplates(newTemplates, language)
  }

  onTitleChanged = (title, matterType) => {
    const { language } = this.props.alertTemplates
    const newTemplates = cloneDeep(this.props.alertTemplates)
    const { notificationType, alertType, matter } = this.targetAlert()
    newTemplates[notificationType][alertType][matter][matterType].title = title
    this.props.setAlertTemplates(newTemplates, language)
  }

  updateAlertTemplates = () => {
    const { language } = this.props.match.params
    this.props.saveAlertTemplates(language)
  }

  targetAlert = (queryString = this.props.location.search) => {
    const query = new URLSearchParams(queryString)
    return {
      notificationType: query.get('notificationType') || 'email',
      matter: query.get('matter') || 'oilChange',
      alertType: query.get('alertType') || 'warning',
    }
  }

  render() {
    const { alertTemplates, isLoading } = this.props
    const { language } = alertTemplates
    const { notificationType, alertType, matter } = this.targetAlert()

    return (
      <Grid>
        <h1>Alert templates for {isLoading ? <LoadingIcon isLoading /> : language}</h1>
        <Row>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>Notification type</ControlLabel>
              <FormControl
                componentClass='select'
                value={notificationType}
                onChange={this.handleNotificationTypeChange}>
                <option value='email'>Email</option>
                <option value='push'>Push notification</option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>Matter</ControlLabel>
              <FormControl
                componentClass='select'
                value={matter}
                onChange={this.handleMatterChange}>
                <option value='oilChange'>Oil change</option>
                <option value='timingGear'>Timing gear change</option>
                <option value='insurance'>Insurance</option>
                <option value='inspection'>Inspection</option>
                <option value='fumigation'>Fumigation</option>
                <option value='lpgApproval'>LPG approval</option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>Alert type</ControlLabel>
              <FormControl
                componentClass='select'
                value={alertType}
                onChange={this.handleAlertTypeChange}>
                <option value='warning'>Warning</option>
                <option value='error'>Overage</option>
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FieldGroup
              id='date-alert-title'
              label='Date alert title'
              type='text'
              help='Eg. Maintenance required'
              value={alertTemplates[notificationType][alertType][matter].date.title}
              onChange={this.handleDateAlertTitleChange} />
            <Panel>
              <Panel.Heading>Date alert body</Panel.Heading>
              <Panel.Body>
                <Editor
                  editorState={this.state.dateAlertEditorState}
                  placeholder='Alert content'
                  onEditorStateChange={this.handleDateAlertEditorStateChange}
                  toolbar={{ options: ['emoji', 'history'], }}
                />
              </Panel.Body>
            </Panel>
          </Col>
          <Col md={6}>
            <FieldGroup
              id='mileage-alert-title'
              label='Mileage alert title'
              type='text'
              help='Eg. Maintenance required'
              value={alertTemplates[notificationType][alertType][matter].mileage.title}
              onChange={this.handleMileageAlertTitleChange} />
            <Panel>
              <Panel.Heading>Mileage alert body</Panel.Heading>
              <Panel.Body>
                <Editor
                  editorState={this.state.mileageAlertEditorState}
                  placeholder='Alert content'
                  onEditorStateChange={this.handleMileageAlertEditorStateChange}
                  toolbar={{ options: ['emoji', 'history'], }}
                />
              </Panel.Body>
            </Panel>
          </Col>

          <Col md={12}>
            <Alert bsStyle={'info'}>
              <p>Templates will swap content in double braces ({"{{ example }}"}) with real data before sending the alert out. Please find available data below:</p>
              <ul>
                <li><b>{"{{ car.model }}"}</b> – car model, eg. Astra,</li>
                <li><b>{"{{ car.shortName }}"}</b> – car's nickname, if available, eg. Pralinka,</li>
                <li><b>{"{{ car.licensePlate }}"}</b> – car's license plate, eg. SGL 123AB,</li>
                <li><b>{"{{ issue.name }}"}</b> – maintenance matter, eg. inspection,</li>
                <li><b>{"{{ issue.limit }}"}</b> – days/kilometers before (when sending warnings) or after (when sending overage alerts) scheduled maintenance, eg. 14.</li>
              </ul>
              <br />
              <p>Example, for an incoming inspection reminder:</p>
              <p>Title: <b>{"{{ car.licensePlate }}"}</b> to be maintained soon<br />
                Body: Your <b>{"{{ car.model }}"}</b> (<b>{"{{ car.licensePlate }}"}</b>) is soon to be maintained. <b>{"{{ issue.name }}"}</b> have to be performed in <b>{"{{ issue.limit }}"}</b> days.</p>
              <p>will be sent as:</p>
              <p>Title: <b>SGL 123AB</b> to be maintained soon <br />
                Body: Your <b>Astra</b> (<b>{"SGL 123AB"}</b>) is soon to be maintained – <b>inspection</b> have to be performed in <b>14</b> days.</p>

            </Alert>
          </Col>
          {
            notificationType === 'email' && (
              <Col md={12}>
                <Alert bsStyle={'info'}>
                  <p>Email notifications support <a href="https://www.w3schools.com/html/" target="_blank" rel="noopener noreferrer">HTML</a>.</p>
                </Alert>
              </Col>
            )
          }
        </Row>

        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.updateAlertTemplates}
          title='Save changes'>
          <SaveIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>

      </Grid>
    )
  }
}

function mapStoreStateToProps({ alertTemplates }) {
  return alertTemplates
}

function mapDispatchToProps(dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(EditAlertTemplates)
