export default function getActions(dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addGroup: (group) => {
      dispatch(addGroup(group))
    },
    setGroups: (groups) => {
      dispatch(setGroups(groups))
    },
    setNewGroup: (id, name) => {
      dispatch(setNewGroup(id, name))
    },
    setNewGroupCreationState: (wasCreated, error) => {
      dispatch(setNewGroupCreationState(wasCreated, error))
    },
    resetNewGroup: () => {
      dispatch(resetNewGroup())
    },
    downloadGroups: () => {
      dispatch(downloadGroups())
    },
    downloadGroup: (id) => {
      dispatch(downloadGroup(id))
    },
    postGroup: () => {
      dispatch(postGroup())
    },
    putGroup: () => {
      dispatch(putGroup())
    }
  }
}

export function updateLoadingState(isLoading) {
  return { type: 'GROUPS.SET_LOADING', isLoading }
}

export function addGroup(group) {
  return {
    type: 'GROUPS.ADD_GROUP',
    group
  }
}

export function setGroups(groups) {
  return {
    type: 'GROUPS.SET_GROUPS',
    groups
  }
}

export function setNewGroup(_id, name) {
  const newGroup = { _id, name, created: false, error: '' }
  return {
    type: 'GROUPS.SET_NEW_GROUP',
    newGroup
  }
}

export function resetNewGroup() {
  const newGroup = {
    id: '',
    name: '',
    created: false,
    error: ''
  }
  return {
    type: 'GROUPS.SET_NEW_GROUP',
    newGroup
  }
}

export function setNewGroupCreationState(wasCreated, error) {
  return (dispatch, getState, api) => {
    const state = getState()
    const newGroup = {
      ...state.groups.newGroup,
      created: wasCreated,
      error
    }
    dispatch({
      type: 'GROUPS.SET_NEW_GROUP',
      newGroup
    })
  }

}

export function downloadGroups() {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getGroups()
      .then((response) => {
        if (!response.error) dispatch(setGroups(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadGroup(id) {
  return (dispatch, getState, api) => {
    const { groups } = getState()
    dispatch(updateLoadingState(true))
    return api.getGroupById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasGroupFound = false
        const groupsList = groups.groups
        // replace existing user
        for (let i = 0; i < groupsList.length; i++) {
          if (groupsList[i]._id === id) {
            groupsList[i] = response.data
            wasGroupFound = true
          }
        }

        // or add new if not existent
        if (!wasGroupFound) groupsList.push(response.data)

        dispatch(setGroups(groupsList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postGroup() {
  return (dispatch, getState, api) => {
    const { newGroup } = getState().groups
    dispatch(updateLoadingState(true))
    return api.postGroup(newGroup)
      .then((response) => {
        if (response.error) {
          dispatch(setNewGroupCreationState(false, response.exception.response.data.message))
          dispatch(updateLoadingState(false))
          return
        }

        // Download users since user will be
        // pushed from editor to users list.
        // Also downloadUsers will handle
        // updateLoadingState
        dispatch(downloadGroups())
        dispatch(setNewGroupCreationState(true, ''))
        dispatch(resetNewGroup())
      })
  }
}

export function putGroup() {
  return (dispatch, getState, api) => {
    const { newGroup } = getState().groups
    const updateObject = { ...newGroup }
    dispatch(updateLoadingState(true))

    return api.putGroup(newGroup._id, updateObject)
      .then((response) => {
        if (response.error) {
          dispatch(setNewGroupCreationState(false, response.exception.response.data.message))
          dispatch(updateLoadingState(false))
          return
        }

        // Download users since user will be
        // pushed from editor to users list.
        // Also downloadUsers will handle
        // updateLoadingState
        dispatch(downloadGroups())
        dispatch(setNewGroupCreationState(true, ''))
        dispatch(resetNewGroup())
      })
  }
}
