import styled from 'styled-components'

const FAB = styled.div`
  background-color:#193f77;
  width: ${props => props.mini ? '48px' : '60px'}
  height: ${props => props.mini ? '48px' : '60px'}
  line-height: ${props => props.mini ? '48px' : '60px'}
  text-align:center;
  border-radius:100%;
  background:#193f77;
  border:none;
  outline:none;
  color:#FFF;
  font-size:36px;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  transition:.3s;  
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: pointer;

  &:hover {
    transform:scale(1.1);
  }

  &:focus {
    transform:scale(0.9);
    transform:rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
`

export default FAB
