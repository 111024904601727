export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    addRegulation: (regulation) => {
      dispatch(addRegulation(regulation))
    },
    setRegulations: (regulations) => {
      dispatch(setRegulations(regulations))
    },
    setNewRegulation: (id, title, content) => {
      dispatch(setNewRegulation(id, title, content))
    },
    resetNewRegulation: () => {
      dispatch(resetNewRegulation())
    },
    downloadRegulations: () => {
      dispatch(downloadRegulations())
    },
    downloadRegulation: (id) => {
      dispatch(downloadRegulation(id))
    },
    postRegulation: () => {
      dispatch(postRegulation())
    },
    putRegulation: () => {
      dispatch(putRegulation())
    }
  }
}

export function updateLoadingState (isLoading) {
  return { type: 'REGULATIONS.SET_LOADING', isLoading }
}

export function addRegulation (regulation) {
  return {
    type: 'REGULATIONS.ADD_REGULATION',
    regulation
  }
}

export function setRegulations (regulations) {
  return {
    type: 'REGULATIONS.SET_REGULATIONS',
    regulations
  }
}

export function setNewRegulation (id, title, content) {
  const newRegulation = { id, title, content }
  return {
    type: 'REGULATIONS.SET_NEW_REGULATION',
    newRegulation
  }
}

export function resetNewRegulation () {
  const newRegulation = {
    id: 'new',
    title: '',
    content: ''
  }
  return {
    type: 'REGULATIONS.SET_NEW_REGULATION',
    newRegulation
  }
}

export function downloadRegulations () {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getRegulations()
      .then((response) => {
        if (!response.error) dispatch(setRegulations(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadRegulation (id) {
  return (dispatch, getState, api) => {
    const { regulations } = getState()
    dispatch(updateLoadingState(true))
    return api.getRegulationById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasRegulationFound = false
        const regulationsList = regulations.regulations
        // replace existing regulation
        for (let i = 0; i < regulationsList.length; i++) {
          if (regulationsList[i]._id === id) {
            regulationsList[i] = response.data
            wasRegulationFound = true
          }
        }

        // or add new if not existent
        if (!wasRegulationFound) regulationsList.push(response.data)

        dispatch(setRegulations(regulationsList))
        dispatch(updateLoadingState(false))
      })
  }
}

export function postRegulation () {
  return (dispatch, getState, api) => {
    const { newRegulation } = getState().regulations
    dispatch(updateLoadingState(true))
    return api.postRegulation(newRegulation)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download regulations since user will be
        // pushed from editor to regulations list.
        // Also downloadRegulations will handle
        // updateLoadingState
        dispatch(downloadRegulations())
      })
  }
}

export function putRegulation () {
  return (dispatch, getState, api) => {
    const { newRegulation } = getState().regulations
    dispatch(updateLoadingState(true))
    return api.putRegulation(newRegulation.id, newRegulation)
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        // Download regulations since user will be
        // pushed from editor to regulations list.
        // Also downloadRegulations will handle
        // updateLoadingState
        dispatch(downloadRegulations())
      })
  }
}
