import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Panel, Fade } from 'react-bootstrap'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'
import '../../../../styles/react-draft-wysiwyg-custom.css'
import { cloneDeep } from 'lodash'
import getActions from '../../actions/regulationsActions'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import { MdSave as SaveIcon } from 'react-icons/md'
import { getRegulationById } from '../../../../api'

class CreateRegulation extends Component {
  constructor (props) {
    super(props)
    this.state = { editorState: EditorState.createEmpty() }
  }

  componentDidMount () {
    const isThisNewRegulation = !this.props.match.params.id
    // reset regulation only if previous id is not equal to 'new'
    // If it is equal, that means a clone option is being used so no reset is required
    if (isThisNewRegulation) {
      if (this.props.newRegulation.id !== 'cloned') this.props.resetNewRegulation()
      // apply the content that was cloned
      this.setTextEditorContent(this.props.newRegulation.content)
    } else {
      const { id, revision } = this.props.match.params
      getRegulationById(id)
        .then((response) => {
          const { _id, title, content } = this.findRequestedRegulationRevision(response.data, parseInt(revision))
          // set requested revision as new regulations
          this.props.setNewRegulation(_id, title, content)
          this.setTextEditorContent(content)
        })
    }
  }

  setTextEditorContent = (markdownContent) => {
    this.setState({
      editorState:
        EditorState.createWithContent(
          convertFromRaw(
            markdownToDraft(markdownContent)
          )
        )
    })
  }

  findRequestedRegulationRevision = (regulation, requestedRevision) => {
    // find and clone the object to make sure that this component will not mutate a in-store variable!
    const requestedRegulationRevision = cloneDeep(
      regulation.revisions
        .filter((revision) => revision.version === requestedRevision)[0]
    )

    return {
      ...requestedRegulationRevision,
      _id: regulation._id,
      title: regulation.title
    }
  }

  handleTitleChange = (event) => {
    const { id, content } = this.props.newRegulation
    this.props.setNewRegulation(id, event.target.value, content)
  }

  onEditorChanged = (editorState) => {
    this.setState({ editorState })
    this.onContentChanged(
      draftToMarkdown(
        convertToRaw(
          editorState.getCurrentContent()
        )
      )
    )
  }

  onContentChanged = (content) => {
    const { id, title } = this.props.newRegulation
    this.props.setNewRegulation(id, title, content)
  }

  saveRegulation = () => {
    const { id } = this.props.newRegulation
    if (id === 'new' || id === 'cloned') {
      this.addNewRegulation()
    } else {
      this.addNewRevisionToRegulation()
    }
    this.props.history.push('/dashboard/regulations')
  }

  addNewRegulation = () => {
    this.props.postRegulation()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewRegulation()
  }

  addNewRevisionToRegulation = () => {
    this.props.putRegulation()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewRegulation()
  }

  getNewestRegulationRevision (regulation) {
    regulation.revisions.sort((a, b) => a.version - b.version)
    return regulation.revisions[regulation.revisions.length - 1].version
  }

  render () {
    const { title } = this.props.newRegulation
    const headerActionDescription = this.props.match.params.id
      ? 'Edit' : 'Create'

    return (
      <Grid>
        <h1>{headerActionDescription} regulation</h1>
        <Row>
          <Col md={6}>
            <FieldGroup
              id='regulation-title'
              label='Regulation title'
              type='text'
              help='Eg. Škoda Octavia (2017) - regulations and safety tips'
              value={title}
              onChange={this.handleTitleChange} />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Panel>
              <Panel.Body>
                <Editor
                  editorState={this.state.editorState}
                  placeholder='Regulation content goes here'
                  onEditorStateChange={this.onEditorChanged}
                  toolbar={{
                    options: ['blockType', 'inline', 'list', 'emoji', 'link', 'history'],
                    inline: {
                      inDropdown: false,
                      options: ['bold', 'italic']
                    },
                    list: {
                      inDropdown: false,
                      options: ['unordered', 'ordered']
                    },
                    link: { inDropdown: false },
                    history: { inDropdown: false },
                    blockType: {
                      options: ['Normal', 'H1', 'H3', 'Blockquote']
                    }
                  }}
                />
              </Panel.Body>
            </Panel>
          </Col>
        </Row>

        <Fade in={title.length > 0 && this.state.editorState.getCurrentContent().hasText()}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.saveRegulation}
            title='Save regulation'>
            <SaveIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
          </FAB>
        </Fade>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ regulations }) {
  return regulations
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CreateRegulation)
