import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, Row, Col, Table, Panel, Label, Button, Alert, ControlLabel } from 'react-bootstrap'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import { MdModeEdit as EditIcon, MdPerson as PersonIcon } from 'react-icons/md'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import ReactMarkdown from 'react-markdown'
import MileageRecordsList from './MileageRecordsList'
import OilChangesHistoriesList from './OilChangesHistoriesList'
import InspectionsHistoryList from './InspectionsHistoryList'
import FumigationHistoryList from './FumigationHistoryList'
import InsuranceHistoryList from './InsuranceHistoryList'
import TimingGearChangesList from './TimingGearChangesHistoryList'
import LpgApprovalHistoryList from './LpgApprovalHistoryList'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import getCarManufacturersActions from '../../../CarManufacturers/actions/carManufacturerActions'
import getFuelTypesActions from '../../../FuelTypes/actions/fuelTypeActions'
import getCarModelsActions from '../../../CarModels/actions/carModelsActions'
import getUsersActions from '../../../Users/actions/actions'
import getCarsActions from '../../actions/carsActions'
import getTransfersActions from '../../../Transfers/actions/actions'
import getRegulationsActions from '../../../Regulations/actions/regulationsActions'
import api from '../../../../api'
import DocumentScansList from './DocumentScansList'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    pageHeader: '{0} details',
    archived: 'archived',
    details: 'Details',
    manufacturer: 'Manufacturer',
    model: 'Model',
    licensePlate: 'License plate',
    color: 'Color',
    fuelType: 'Fuel type',
    tires: 'Tires',
    regulation: 'Regulation',
    productionDate: 'Production date',
    vin: 'VIN',
    none: 'none',
    engine: 'Engine',
    engineDescription: '{liters} liters, {hp} horsepower',
    lastUpdate: 'Last update',
    lastTransfer: 'Last transfer',
    date: 'Date',
    outsideUser: 'Outside user',
    previousOwner: 'Previous owner',
    currentOwner: 'Current owner',
    browseTransferDetailsButton: 'Browse transfer details',
    clearKnownIssuesButton: 'Clear known issues',
    readme: 'Readme',
    adminNotes: 'Admin notes',
    updateCarState: 'Update car state',
    mileage: 'Current mileage:',
    nextOilChangeMileage: 'The engine oil was changed at (km)/on (date):',
    nextTimingGearChange: 'The timing gear was replaced at (km)/on (date):',
    nextInspection: 'The inspection took place at the vehicle inspection station on:',
    nextFumigation: 'The air conditioning was cleaned/fungal removed on:',
    updateCarStateButton: 'Update car state',
    saved: 'Saved',
    insuranceDocumentScans: 'Insurance document scans',
    proofOfRegistrationScans: 'Proof of registration scans',
    mileageRecords: 'Mileage records',
    oilChanges: 'Oil changes',
    inspections: 'Inspections',
    fumigation: 'Fumigation',
    insurance: 'Insurance',
    timingGearChanges: 'Timing gear changes',
    lpgApproval: 'LPG approval',
    editCarButton: 'Edit car',
    changeOwnerButton: 'Change owner',
  },
  pl: {
    pageHeader: 'Szczegóły {0}',
    archived: 'zarchiwizowany',
    details: 'Szczegóły',
    manufacturer: 'Producent',
    model: 'Model',
    licensePlate: 'Numer rejestracyjny',
    color: 'Kolor',
    fuelType: 'Typ paliwa',
    tires: 'Opony',
    regulation: 'Regulamin',
    productionDate: 'Data produkcji',
    vin: 'VIN',
    none: 'brak',
    engine: 'Silnik',
    engineDescription: '{liters} litrów, {hp} koni mechanicznych',
    lastUpdate: 'Ostatnia aktualizacja',
    lastTransfer: 'Ostatni transfer',
    date: 'Data',
    outsideUser: 'Użytkownik zewnętrzny',
    previousOwner: 'Poprzedni użytkownik',
    currentOwner: 'Aktualny użytkownik',
    browseTransferDetailsButton: 'Historia transferów',
    clearKnownIssuesButton: 'Usuń znane usterki',
    readme: 'Informacje',
    adminNotes: 'Notatnik administratora',
    updateCarState: 'Zaktualizuj stan pojazdu',
    mileage: 'Aktualny przebieg:',
    nextOilChangeMileage: 'Nastąpiła wymiana oleju silnikowego przy stanie (km)/w dniu (data):',
    nextTimingGearChange: 'Nastąpiła wymiana rozrządu przy stanie (km)/w dniu (data):',
    nextInspection: 'Nastąpił przegląd w stacji kontroli pojazdów w dniu:',
    nextFumigation: 'Nastąpiło czyszczenie klimatyzacji/odgrzybianie w dniu:',
    updateCarStateButton: 'Zaktualizuj stan pojazdu',
    saved: 'Zapisano',
    insuranceDocumentScans: 'Skany ubezpieczenia pojazdu',
    proofOfRegistrationScans: 'Skany dowodu rejestracyjnego',
    mileageRecords: 'Historia przebiegów',
    oilChanges: 'Zmiany oleju',
    inspections: 'Przeglądy',
    fumigation: 'Odgrzybianie',
    insurance: 'Historia ubezpieczeń',
    timingGearChanges: 'Wymiany rozrządu',
    lpgApproval: 'Homologacje LPG',
    editCarButton: 'Edytuj pojazd',
    changeOwnerButton: 'Zmień użytkownika pojazdu'
  },
  de: {
    pageHeader: 'Details {0}',
    archived: 'Archiviert',
    details: 'Details',
    manufacturer: 'Hersteller',
    model: 'Modell',
    licensePlate: 'Kennzeichen',
    color: 'Farbe',
    fuelType: 'Kraftstoffart',
    tires: 'Reifen',
    regulation: 'Regelung',
    productionDate: 'Herstellungsdatum',
    vin: 'Fahrgestellnummer (VIN)',
    none: 'Keine',
    engine: 'Motor',
    engineDescription: '{liters} Liter, {hp} PS',
    lastUpdate: 'Letztes Update',
    lastTransfer: 'Letzte Überweisung',
    date: 'Datum',
    outsideUser: 'Externer Benutzer',
    previousOwner: 'Vorheriger Besitzer',
    currentOwner: 'Aktueller Besitzer',
    browseTransferDetailsButton: 'Übertragungshistorie durchsuchen',
    clearKnownIssuesButton: 'Bekannte Probleme beheben',
    readme: 'Information',
    adminNotes: 'Notizen des Administrators',
    updateCarState: 'Fahrzeugstatus aktualisieren',
    mileage: 'Aktueller Kilometerstand:',
    nextOilChangeMileage: 'Das Motoröl wurde bei (km)/am (Datum) gewechselt:',
    nextTimingGearChange: 'Der Zahnriemen wurde um (km)/am (Datum) ausgetauscht:',
    nextInspection: 'Die Prüfung fand in der Kfz-Prüfstelle statt am:',
    nextFumigation: 'Die Klimaanlage wurde gereinigt/Pilz entfernt am:',
    updateCarStateButton: 'Fahrzeugstatus aktualisieren',
    saved: 'Gespeichert',
    insuranceDocumentScans: 'KFZ-Versicherungsdokument-Scans',
    proofOfRegistrationScans: 'Scans der Zulassungsurkunde',
    mileageRecords: 'Kilometerstandprotokolle',
    oilChanges: 'Ölwechsel',
    inspections: 'Inspektionen',
    fumigation: 'Desinfektion',
    insurance: 'Versicherung',
    timingGearChanges: 'Wechsel der Zahnriemen',
    lpgApproval: 'LPG-Zulassung',
    editCarButton: 'Auto bearbeiten',
    changeOwnerButton: 'Besitzer ändern',
  },
  fr: {
    pageHeader: 'Détails {0}',
    archived: 'Archivé',
    details: 'Détails',
    manufacturer: 'Fabricant',
    model: 'Modèle',
    licensePlate: 'Plaque d’immatriculation',
    color: 'Couleur',
    fuelType: 'Type de carburant',
    tires: 'Pneus',
    regulation: 'Règlement',
    productionDate: 'Date de production',
    vin: 'VIN',
    none: 'Aucun',
    engine: 'Moteur',
    engineDescription: '{liters} litres, {hp} chevaux',
    lastUpdate: 'Dernière mise à jour',
    lastTransfer: 'Dernier transfert',
    date: 'Date',
    outsideUser: 'Utilisateur externe',
    previousOwner: 'Ancien propriétaire',
    currentOwner: 'Propriétaire actuel',
    browseTransferDetailsButton: 'Historique des transferts',
    clearKnownIssuesButton: 'Supprimer les problèmes connus',
    readme: 'Informations',
    adminNotes: 'Notes d’administration',
    updateCarState: 'Mettre à jour l’état du véhicule',
    mileage: 'Kilométrage actuel :',
    nextOilChangeMileage: 'La vidange de l’huile moteur a été effectuée à (km)/le (date) :',
    nextTimingGearChange: 'La courroie de distribution a été remplacée à (km)/le (date) :',
    nextInspection: 'Le contrôle a eu lieu au poste de contrôle technique le :',
    nextFumigation: 'La climatisation a été nettoyée/défoncée sur :',
    updateCarStateButton: 'Mettre à jour l’état du véhicule',
    saved: 'Enregistré',
    insuranceDocumentScans: 'Scans de documents d’assurance',
    proofOfRegistrationScans: 'Scans de la preuve d’immatriculation',
    mileageRecords: 'Historique des kilométrages',
    oilChanges: 'Changements d’huile',
    inspections: 'Inspections',
    fumigation: 'Fumigation',
    insurance: 'Assurance',
    timingGearChanges: 'Changements de synchronisation',
    lpgApproval: 'Homologation GPL',
    editCarButton: 'Modifier le véhicule',
    changeOwnerButton: 'Changer de propriétaire',
  }
})

class CarDetails extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoading: true, transfersHistoryId: '' }
  }

  componentWillMount() {
    this.setLoadingStateForAllDataSources(true)

    // download a car and other dependent data
    const { id } = this.props.match.params
    api.getCarById(id)
      .then((response) => {
        const car = response.data

        Promise
          .all([
            api.getFuelTypeById(car.fuelTypeId),
            api.getCarManufacturerById(car.manufacturerId),
            api.getCarModelById(car.carModelId),
            api.getRegulationById(car.regulationId),
            api.getLatestTransferById(car.transfersHistoryId)
          ])
          .then(
            (
              responses
            ) => {
              const transferDetails = responses[4].data.transferDetails
              const userPromises = []
              if (transferDetails.previousUserId) {
                userPromises.push(api.getUserById(transferDetails.previousUserId))
              }

              if (transferDetails.nextUserId) {
                userPromises.push(api.getUserById(transferDetails.nextUserId))
              }

              this.setState({ transfersHistoryId: responses[4].data._id })

              Promise.all(userPromises)
                .then((usersResponses) => {
                  this.props.setCars([car])
                  this.props.setFuelTypes([responses[0].data])
                  this.props.setCarManufacturers([responses[1].data])
                  this.props.setCarModels([responses[2].data])
                  this.props.setRegulations([responses[3].data])
                  this.props.setTransfers([responses[4].data])
                  this.props.setUsers(usersResponses.map((response) => response.data))

                  this.setLoadingStateForAllDataSources(false)
                })
            })
      })
  }

  setLoadingStateForAllDataSources = (isLoading) => {
    if (isLoading) { this.setState({ isLoading }) }

    this.props.setCarsLoading(isLoading)
    this.props.setFuelTypesLoading(isLoading)
    this.props.setCarManufacturersLoading(isLoading)
    this.props.setCarModelsLoading(isLoading)
    this.props.setRegulationsLoading(isLoading)
    this.props.setTransfersLoading(isLoading)
    this.props.setUsersLoading(isLoading)

    if (!isLoading) { this.setState({ isLoading }) }
  }

  findCarById = (id) => {
    return this.props.cars.filter((car) => car._id === id)[0]
  }

  findCarModelById = (id) => {
    return this.props.carModels.filter((carModel) => carModel._id === id)[0]
  }

  findManufacturerById = (id) => {
    return this.props.carManufacturers.filter((manufacturer) => manufacturer._id === id)[0]
  }

  findRegulationById = (id) => {
    return this.props.regulations.filter((regulation) => regulation._id === id)[0]
  }

  findFuelTypeById = (id) => {
    return this.props.fuelTypes.filter((fuelType) => fuelType._id === id)[0]
  }

  findTransferHistoryById = (id) => {
    return this.props.transfers.filter((transfer) => transfer._id === id)[0]
  }

  findUserById = (id) => {
    return this.props.users.filter((user) => user._id === id)[0]
  }

  handleMileageChange = (event) => {
    this.props.setCarState({
      ...this.props.carState,
      mileage: parseInt(event.target.value)
    })
  }

  handleOilChangeMileageChange = (event) => {
    this.props.setCarState({
      ...this.props.carState,
      oilChange: {
        ...this.props.oilChange,
        mileage: parseInt(event.target.value)
      }
    })
  }

  handleOilChangeDateChange = (date) => {
    this.props.setCarState({
      ...this.props.carState,
      oilChange: {
        ...this.props.oilChange,
        date
      }
    })
  }

  handleTimingGearChangeMileageChange = (event) => {
    this.props.setCarState({
      ...this.props.carState,
      timingGearChange: {
        ...this.props.timingGear,
        mileage: parseInt(event.target.value)
      }
    })
  }

  handleTimingGearChangeDateChange = (date) => {
    this.props.setCarState({
      ...this.props.carState,
      timingGearChange: {
        ...this.props.timingGear,
        date
      }
    })
  }

  handleInspectionDateChange = (date) => {
    this.props.setCarState({
      ...this.props.carState,
      inspection: {
        ...this.props.inspection,
        date
      }
    })
  }

  handleFumigationDateChange = (date) => {
    this.props.setCarState({
      ...this.props.carState,
      fumigation: {
        ...this.props.fumigation,
        date
      }
    })
  }

  handleCarStateUpdateClick = () => {
    this.props.sendCarStateUpdate(this.props.match.params.id)
  }

  pushToEdit = () => {
    this.props.history.push(`/dashboard/car/${this.props.match.params.id}/edit`)
  }

  pushToOwnerChange = () => {
    this.props.history.push(`/dashboard/car/${this.props.match.params.id}/ownerChange`)
  }

  pushToTransfersBrowser = () => {
    this.props.history.push(`/dashboard/transfer/${this.state.transfersHistoryId}/0/carModelSide/1`)
  }

  clearIssuesAndPushToTransfersBrowser = async () => {
    if (window.confirm('You are going to delete all known issues from this car. After this action you\'re probably need to take new issue photos.\nAre you sure you want delete ALL of known issues for this car?')) {
      await api.putClearIssues(this.state.transfersHistoryId)
      this.pushToTransfersBrowser()
    }
  }

  render() {
    const { forAdmin, carState } = this.props
    const car = this.findCarById(this.props.match.params.id)
    if (!this.state.isLoading && !this.props.isLoading && car) {
      const manufacturer = this.findManufacturerById(car.manufacturerId)
      const fuelType = this.findFuelTypeById(car.fuelTypeId)
      const regulation = this.findRegulationById(car.regulationId)
      const transfer = this.findTransferHistoryById(car.transfersHistoryId)

      let previousUser
      if (transfer.transferDetails.previousUserId) {
        previousUser = this.findUserById(transfer.transferDetails.previousUserId)
        // if else, it means that a previousUserDetails object is present
      } else {
        previousUser = {
          username: transfer.transferDetails.previousUserDetails.name,
          isOutsideUser: true
        }
      }

      let nextUser
      if (transfer.transferDetails.nextUserId) {
        nextUser = this.findUserById(transfer.transferDetails.nextUserId)
        // if else, it means that a nextUserDetails object is present
      } else {
        nextUser = {
          username: transfer.transferDetails.nextUserDetails.name,
          isOutsideUser: true
        }
      }

      const lastUpdate = new Date(car.lastUpdateDate)
      const productionDate = new Date(car.productionDate)

      const carName = <>{manufacturer.name} {car.model} {car.common && car.common.nick ? <i>aka {car.common.nick}</i> : ''}</>
      return (
        <Grid>
          <Row>
            <h1>{strings.formatString(strings.pageHeader, carName)}</h1>
            {car.archived && <h4 style={{ color: 'red' }}>{strings.archived}</h4>}
          </Row>
          <Row>
            <Col md={4}>
              <Table striped hover bordered>
                <thead>
                  <tr>
                    <th colSpan={2}>{strings.details}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{strings.manufacturer}</td>
                    <td>{manufacturer.name}</td>
                  </tr>
                  <tr>
                    <td>{strings.model}</td>
                    <td>{car.model}</td>
                  </tr>
                  <tr>
                    <td>{strings.licensePlate}</td>
                    <td>{car.licensePlate}</td>
                  </tr>
                  <tr>
                    <td>{strings.color}</td>
                    <td>{car.color}</td>
                  </tr>
                  <tr>
                    <td>{strings.fuelType}</td>
                    <td>{fuelType.name}</td>
                  </tr>
                  <tr>
                    <td>{strings.tires}</td>
                    <td>{car.tires}</td>
                  </tr>
                  <tr>
                    <td>{strings.regulation}</td>
                    <td><Link to={`/dashboard/regulation/${regulation._id}/latest`}>{regulation.title}</Link></td>
                  </tr>
                  <tr>
                    <td>{strings.productionDate}</td>
                    <td>{productionDate.toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <td>{strings.vin}</td>
                    <td>{car.vin && car.vin.length ? car.vin : strings.none}</td>
                  </tr>
                  <tr>
                    <td>{strings.engine}</td>
                    <td>
                      {strings.formatString(
                        strings.engineDescription, {
                        liters: car.engine.capacity,
                        hp: car.engine.power
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>{strings.lastUpdate}</td>
                    <td>{lastUpdate.toLocaleDateString()} {lastUpdate.toLocaleTimeString()}</td>
                  </tr>
                </tbody>
              </Table>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    {strings.lastTransfer}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {strings.date}: {new Date(transfer.transferDetails.date).toLocaleString()}
                  <br />
                  {strings.previousOwner}: {previousUser.username} {' '}
                  {previousUser.isOutsideUser ? <Label>{strings.outsideUser}</Label> : null}
                  <br />
                  {strings.currentOwner}: {nextUser.username} {' '}
                  {nextUser.isOutsideUser ? <Label>{strings.outsideUser}</Label> : null}
                  {forAdmin && (<>
                    <br />
                    <br />
                    <Button onClick={this.pushToTransfersBrowser}>{strings.browseTransferDetailsButton}</Button> {'  '}
                    <Button bsStyle='warning' onClick={this.clearIssuesAndPushToTransfersBrowser}>{strings.clearKnownIssuesButton}</Button>
                  </>)}
                </Panel.Body>
              </Panel>
            </Col>
            <Col md={6}>
              <Panel>
                <Panel.Heading>
                  <Panel.Title>
                    {strings.readme}
                  </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <ReactMarkdown source={car.readme} />
                </Panel.Body>
              </Panel>
            </Col>
            {forAdmin && (
              <Col md={6}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      {strings.adminNotes}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <ReactMarkdown source={car.adminReadme} />
                  </Panel.Body>
                </Panel>
              </Col>
            )}
            {!forAdmin && (
              <Col md={6}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      {strings.updateCarState}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <FieldGroup
                      label={strings.mileage}
                      placeholder='eg. 120000'
                      type='number'
                      onChange={this.handleMileageChange}
                      value={carState.mileage}
                    />
                    <FieldGroup
                      label={strings.nextOilChangeMileage}
                      placeholder='eg. 130000'
                      type='number'
                      onChange={this.handleOilChangeMileageChange}
                      value={carState.oilChange.mileage}
                    />
                    <DayPickerInput
                      type='number'
                      onKeyUp={(e) => e.preventDefault()}
                      onDayChange={this.handleOilChangeDateChange}
                      value={carState.oilChange.date && new Date(carState.oilChange.date)}
                      dateFormat='d MMMM yyyy'
                    />
                    <br />
                    <br />
                    <FieldGroup
                      label={strings.nextTimingGearChange}
                      placeholder='eg. 130000'
                      type='number'
                      onChange={this.handleTimingGearChangeMileageChange}
                      value={carState.timingGearChange.mileage}
                    />
                    {/* <DayPickerInput
                      type='number'
                      onKeyUp={(e) => e.preventDefault()}
                      onDayChange={this.handleTimingGearChangeDateChange}
                      value={carState.timingGearChange.date && new Date(carState.timingGearChange.date)}
                      dateFormat='d MMMM yyyy'
                    />
                    <br />
                    <br /> */}
                    <ControlLabel>{strings.nextInspection}</ControlLabel>
                    <DayPickerInput
                      type='number'
                      onKeyUp={(e) => e.preventDefault()}
                      onDayChange={this.handleInspectionDateChange}
                      value={carState.inspection.date && new Date(carState.inspection.date)}
                      dateFormat='d MMMM yyyy'
                    />
                    <br />
                    <br />
                    <ControlLabel>{strings.nextFumigation}</ControlLabel>
                    <DayPickerInput
                      type='number'
                      onKeyUp={(e) => e.preventDefault()}
                      onDayChange={this.handleFumigationDateChange}
                      value={carState.fumigation.date && new Date(carState.fumigation.date)}
                      dateFormat='d MMMM yyyy'
                    />
                    {(carState.mileage ||
                      carState.oilChange.mileage || carState.oilChange.date ||
                      carState.timingGearChange.mileage || carState.timingGearChange.date ||
                      carState.inspection.date ||
                      carState.fumigation.date) && (<>
                        <br />
                        <br />
                        <Button onClick={this.handleCarStateUpdateClick}>
                          {strings.updateCarStateButton}
                        </Button>
                      </>)
                    }
                    {
                      carState.isSaved && (<>
                        <br />
                        <br />
                        <Alert bsStyle='info'>{strings.saved}</Alert>
                      </>)
                    }
                    {
                      carState.error && (<>
                        <br />
                        <br />
                        <Alert bsStyle='warning'>{carState.error}</Alert>
                      </>)
                    }
                  </Panel.Body>
                </Panel>
              </Col>
            )}
          </Row>
          <Row>
            {car.insuranceDocumentScans.length > 0 &&
              <Col md={5}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      {strings.insuranceDocumentScans}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <DocumentScansList scans={car.insuranceDocumentScans} />
                  </Panel.Body>
                </Panel>
              </Col>
            }
            {car.proofOfRegistrationScans.length > 0 &&
              <Col md={5}>
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      {strings.proofOfRegistrationScans}
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <DocumentScansList scans={car.proofOfRegistrationScans} />
                  </Panel.Body>
                </Panel>
              </Col>
            }
          </Row>
          <Row>
            <Col md={5}>
              <h5>{strings.mileageRecords}</h5>
              <MileageRecordsList list={[...car.mileageRecords].reverse()} />
            </Col>
            <Col md={5}>
              <h5>{strings.oilChanges}</h5>
              <OilChangesHistoriesList list={[...car.oilChangesHistory].reverse()} />
            </Col>
            <Col md={5}>
              <h5>{strings.inspections}</h5>
              <InspectionsHistoryList list={[...car.inspectionsHistory].reverse()} />
            </Col>
            <Col md={5}>
              <h5>{strings.fumigation}</h5>
              <FumigationHistoryList list={[...car.fumigationHistory].reverse()} />
            </Col>
            <Col md={5}>
              <h5>{strings.insurance}</h5>
              <InsuranceHistoryList list={[...car.insuranceHistory].reverse()} />
            </Col>
            <Col md={5}>
              <h5>{strings.timingGearChanges}</h5>
              <TimingGearChangesList list={[...car.timingGearChangesHistory].reverse()} />
            </Col>
            {
              fuelType.isLPG ? (
                <Col md={5}>
                  <h5>{strings.lpgApproval}</h5>
                  <LpgApprovalHistoryList list={[...car.lpgApprovalHistory].reverse()} />
                </Col>
              ) : null
            }

          </Row>
          <div style={{ height: 48 }} />

          {forAdmin && (<>
            <FAB
              style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
              onClick={this.pushToEdit}
              title={strings.editCarButton}>
              <EditIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
            </FAB>
            <FAB
              style={{ 'position': 'fixed', right: '96px', bottom: '30px' }}
              mini
              onClick={this.pushToOwnerChange}
              title={strings.changeOwnerButton}>
              <PersonIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-10px' }} />
            </FAB>
          </>)}
        </Grid>
      )
    } else {
      return (
        <Grid>
          <Row>
            <h1>{strings.formatString(strings.carDetails, '')} {' '}
              <LoadingIcon isLoading={this.props.isLoading} />
            </h1>
          </Row>
        </Grid>
      )
    }
  }
}

function mapStoreStateToProps({ loginPage, cars, carModels, carManufacturers, fuelTypes, regulations, users, transfers }) {
  const forAdmin = (loginPage.auth && loginPage.auth.role) === 'admin'
  return {
    forAdmin,
    ...cars,
    ...carModels,
    ...carManufacturers,
    ...fuelTypes,
    ...regulations,
    ...users,
    ...transfers,
    isLoading: cars.isLoading || carModels.isLoading ||
      carManufacturers.isLoading || fuelTypes.isLoading ||
      regulations.isLoading || users.isLoading ||
      transfers.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...getCarsActions(dispatch),
    ...getCarModelsActions(dispatch),
    ...getCarManufacturersActions(dispatch),
    ...getFuelTypesActions(dispatch),
    ...getRegulationsActions(dispatch),
    ...getUsersActions(dispatch),
    ...getTransfersActions(dispatch),
    setCarsLoading: (isLoading) => getCarsActions(dispatch).updateLoadingState(isLoading),
    setCarModelsLoading: (isLoading) => getCarModelsActions(dispatch).updateLoadingState(isLoading),
    setCarManufacturersLoading: (isLoading) => getCarManufacturersActions(dispatch).updateLoadingState(isLoading),
    setFuelTypesLoading: (isLoading) => getFuelTypesActions(dispatch).updateLoadingState(isLoading),
    setRegulationsLoading: (isLoading) => getRegulationsActions(dispatch).updateLoadingState(isLoading),
    setUsersLoading: (isLoading) => getUsersActions(dispatch).updateLoadingState(isLoading),
    setTransfersLoading: (isLoading) => getTransfersActions(dispatch).updateLoadingState(isLoading)
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CarDetails)
