import React, { Component } from 'react'
import { Grid, Row, Col, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import CarsList from './CarsList'
import ArchivedCarsList from './ArchivedCarsList'
import LoadingIcon from '../../../components/common/LoadingIcon'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'
import getFuelTypesActions from '../../FuelTypes/actions/fuelTypeActions'
import getCarManufacturersActions from '../../CarManufacturers/actions/carManufacturerActions'
import getCarModelsActions from '../../CarModels/actions/carModelsActions'
import getActions from '../actions/carsActions'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    pageHeader: 'Cars',
    addCar: 'Add new car',
    noCarsFoundMessage: 'No cars found. Please make sure that you have already created at least one of each:',
    questionnaires: 'questionnaires',
    regulations: 'regulations',
    carModels: 'car models',
  },
  pl: {
    pageHeader: 'Pojazdy',
    addCar: 'Dodaj nowy pojazd',
    noCarsFoundMessage: 'Brak pojazdów. Sprawdź, czy już utworzono:',
    questionnaires: 'pytania',
    regulations: 'regulaminy',
    carModels: 'modele',
  },
  de: {
    pageHeader: 'Autos',
    addCar: 'Neues Auto hinzufügen',
    noCarsFoundMessage: 'Keine Autos gefunden. Stellen Sie sicher, dass Sie bereits mindestens eines von jedem erstellt haben:',
    questionnaires: 'Fragebögen',
    regulations: 'Vorschriften',
    carModels: 'Automodelle',
  },
  fr: {
    pageHeader: 'Voitures',
    addCar: 'Ajouter une nouvelle voiture',
    noCarsFoundMessage: "Aucune voiture trouvée. Assurez-vous d'avoir déjà créé au moins un exemplaire de chaque élément: ",
    questionnaires: 'questionnaires',
    regulations: 'réglementations',
    carModels: 'modèles de voiture',
  }
})

class Cars extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showArchivedCars: false
    }
  }

  componentDidMount() {
    this.props.downloadFuelTypes()
    this.props.downloadCarManufacturers()
    this.props.downloadCarModels()
    this.props.downloadCars()
  }

  addCar = () => {
    this.props.history.push('/dashboard/car/add')
  }

  handleCarClick = (_id) => {
    // it's necessary for car details component -
    // when it's set to false, a first tick in new
    // components causes undefined results when
    // finding elements by id since it was not downloaded yet!
    this.props.updateLoadingState(true)

    this.props.history.push(`/dashboard/car/${_id}`)
  }

  render() {
    const { cars, carModels, carManufacturers, fuelTypes, isLoading, isAdmin } = this.props

    let componentToRender
    if (!cars || cars.length === 0) {
      componentToRender = (
        <Alert bsStyle='warning'>
          {strings.noCarsFoundMessage}
          <ul>
            <li><Link to='/dashboard/questionnaires'>{strings.questionnaires}</Link></li>
            <li><Link to='/dashboard/regulations'>{strings.regulations}</Link></li>
            <li><Link to='/dashboard/carModels'>{strings.carModels}</Link></li>
          </ul>
        </Alert>
      )
    } else {
      const archivedCars = [...cars].filter((car) => car.archived)
      componentToRender = (
        <>
          <CarsList
            cars={[...cars].filter(car => !car.archived)}
            carModels={carModels}
            carManufacturers={carManufacturers}
            fuelTypes={fuelTypes}
            onCarClicked={this.handleCarClick}
          />
          {
            archivedCars.length > 0 &&
            <ArchivedCarsList
              cars={[...cars].filter(car => car.archived)}
              carModels={carModels}
              carManufacturers={carManufacturers}
              fuelTypes={fuelTypes}
              onCarClicked={this.handleCarClick}
            />
          }
        </>
      )
    }

    return (
      <Grid>
        <Row>
          <h1>
            {strings.pageHeader} {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>
        <Row>
          <Col md={9}>
            {isLoading ? null : componentToRender}
          </Col>
        </Row>
        {isAdmin &&
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.addCar}
            title={strings.addCar}>
            <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
          </FAB>
        }
      </Grid>
    )
  }
}

function mapStoreStateToProps({ loginPage, cars, carModels, carManufacturers, fuelTypes }) {
  const isAdmin = (loginPage.auth && loginPage.auth.role) === 'admin'
  const accessibleCars = isAdmin ? cars.cars : loginPage.ownedCars

  return {
    cars: accessibleCars,
    ...carModels,
    ...carManufacturers,
    ...fuelTypes,
    isAdmin,
    isLoading: cars.isLoading || carModels.isLoading || carManufacturers.isLoading || fuelTypes.isLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...getActions(dispatch),
    ...getFuelTypesActions(dispatch),
    ...getCarManufacturersActions(dispatch),
    ...getCarModelsActions(dispatch)
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(Cars)
