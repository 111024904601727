import React from 'react'
import Table from './Table'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    date: 'Date',
    time: 'Time',
    mileage: 'Mileage (km)',
  },
  pl: {
    date: 'Data',
    time: 'Godzina',
    mileage: 'Przebieg (km)',
  },
  de: {
    date: 'Datum',
    time: 'Uhrzeit',
    mileage: 'Kilometerstand (km)',
  },
  fr: {
    date: 'Date',
    time: 'Heure',
    mileage: 'Kilométrage (km)',
  }
})

export default ({ list }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>{strings.date}</th>
          <th>{strings.time}</th>
          <th>{strings.mileage}</th>
        </tr>
      </thead>
      <tbody>
        {
          list ? list.map((element) => {
            return (
              <tr key={element.date}>
                <td>
                  {new Date(element.date).toLocaleDateString()}
                </td>
                <td>
                  {new Date(element.date).toLocaleTimeString()}
                </td>
                <td>
                  {
                    element.mileage
                  }
                </td>
              </tr>
            )
          }) : null
        }
      </tbody>
    </Table>
  )
}
