import React, { Component } from 'react'
import { ProgressBar } from 'react-bootstrap'
import styled from 'styled-components'
import httpClient from 'axios'

const FileInput = styled.input`
  margin: 8px auto;
`

class ImageUpload extends Component {
  constructor (props) {
    super(props)
    if (!props.uploadEndpoint) throw new Error('"uploadEndpoint" prop cannot be undefined in ImageUpload component')
    this.state = {
      inProgress: false,
      percentage: 0,
      error: null,
      finished: false
    }
  }

  resetState = () => {
    this.setState({
      inProgress: false,
      percentage: 0,
      error: null,
      finished: false
    })
  }

  handleUpload = async (event) => {
    this.resetState()
    this.setState({ inProgress: true })
    /* global FormData */
    const data = new FormData()
    data.append('file', event.target.files[0])

    const vm = this
    try {
      const response = await httpClient.post(
        this.props.uploadEndpoint,
        data,
        {
          withCredentials: true,
          onUploadProgress: (progressEvent) => {
            vm.setState({ percentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)) })
          }
        }
      )
      this.setState({ inProgress: false, finished: true })
      this.props.onUploadFinished(response.data.path.toString())
    } catch (exception) {
      console.error({ exception })
      this.setState({
        error: exception.response.data.message ? exception.response.data.message : exception.toString(),
        finished: true,
        inProgress: false
      })
    }
  }

  render () {
    return (
      <React.Fragment>
        <h4 style={{ textAlign: 'center' }}>Upload image</h4>
        <FileInput
          type='file'
          onChange={this.handleUpload}
          accept='image/*'
          style={{ visibility: this.state.inProgress ? 'hidden' : 'visible' }}
        />
        <ProgressBar
          style={{ visibility: this.state.inProgress || this.state.finished ? 'visible' : 'hidden' }}
          now={this.state.percentage}
          active={this.state.inProgress}
          striped
          bsStyle={this.state.error ? 'danger' : null} />

        {
          this.state.error ? this.state.error.toString() : null
        }
      </React.Fragment>
    )
  }
}

export default ImageUpload
