import React from 'react'
import o2d from 'date-from-object-id'
import { Row, Col, ListGroup, ListGroupItem, Badge, Label, Image } from 'react-bootstrap'
import withApiUrl from '../../../components/common/hoc/withApiUrl'

const CarModelList = ({ carModels, onCarModelClicked, apiUrl }) => {
  return (
    <ListGroup>
      {
        carModels.map((carModel) => {
          carModel.revisions.sort((a, b) => a.version - b.version)
          const newestRevision = carModel.revisions[carModel.revisions.length - 1]
          const photoUrl = newestRevision.elements.length > 0 ? `${newestRevision.elements[0].imagePath}?width=450` : null

          return (
            <ListGroupItem
              key={carModel._id}
              onClick={() => onCarModelClicked(carModel._id)}>
              <Row>
                <Col xs={12} sm={4} md={3}>
                  <Image src={`${apiUrl}${photoUrl}`} thumbnail />
                </Col>
                <Col xs={12} sm={8} md={9}>
                  <b style={{ fontSize: '16px' }}><Label>{carModel.carBodyType}</Label>  {carModel.carName}</b>
                  <p style={{ marginTop: '12px' }}>
                      Added {o2d(carModel._id).toLocaleDateString()}
                  </p>
                  <Badge>Newest revision: { newestRevision.version } </Badge>
                </Col>
              </Row>
            </ListGroupItem>
          )
        })
      }
    </ListGroup>
  )
}

export default withApiUrl(CarModelList)
