const blankTemplate = {
  date: {
    title: '',
    body: ''
  },
  mileage: {
    title: '',
    body: ''
  },
};

const alertTypeContents = {
  oilChange: { ...blankTemplate },
  timingGear: { ...blankTemplate },
  insurance: { ...blankTemplate },
  inspection: { ...blankTemplate },
  fumigation: { ...blankTemplate },
  lpgApproval: { ...blankTemplate },
}


const initState = {
  alertTemplates: {
    push: {
      warning: { ...alertTypeContents },
      error: { ...alertTypeContents },
    },
    email: {
      warning: { ...alertTypeContents },
      error: { ...alertTypeContents },
    }
  },
  isLoading: true
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'ALERT_TEMPLATES.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'ALERT_TEMPLATES.SET_ALERT_TEMPLATES':
      return {
        ...state,
        alertTemplates: action.templates
      }
    default: return state
  }
}

export default reducer
