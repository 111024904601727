import React, { Component } from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'
import FieldGroup from '../../../../components/common/FieldGroup'

class Questionnaire extends Component {
  mapAnswerTypeToInputType = (answerType) => {
    switch (answerType) {
      case 'string': return 'text'
      case 'number': return 'number'
      default: return 'unknown'
    }
  }

  render () {
    if (!this.props.questionnaire) return null
    const { questionnaire } = this.props

    return (
      <React.Fragment>
        <h5>Questionnaire </h5>
        <p>Please answer the questions below:</p>
        {
          questionnaire.map((question) => {
            if (question.answerType === 'boolean') {
              return (
                <FormGroup
                  key={`answer-${question.questionOrder.toString()}`}
                  controlId={`answer-${question.questionOrder.toString()}`}>
                  <ControlLabel>{ question.content }</ControlLabel>
                  <FormControl componentClass='select'
                    onChange={this.props.onContentChange}
                    value={question.answer.toString()}
                    style={{ width: '30%' }}>
                    <option value='true'>Yes</option>
                    <option value='false'>No</option>
                  </FormControl>
                </FormGroup>
              )
            }

            return (
              <FieldGroup
                key={`answer-${question.questionOrder.toString()}`}
                id={`answer-${question.questionOrder.toString()}`}
                questionid={question.questionOrder}
                label={question.content}
                type={this.mapAnswerTypeToInputType(question.answerType)}
                value={question.answer}
                help={`Answer needs to be a ${this.mapAnswerTypeToInputType(question.answerType)}`}
                onChange={this.props.onContentChange}
              />
            )
          })
        }
      </React.Fragment>
    )
  }
}

export default Questionnaire
