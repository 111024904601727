import React, { Component } from 'react'
import { Pagination } from 'react-bootstrap'

class TransfersPagination extends Component {
  render () {
    const active = this.props.currentPage
    let items = []
    for (let number = 1; number <= this.props.allPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === active} onClick={() => this.props.onPageClicked(number - 1)}>
          {number}
        </Pagination.Item>
      )
    }
    return (
      <div style={{ textAlign: 'center' }}>
        <Pagination>
          {items}
        </Pagination>
      </div>
    )
  }
}
export default TransfersPagination
