import React from 'react'
import { Label } from 'react-bootstrap'
import { MdPerson as PersonIcon } from 'react-icons/md'
import styled from 'styled-components'

const Person = styled.div`
  background-color:#193f77;
  width:120px
  height:120px
  line-height:120px
  text-align:center;
  border-radius:100%;
  background:#193f77;
  border:none;
  outline:none;
  color:#FFF;
  font-size:56px;
`

export default (({ name, isOutside }) => (
  <div>
    <Person style={{ margin: '0 auto' }}>
      <PersonIcon />
    </Person>
    <div style={{ margin: '0 auto', textAlign: "center" }}><b>{name}</b></div>
    {
      isOutside ?
        <div style={{ textAlign: "center" }}><Label>Outside user</Label></div> : null
    }
  </div>
))
