const initState = {
  isLoading: false,
  transfers: []
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'TRANSFERS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'TRANSFERS.ADD_TRANSFER':
      return {
        ...state,
        transfers: [ ...state.transfers, action.transfer ]
      }
    case 'TRANSFERS.SET_TRANSFERS':
      return {
        ...state,
        transfers: action.transfers
      }
    default: return state
  }
}

export default reducer
