import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function ({ elements, activeItem, onElementClicked }) {
  const pages = []
  for (let i = 0; i < elements.length; i++) {
    pages.push(
      <Pagination.Item
        key={`page-${i}`}
        active={elements[i].order === activeItem}
        onClick={() => onElementClicked(elements[i].order)}>
        {mapOrderToName(elements[i].order)}
      </Pagination.Item>
    )
  }

  return (
    <Pagination bsSize='large'>
      {
        pages
      }
    </Pagination>
  )
}

function mapOrderToName (elementOrder) {
  switch (elementOrder) {
    case 1: return 'Front'
    case 2: return 'Driver side'
    case 3: return 'Back'
    case 4: return 'Passenger side'
    case 5: return 'Roof'
    default: return elementOrder
  }
}
