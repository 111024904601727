import React from 'react'
import { Link } from 'react-router-dom'
import { Table } from 'react-bootstrap'

function CarBriefInfo ({ manufacturer, fuelType, car, regulation }) {
  const lastUpdate = new Date(car.lastUpdateDate)

  return (
    <Table bordered striped hover>
      <thead>
        <tr>
          <th colSpan={2}>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Manufacturer</td>
          <td>{manufacturer.name}</td>
        </tr>
        <tr>
          <td>Model</td>
          <td>{car.model}</td>
        </tr>
        <tr>
          <td>License plate</td>
          <td>{car.licensePlate}</td>
        </tr>
        <tr>
          <td>Color</td>
          <td>{car.color}</td>
        </tr>
        <tr>
          <td>Fuel type</td>
          <td>{fuelType.name}</td>
        </tr>
        <tr>
          <td>Tires</td>
          <td>{car.tires}</td>
        </tr>
        <tr>
          <td>Regulation</td>
          <td><Link to={`/dashboard/regulation/${regulation._id}/latest`}>{regulation.title}</Link></td>
        </tr>
        <tr>
          <td>Last update</td>
          <td>{ lastUpdate.toLocaleDateString() } { lastUpdate.toLocaleTimeString() }</td>
        </tr>
      </tbody>
    </Table>
  )
}
export default CarBriefInfo
