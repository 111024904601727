import React from 'react'
import { Button, Glyphicon } from 'react-bootstrap'

export default function (props) {
  const { disabled, onClick } = props

  return (
    <Button
      title='Upload new photo'
      disabled={disabled}
      onClick={onClick}
      className='pull-right'>
      <Glyphicon glyph='open' />
    </Button>
  )
}
