import React, { Component } from 'react'
import { connect } from 'react-redux'
import getActions from '../actions/actions'
import { Grid, Row, Col, Alert } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import UsersList from './UsersList'
import OutsideUsersList from './OutsideUsersList'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'

class Users extends Component {
  pushToAdd = () => {
    this.props.history.push('/dashboard/user/add')
  }

  onCarClicked = (id) => {
    this.props.history.push(`/dashboard/car/${id}`)
  }

  onEditClicked = (id) => {
    this.props.history.push(`/dashboard/user/${id}/edit`)
  }

  componentDidMount() {
    this.props.downloadUsers()
    this.props.downloadOutsideUsers()
  }

  render() {
    const { users, outsideUsers, currentUser, isLoading } = this.props
    const [archivedUsers, activeUsers] = [
      (users || []).filter((user) => user.archived),
      (users || []).filter((user) => !user.archived),
    ]

    let componentToRender
    if (!isLoading) {
      if (!users) {
        componentToRender = (
          <Col md={6}>
            <Alert bsStyle='warning'>
              No users found.
            </Alert>
          </Col>
        )
      } else {
        componentToRender = (
          <>
            <UsersList
              users={activeUsers}
              currentUser={currentUser}
              onCarClicked={this.onCarClicked}
              onEditClicked={this.onEditClicked} />
            <OutsideUsersList
              outsideUsers={outsideUsers}
              onCarClicked={this.onCarClicked} />
          </>
        )
      }
    }

    return (
      <Grid>
        <Row>
          <h1>
            Users {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>
        <Row>
          {componentToRender}
        </Row>
        {
          archivedUsers.length > 0 && (
            <Row>
              <h2>Archived users</h2>
              <UsersList
                users={archivedUsers}
                currentUser={currentUser}
                onCarClicked={this.onCarClicked}
                onEditClicked={this.onEditClicked}
                archived />
            </Row>
          )
        }


        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.pushToAdd}
          title='Create new user'>
          <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>
      </Grid>
    )
  }
}

function mapStoreStateToProps({ users, loginPage }) {
  return { ...users, currentUser: { ...loginPage.auth } }
}

function mapDispatchToProps(dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(Users)
