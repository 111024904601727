import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import getActions from '../../actions/carModelsActions'
import { Grid, Row, Col, Label } from 'react-bootstrap'
import { cloneDeep } from 'lodash'
import RevisionsChooser from './RevisionsChooser'
import CarModelElement from './CarModelElement'
import ElementChooser from './ElementChooser'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import FAB from '../../../../components/common/FAB'
import { MdModeEdit as EditIcon, MdContentCopy as CloneIcon } from 'react-icons/md'

class CarModelDetails extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    this.props.downloadCarModel(id)
  }

  getCarModelById = (id) => {
    return this.props.carModels.filter((carModel) => carModel._id === id)[0]
  }

  getNewestCarModelRevision (carModel) {
    carModel.revisions.sort((a, b) => a.version - b.version)
    return carModel.revisions[carModel.revisions.length - 1].version
  }

  getRequestedRevisionNumber (carModel, revision) {
    if (revision === 'latest') {
      revision = this.getNewestCarModelRevision(carModel)
    } else revision = parseInt(revision, 10)
    return revision
  }

  getRequestedRevisionContent (carModel, revisionNumber) {
    return carModel.revisions.filter((revision) => revision.version === revisionNumber)[0]
  }

  getRequestedElement (revision, elementOrder) {
    return revision.elements.filter((element) => element.order === elementOrder)[0]
  }

  handleRevisionVersionClick = (requestedRevisionNumber) => {
    const { id } = this.props.match.params
    this.props.history.push(`/dashboard/carModel/${id}/${requestedRevisionNumber}/element/1`)
  }

  handleElementOrderClick = (requestedElementOrder) => {
    const { id, revision } = this.props.match.params
    this.props.history.push(`/dashboard/carModel/${id}/${revision}/element/${requestedElementOrder}`)
  }

  pushToEdit = () => {
    let { id, revision } = this.props.match.params
    if (revision === 'latest') revision = this.getNewestCarModelRevision(this.getCarModelById(id))
    this.props.history.push(`/dashboard/carModel/${id}/${revision}/edit/element/1`)
  }

  pushToClone = () => {
    let { id, revision } = this.props.match.params
    const carModel = this.getCarModelById(id)
    if (revision === 'latest') revision = this.getNewestCarModelRevision(carModel)
    this.props.setNewCarModel(
      'cloned',
      '',
      '',
      cloneDeep(this.getRequestedRevisionContent(carModel, parseInt(revision)).elements)
    )
    this.props.history.push(`/dashboard/carModel/add/element/1`)
  }

  render () {
    const { id, revision, element } = this.props.match.params

    let details = (
      <Row>
        <h1>Car model details <LoadingIcon isLoading={this.props.isLoading} /></h1>
      </Row>
    )

    if (!this.props.isLoading) {
      const carModel = this.getCarModelById(id)
      const requestedRevisionNumber = this.getRequestedRevisionNumber(carModel, revision)
      const requestedRevision = this.getRequestedRevisionContent(carModel, requestedRevisionNumber)
      const requestedElement = this.getRequestedElement(requestedRevision, parseInt(element))

      details = (
        <Fragment>
          <Row>
            <Col md={6}>
              <h1>
                <Label>{carModel.carBodyType}</Label>
                {' '} {carModel.carName} model details
              </h1>
              <span style={{ marginTop: '16px' }}>
                <p style={{ display: 'inline' }}>Revisions: {' '}</p>
                <RevisionsChooser
                  revisions={carModel.revisions}
                  requestedRevisionNumber={requestedRevisionNumber}
                  onRevisionClicked={this.handleRevisionVersionClick} />
              </span>
            </Col>
          </Row>
          <Row style={{ marginTop: 16 }}>
            <Col md={6}>
              <CarModelElement carModelElement={requestedElement} />
            </Col>
            <Col md={6}>
              <h4>Elements: </h4>
              <ElementChooser
                elements={requestedRevision.elements}
                activeItem={requestedElement.order}
                onElementClicked={this.handleElementOrderClick}
              />
            </Col>
          </Row>
        </Fragment>
      )
    }

    return (
      <Grid>
        {details}

        { !this.props.isLoading
          ? (
            <Fragment>
              <FAB
                mini
                style={{ 'position': 'fixed', right: '96px', bottom: '28px' }}
                onClick={this.pushToClone}
                title='Create new car model out of this one'>
                <CloneIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px', maxWidth: '24px' }} />
              </FAB>

              <FAB
                style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
                onClick={this.pushToEdit}
                title='Edit questionnaire'>
                <EditIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
              </FAB>
            </Fragment>
          ) : null}
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ carModels }) {
  return carModels
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CarModelDetails)
