import React from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'

export default ({ revisions, requestedRevisionNumber, onRevisionClicked }) => {
  return (
    <ButtonGroup>
      {
        revisions.map((revision) => {
          return (
            <Button
              key={revision.version}
              active={revision.version === requestedRevisionNumber}
              onClick={() => onRevisionClicked(revision.version)}>
              {revision.version}
            </Button>
          )
        })
      }
    </ButtonGroup>
  )
}
