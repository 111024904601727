const initState = {
  isLoading: true,
  groups: [],
  newGroup: {
    id: '',
    name: '',
    created: false,
    error: '',
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'GROUPS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'GROUPS.ADD_GROUP':
      return {
        ...state,
        groups: [...state.groups, action.group]
      }
    case 'GROUPS.SET_GROUPS':
      return {
        ...state,
        groups: action.groups
      }
    case 'GROUPS.SET_NEW_GROUP':
      return {
        ...state,
        newGroup: action.newGroup
      }
    default: return state
  }
}

export default reducer
