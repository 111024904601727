import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import getActions from '../../actions/actions'
import getGroupActions from '../../../Groups/actions/actions'
import { Grid, Row, Col, FormGroup, ControlLabel, FormControl, Checkbox, Panel, Fade } from 'react-bootstrap'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import FabLoadableIcon from '../../../../components/common/FabLoadableIcon'
import { getFullUserById } from '../../../../api'

class CreateUser extends Component {
  componentDidMount() {
    this.props.groupActions.downloadGroups()
    const isThisNewUser = !this.props.match.params.id
    if (!isThisNewUser) {
      const { id } = this.props.match.params
      getFullUserById(id)
        .then((response) => {
          const { _id, username, role, meta, passwordChangeAtNextLogin } = response.data
          this.props.setNewUser(_id, username, '', role, meta, passwordChangeAtNextLogin || false)
          this.props.setUserDataBeforeEdit(username, role, meta, passwordChangeAtNextLogin)
          this.props.updateLoadingState(false)
        })
    } else {
      this.props.updateLoadingState(false)
    }
  }

  handleUsernameChange = (event) => {
    const { id, password, role, meta, passwordChangeAtNextLogin } = this.props.newUser
    this.props.setNewUser(id, event.target.value, password, role, meta, passwordChangeAtNextLogin)
  }

  handlePasswordChange = (event) => {
    const { id, username, role, meta, passwordChangeAtNextLogin } = this.props.newUser
    this.props.setNewUser(id, username, event.target.value, role, meta, passwordChangeAtNextLogin)
  }

  handleRoleChange = (event) => {
    const { id, username, password, meta, passwordChangeAtNextLogin } = this.props.newUser
    this.props.setNewUser(id, username, password, event.target.value, meta, passwordChangeAtNextLogin)
  }

  handlePasswordChangeAtNextLoginChange = (event) => {
    const { id, username, password, role, meta } = this.props.newUser
    this.props.setNewUser(id, username, password, role, meta, event.target.checked)
  }

  handleGroupIdChange = (event) => {
    const { id, username, password, role, passwordChangeAtNextLogin } = this.props.newUser
    this.props.setNewUser(id, username, password, role, { groupId: event.target.value }, passwordChangeAtNextLogin)
  }

  shouldSaveButtonBeShown = () => {
    const isThisNewUser = !this.props.match.params.id
    const { newUser } = this.props
    if (isThisNewUser) {
      return (
        newUser.username.length > 3 &&
        newUser.password.length === 4
      )
    } else {
      return (
        // if password length === 0 it means that there's no change
        // otherwise, new password must be 4 characters long
        (newUser.password.length === 0 || newUser.password.length === 4) &&
        this.didUserDataChanged()
      )
    }
  }

  didUserDataChanged = () => {
    const { newUser, userDataBeforeEdit } = this.props
    const passwordChanged = newUser.password.length !== 0
    const usernameChanged = newUser.username !== userDataBeforeEdit.username
    const roleChanged = newUser.role !== userDataBeforeEdit.role
    const groupChanged = newUser.meta.groupId !== userDataBeforeEdit.meta.groupId
    const passwordChangeAtNextLoginChanged =
      // First check if passwordChangeAtNextLogin is set.
      // If it isn't it means false but undefined !== false
      newUser.passwordChangeAtNextLogin !== undefined &&
      newUser.passwordChangeAtNextLogin !== userDataBeforeEdit.passwordChangeAtNextLogin

    return passwordChanged || passwordChangeAtNextLoginChanged ||
      usernameChanged || roleChanged || passwordChangeAtNextLoginChanged
      || groupChanged
  }

  saveUser = () => {
    const isThisNewUser = !this.props.match.params.id
    if (isThisNewUser) this.props.postUser()
    else this.props.putUser()
  }

  render() {
    const { newUser, groups } = this.props
    const query = new URLSearchParams(this.props.location.search)
    const isThisNewUser = !this.props.match.params.id

    return (
      <Grid>

        {
          newUser.created
            ? <Redirect to={query.get('back') || '/dashboard/users'} /> : null
        }

        <Row>
          <h1>{isThisNewUser ? 'Create' : 'Edit'} user</h1>
          <Col md={6}>
            {
              newUser.error.length > 0
                ? (<Panel bsStyle='warning'>
                  <Panel.Heading>
                    <Panel.Title>
                      Warning
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    {newUser.error}
                  </Panel.Body>
                </Panel>) : null
            }
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FieldGroup
              label='Username'
              placeholder='eg. john.smith'
              value={newUser.username}
              onChange={this.handleUsernameChange}
              minLength={3}
              maxLength={64}
              autoComplete='off'
            />
          </Col>
          <Col md={3}>
            <FieldGroup
              type='password'
              label='PIN (4 characters)'
              placeholder='eg. 4594'
              value={newUser.password}
              onChange={this.handlePasswordChange}
              minLength={4}
              maxLength={4}
              autoComplete='off'
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>User role</ControlLabel>
              <FormControl
                componentClass='select'
                placeholder='eg. User'
                value={newUser.role}
                onChange={this.handleRoleChange}>
                <option value='user'>User</option>
                <option value='admin'>Admin</option>
              </FormControl>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>Group</ControlLabel>
              <FormControl
                componentClass='select'
                placeholder='eg. default'
                value={newUser.meta.groupId}
                onChange={this.handleGroupIdChange}>
                {
                  groups.map(({ _id, name }) => <option key={_id} value={_id}>{name}</option>)
                }
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormGroup>
              <Checkbox
                value={newUser.passwordChangeAtNextLogin}
                checked={newUser.passwordChangeAtNextLogin}
                onChange={this.handlePasswordChangeAtNextLoginChange}>
                Require password change at next login
              </Checkbox>
            </FormGroup>
          </Col>
        </Row>

        <Fade in={this.shouldSaveButtonBeShown()}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.saveUser}
            title='Save user'>
            <FabLoadableIcon isLoading={this.props.isLoading} />
          </FAB>
        </Fade>
      </Grid >
    )
  }
}

function mapStoreStateToProps({ users, groups }) {
  return { ...users, groups: groups.groups }
}

function mapDispatchToProps(dispatch) {
  return { ...getActions(dispatch), groupActions: getGroupActions(dispatch) }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(CreateUser)
