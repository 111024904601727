const initState = {
  isLoading: true,
  fuelTypes: []
}

const reducer = (state = initState, action) => {
  if (action.type === 'FUEL_TYPES.SET_LOADING') {
    return {
      ...state,
      isLoading: action.isLoading
    }
  }

  if (action.type === 'FUEL_TYPES.ADD_FUEL_TYPE') {
    return {
      ...state,
      fuelTypes: [...state.fuelTypes, action.fuelType]
    }
  }

  if (action.type === 'FUEL_TYPES.SET_FUEL_TYPES') {
    return {
      ...state,
      fuelTypes: action.fuelTypes
    }
  }

  return state
}

export default reducer
