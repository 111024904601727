export default function getActions(dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    setAlertTemplates: (templates, language = 'en_GB') => {
      dispatch(setAlertTemplates(templates, language))
    },
    saveAlertTemplates: (templates, language = 'en_GB') => {
      dispatch(saveAlertTemplates(templates, language))
    },
    downloadAlertTemplates: (language = 'en_GB') => {
      dispatch(downloadAlertTemplates(language))
    }
  }
}

export function updateLoadingState(isLoading) {
  return { type: 'ALERT_TEMPLATES.SET_LOADING', isLoading }
}

export function setAlertTemplates(templates, language) {
  return {
    type: 'ALERT_TEMPLATES.SET_ALERT_TEMPLATES',
    templates
  }
}

export function downloadAlertTemplates(language) {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getAlertTemplates(language)
      .then((response) => {
        if (!response.error) dispatch(setAlertTemplates(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}

export function saveAlertTemplates(language) {
  return (dispatch, getState, api) => {
    const { alertTemplates } = getState().alertTemplates
    dispatch(updateLoadingState(true))
    return api.putAlertTemplates(language, { ...alertTemplates })
      .then((response) => {
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        dispatch(downloadAlertTemplates())
      })
  }
}
