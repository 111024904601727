import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Glyphicon } from 'react-bootstrap'
import { withRouter } from 'react-router'
import actions from '../actions/dashboardActions'
import LocalizedStrings from 'react-localization'

const MenuContainer = styled.div`
    border-right: 1px solid rgba(0,0,0,.1);
    font-size: 16px;
    overflow: hidden;
    min-height: calc(100vh - 60px);
    background: #fff;
    position: fixed;
    margin-top: 60px;
    z-index: 10000
`

const MenuItem = styled.li`
    display: flex;
    color: ${props => (props.active ? '#193f77' : 'rgba(0,0,0,0.86)')};
    list-style-type: none;
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
    white-space: nowrap;
    transition: background-color 300ms ease-in-out;
    height: 40px;
    padding: 0 16px;
    user-select: none;
    &:hover {
        background-color: #e1e1e1;
        cursor: pointer;
    }
`

const MenuItemText = styled.div`
    padding-top: 10px;
`

const MenuItemIcon = styled(Glyphicon)`
    padding-top: 10px;
    padding-right: 10px;
    margin-left: 20px;
`

const LineSeperator = styled.div`
    border-top: solid 1px ${props => (props.black ? 'rgba(51,51,51,.3)' : 'rgba(0,0,0,0)')};
    margin: 6px 0 6px 0;
`

const strings = new LocalizedStrings({
  en: {
    dashboard: 'Dashboard',
    questionnaires: 'Questionnaires',
    regulations: 'Regulations',
    carModels: 'Car models',
    cars: 'Cars',
    alertTemplates: 'Alert templates',
    users: 'Users',
    groups: 'Groups',
  },
  pl: {
    dashboard: 'Panel',
    questionnaires: 'Pytania',
    regulations: 'Regulaminy',
    carModels: 'Modele',
    cars: 'Pojazdy',
    alertTemplates: 'Szablony alertów',
    users: 'Użytkownicy',
    groups: 'Grupy',
  },
  de: {
    dashboard: 'Dashboard',
    questionnaires: 'Fragebögen',
    regulations: 'Vorschriften',
    carModels: 'Automodelle',
    cars: 'Autos',
    alertTemplates: 'Benachrichtigungsvorlagen',
    users: 'Benutzer',
    groups: 'Gruppen',
  },
  fr: {
    dashboard: 'Tableau de bord',
    questionnaires: 'Questionnaires',
    regulations: 'Réglementations',
    carModels: 'Modèles de voiture',
    cars: 'Voitures',
    alertTemplates: 'Modèles d’alerte',
    users: 'Utilisateurs',
    groups: 'Groupes',
  }
})

const menuOptions = [
  { admin: true, name: strings.questionnaires, glyph: 'comment', path: '/dashboard/questionnaires' },
  { admin: true, name: strings.regulations, glyph: 'book', path: '/dashboard/regulations' },
  { admin: true, name: strings.carModels, glyph: 'folder-open', path: '/dashboard/carModels' },
  { admin: false, name: strings.cars, glyph: 'piggy-bank', path: '/dashboard/cars' },
  { admin: true, name: strings.alertTemplates, glyph: 'alert', path: '/dashboard/alertTemplates/en_GB' },
  { admin: true, name: strings.users, glyph: 'user', path: '/dashboard/users' },
  { admin: true, name: strings.groups, glyph: 'tasks', path: '/dashboard/groups' },
]

class DashboardMenu extends React.Component {
  render() {
    const { history, auth, showMainMenu } = this.props
    const options = auth.role === 'admin' ? menuOptions : menuOptions.filter(({ admin }) => !admin);

    return (
      <div>
        <div style={{ transition: '.3s', width: showMainMenu ? 240 : 80 }}>
          <MenuContainer>
            <ul style={{ padding: 0 }}>
              <LineSeperator />
              <MenuItem active onClick={() => history.push('/dashboard')}
                title={strings.dashboard}>
                <MenuItemIcon
                  glyph='th-list'
                  style={showMainMenu
                    ? { fontSize: '15px', transition: '0.3s' } : { fontSize: '18px', marginLeft: '15px', transition: '0.3s' }} />
                <MenuItemText style={showMainMenu ? { display: 'block', transition: '0.2s' } : { display: 'none' }}>
                  Dashboard
                </MenuItemText>
              </MenuItem>
              <LineSeperator black />
              {
                options.map((menuItem) => {
                  return (
                    <MenuItem active key={menuItem.path}
                      onClick={() => history.push(menuItem.path)}
                      title={menuItem.name}>
                      <MenuItemIcon
                        glyph={menuItem.glyph}
                        style={showMainMenu
                          ? { fontSize: '15px', transition: '0.3s' } : { fontSize: '18px', marginLeft: '15px', transition: '0.3s' }} />
                      <MenuItemText style={showMainMenu ? { display: 'block' } : { display: 'none' }}>
                        {menuItem.name}
                      </MenuItemText>
                    </MenuItem>
                  )
                })
              }
            </ul>
          </MenuContainer>
        </div>
      </div>
    )
  }
}

const mapStoreStateToProps = (state) => {
  return {
    ...state.dashboard,
    auth: { ...state.loginPage.auth },
  }
}

const mapDispatchToProps = (dispatch) => {
  return actions(dispatch)
}

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(withRouter(DashboardMenu))
