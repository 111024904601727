import React, { Component } from 'react'
import { Row, Col, ListGroup, ListGroupItem, Badge, Label, Image } from 'react-bootstrap'
import withApiUrl from '../../../components/common/hoc/withApiUrl'
import LocalizedStrings from 'react-localization'

const strings = new LocalizedStrings({
  en: {
    carBodyType: 'Car body type',
    fuelType: 'Fuel type',
    color: 'Color',
    licensePlate: 'License plate',
    lastUpdated: 'Last updated',
  },
  pl: {
    carBodyType: 'Rodzaj pojazdu',
    fuelType: 'Rodzaj paliwa',
    color: 'Kolor',
    licensePlate: 'Numer rejestracyjny',
    lastUpdated: 'Ostatnia aktualizacja',
  },
  de: {
    carBodyType: 'Fahrzeugtyp',
    fuelType: 'Kraftstoffart',
    color: 'Farbe',
    licensePlate: 'Kennzeichen',
    lastUpdated: 'Zuletzt aktualisiert',
  },
  fr: {
    carBodyType: 'Type de carrosserie',
    fuelType: 'Type de carburant',
    color: 'Couleur',
    licensePlate: 'Plaque d’immatriculation',
    lastUpdated: 'Dernière mise à jour',
  }
})

class CarsList extends Component {
  findCarModelById = (id) => {
    return this.props.carModels.filter((carModel) => carModel._id === id)[0]
  }

  findManufacturerById = (id) => {
    return this.props.carManufacturers.filter((manufacturer) => manufacturer._id === id)[0]
  }

  findFuelTypeById = (id) => {
    return this.props.fuelTypes.filter((fuelType) => fuelType._id === id)[0]
  }

  getLatestMileageRecord = (car) => {
    return car.mileageRecords[car.mileageRecords.length - 1]
  }

  getTyreTypeIcon = (tyreType) => {
    switch (tyreType) {
      case 'summer': return '☀️'
      case 'winter': return '❄️'
      case 'all-season': return '♾️'
      default: return '?'
    }
  }

  getTyreTypeTitle = (tyreType) => {
    switch (tyreType) {
      case 'summer': return 'Summer tyres'
      case 'winter': return 'Winter tyres'
      case 'all-season': return 'All-season tyres'
      default: return '?'
    }
  }

  render() {
    const { cars, apiUrl, onCarClicked } = this.props

    return (
      <ListGroup>
        {
          cars.sort((a, b) => a.model.toLowerCase().localeCompare(b.model.toLowerCase())).map((car) => {
            const carModel = this.findCarModelById(car.carModelId)
            const newestRevision = carModel.revisions[carModel.revisions.length - 1]
            const photoUrl = car.common && car.common.avatar ? car.common.avatar : newestRevision.elements.length > 0 ? newestRevision.elements[0].imagePath : null

            const manufacturer = this.findManufacturerById(car.manufacturerId)
            const fuelType = this.findFuelTypeById(car.fuelTypeId)
            const lastUpdate = new Date(car.lastUpdateDate)

            return (
              <ListGroupItem
                key={car._id}
                onClick={() => onCarClicked(car._id)}
              >
                <Row>
                  <Col xs={12} sm={4} md={3}>
                    <Image src={`${apiUrl}${photoUrl}?width=450`} thumbnail loading='lazy' />
                  </Col>
                  <Col xs={12} sm={8} md={7}>
                    <b style={{ fontSize: '16px' }}>
                      <Label title={strings.carBodyType}>{carModel.carBodyType}</Label>
                      {' '}{car.common && car.common.nick ? <i>{car.common.nick}</i> : `${manufacturer.name} ${car.model}`} {' '}
                      ({car.user ? car.user.details.username ? car.user.details.username : car.user.details.name : ''})
                    </b>
                    <p style={{ marginTop: '12px' }}>
                      {strings.color}: {car.color}
                    </p>
                    <p>
                      {strings.licensePlate}: {car.licensePlate}
                    </p>
                    <p>
                      {strings.lastUpdated}: {lastUpdate.toLocaleDateString()} {lastUpdate.toLocaleTimeString()}
                    </p>
                    <Badge title={strings.fuelType}>{fuelType.name}</Badge>  <span title={this.getTyreTypeTitle(car.tires)}>{this.getTyreTypeIcon(car.tires)}</span>
                  </Col>
                </Row>
              </ListGroupItem>
            )
          })
        }
      </ListGroup>
    )
  }
}

export default withApiUrl(CarsList)
