import React from 'react'
import { Panel } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

export default ({ title, content }) => {
  return (
    <Panel>
      <Panel.Heading>
        <Panel.Title>
          {title}
        </Panel.Title>
      </Panel.Heading>
      <Panel.Body>
        <ReactMarkdown source={content} />
      </Panel.Body>
    </Panel>
  )
}
