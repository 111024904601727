import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, ListGroup, ListGroupItem, Button, Fade } from 'react-bootstrap'
import QuestionOrderChanger from './QuestionOrderChanger'
import getActions from '../../actions/questionnairesActions'
import EditableQuestion from './EditableQuestion'
import DeleteButton from './DeleteButton'
import FAB from '../../../../components/common/FAB'
import FabLoadableIcon from '../../../../components/common/FabLoadableIcon'
import { cloneDeep } from 'lodash'
import FieldGroup from '../../../../components/common/FieldGroup'
import { getQuestionnaireById } from '../../../../api'

class CreateQuestionnaire extends Component {
  componentDidMount () {
    // set questionnaires.newQuestionnaire state
    let isThisNewQuestionnaire = !this.props.match.params.id
    if (isThisNewQuestionnaire) {
      // reset questionnaire only if previous id is not equal to 'cloned'
      // If it is equal, that means a clone option is being used so no reset is required
      if (this.props.newQuestionnaire.id !== 'cloned') this.props.resetNewQuestionnaire()
      this.props.updateLoadingState(false)
    } else {
      const { id, revision } = this.props.match.params
      getQuestionnaireById(id)
        .then((response) => {
          const { _id, title, questions } = this.findRequestedQuestionnaireRevision(response.data, parseInt(revision))
          // set requested revision as new questionnaire
          this.props.setNewQuestionnaire(_id, questions, title)
          this.props.addQuestionnaire(response.data)
          this.props.updateLoadingState(false)
        })
    }
  }

  findRequestedQuestionnaireRevision = (questionnaire, requestedRevision) => {
    // find and clone the object to make sure that this component will not mutate a in-store variable!
    const requestedQuestionnaireRevision = cloneDeep(
      questionnaire.revisions
        .filter((revision) => revision.version === requestedRevision)[0]
    )

    return {
      ...requestedQuestionnaireRevision,
      _id: questionnaire._id,
      title: questionnaire.title
    }
  }

  handleTitleChange = (event) => {
    this.props.changeNewQuestionnaireTitle(event.target.value, this.props.newQuestionnaire)
  }

  handleNewQuestionContent = (event) => {
    // FIXME: find a better way to send the question order via event
    const questionOrder = parseInt(
      event.target.id.replace('question-content-', ''),
      10
    )
    const newContent = event.target.value
    this.props.editQuestionContentInNewQuestionnaire(
      questionOrder,
      newContent,
      this.props.newQuestionnaire
    )
  }

  handleAnswerTypeChange = (event) => {
    // FIXME: find a better way to send the question order via event
    const questionOrder = parseInt(
      event.target.id.replace('question-answer-type-', ''),
      10
    )
    const newAnswerType = event.target.value
    this.props.editQuestionAnswerTypeInNewQuestionnaire(
      questionOrder,
      newAnswerType,
      this.props.newQuestionnaire
    )
  }

  handleEnterClickedInQuestionField = () => {
    if (this.doesEveryQuestionHaveContent()) {
      this.addNewQuestion()
    }
  }

  addNewQuestion = (event) => {
    this.props.addNewBlankQuestionToNewQuestionnaire(
      this.props.newQuestionnaire
    )
  }

  handleQuestionOrderChange = (questionOrder, orderChange) => {
    this.props.changeQuestionsOrderInNewQuestionnaire(
      questionOrder,
      orderChange,
      this.props.newQuestionnaire
    )
  }

  deleteQuestion = (questionOrder) => {
    this.props.deleteQuestionInNewQuestionnaire(
      questionOrder,
      this.props.newQuestionnaire
    )
  }

  doesEveryQuestionHaveContent = () => {
    const { questions } = this.props.newQuestionnaire
    for (const question of questions) {
      if (!question.content) return false
    }

    return true
  }

  saveQuestionnaire = () => {
    this.props.updateLoadingState(true)

    const { id } = this.props.newQuestionnaire
    if (id === 'new' || id === 'cloned') {
      this.saveNewQuestionnaire()
    } else this.addRevisionToQuestionnaire()

    this.props.history.push('/dashboard/questionnaires')
  }

  saveNewQuestionnaire = () => {
    this.props.postQuestionnaire()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewQuestionnaire()
  }

  addRevisionToQuestionnaire = () => {
    this.props.putQuestionnaire()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewQuestionnaire()
  }

  getNewestQuestionnaireRevision (questionnaire) {
    questionnaire.revisions.sort((a, b) => a.version - b.version)
    return questionnaire.revisions[questionnaire.revisions.length - 1].version
  }

  render () {
    const { questions, title } = this.props.newQuestionnaire
    const headerActionDescription = this.props.match.params.id
      ? 'Edit' : 'Create'

    return (
      <Grid>
        <h1>{headerActionDescription} questionnaire</h1>
        <Row>
          <Col md={6}>
            <FieldGroup
              id='questionnaire-title'
              label='Questionnaire title'
              type='text'
              help='Eg. Hatchback'
              value={title}
              onChange={this.handleTitleChange}
            />
            <ListGroup>
              {
                questions.map((question) => {
                  return (
                    <ListGroupItem key={`question-container-${question.order}`}>
                      <EditableQuestion
                        question={question}
                        onContentChange={this.handleNewQuestionContent}
                        onAnswerTypeChange={this.handleAnswerTypeChange}
                        onEnterClicked={this.handleEnterClickedInQuestionField}
                        autoFocus={question.order === questions.length}
                      />
                      <QuestionOrderChanger
                        style={{ marginLeft: '16px' }}
                        onUpClick={() => this.handleQuestionOrderChange(question.order, -1)}
                        canGoUp={question.order !== 1}
                        onDownClick={() => this.handleQuestionOrderChange(question.order, 1)}
                        canGoDown={question.order !== questions.length} />
                      <DeleteButton
                        style={{ marginLeft: '16px' }}
                        disabled={question.order === 1}
                        onDeleteQuestion={() => this.deleteQuestion(question.order)}
                      />
                    </ListGroupItem>
                  )
                })
              }
              <ListGroupItem>
                <Button
                  onClick={this.addNewQuestion}
                  disabled={!this.doesEveryQuestionHaveContent()}>
                  Add new question
                </Button>
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>

        <Fade in={this.doesEveryQuestionHaveContent() && title.length > 0}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.saveQuestionnaire}
            title='Save questionnaire'>
            <FabLoadableIcon isLoading={this.props.isLoading} />
          </FAB>
        </Fade>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ questionnaires }) {
  return questionnaires
}

function mapActionsToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapActionsToProps)(CreateQuestionnaire)
