import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import o2d from 'date-from-object-id'
import getActions from '../../actions/questionnairesActions'
import { cloneDeep } from 'lodash'
import { Grid, Row, Col } from 'react-bootstrap'
import FAB from '../../../../components/common/FAB'
import { MdModeEdit as EditIcon, MdContentCopy as CloneIcon } from 'react-icons/md'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import RevisionsChooser from './RevisionsChooser'
import QuestionsList from './QuestionsList'

class QuestionnaireDetails extends Component {
  componentDidMount () {
    const { id } = this.props.match.params
    this.props.downloadQuestionnaire(id)
  }

  getQuestionnaireById = (id) => {
    return this.props.questionnaires.filter((questionnaire) => questionnaire._id === id)[0]
  }

  getNewestQuestionnaireRevision (questionnaire) {
    questionnaire.revisions.sort((a, b) => a.version - b.version)
    return questionnaire.revisions[questionnaire.revisions.length - 1].version
  }

  getRequestedRevisionNumber (routerMatch, questionnaire) {
    let requestedRevision = routerMatch.params.revision
    if (requestedRevision === 'latest') {
      requestedRevision = this.getNewestQuestionnaireRevision(questionnaire)
    } else requestedRevision = parseInt(requestedRevision, 10)
    return requestedRevision
  }

  getRequestedRevisionContent (questionnaire, revisionNumber) {
    return questionnaire.revisions.filter((revision) => revision.version === revisionNumber)[0]
  }

  handleRevisionVersionClick = (requestedRevisionNumber) => {
    const { id } = this.props.match.params
    this.props.history.push(`/dashboard/questionnaire/${id}/${requestedRevisionNumber}`)
  }

  pushToEdit = () => {
    let { id, revision } = this.props.match.params
    if (revision === 'latest') revision = this.getNewestQuestionnaireRevision(this.getQuestionnaireById(id))
    this.props.history.push(`/dashboard/questionnaire/${id}/${revision}/edit`)
  }

  pushToClone = () => {
    let { id, revision } = this.props.match.params
    const questionnaire = this.getQuestionnaireById(id)
    if (revision === 'latest') revision = this.getNewestQuestionnaireRevision(questionnaire)
    this.props.setNewQuestionnaire(
      'cloned',
      cloneDeep(this.getRequestedRevisionContent(questionnaire, parseInt(revision)).questions),
      ''
    )
    this.props.history.push(`/dashboard/questionnaire/add`)
  }

  render () {
    const requestedId = this.props.match.params.id

    let details = (
      <Row>
        <h1>Questionnaire details <LoadingIcon isLoading={this.props.isLoading} /></h1>
      </Row>
    )
    if (!this.props.isLoading) {
      const questionnaire = this.getQuestionnaireById(requestedId)
      const requestedRevisionNumber = this.getRequestedRevisionNumber(this.props.match, questionnaire)
      const requestedRevision = this.getRequestedRevisionContent(questionnaire, requestedRevisionNumber)

      details = (
        <Fragment>
          <Row>
            <h1>Questionnaire details <LoadingIcon isLoading={this.props.isLoading} /></h1>
            <p>Name: {questionnaire.title}</p>
            <p>Date added: {o2d(questionnaire._id).toLocaleDateString()}</p>
            <span>
              <p style={{ display: 'inline' }}>Revisions: {' '}</p>
              <RevisionsChooser
                revisions={questionnaire.revisions}
                requestedRevisionNumber={requestedRevisionNumber}
                onRevisionClicked={this.handleRevisionVersionClick} />
            </span>
          </Row>
          <Row>
            <h3>Questions</h3>
            <Col md={6}>
              <QuestionsList questions={requestedRevision.questions} />
            </Col>
          </Row>
        </Fragment>
      )
    }

    return (
      <Grid>
        {details}

        { !this.props.isLoading
          ? (
            <Fragment>
              <FAB
                mini
                style={{ 'position': 'fixed', right: '96px', bottom: '28px' }}
                onClick={this.pushToClone}
                title='Create new questionnaire out of this one'>
                <CloneIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px', maxWidth: '24px' }} />
              </FAB>

              <FAB
                style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
                onClick={this.pushToEdit}
                title='Edit questionnaire'>
                <EditIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
              </FAB>
            </Fragment>
          )
          : null }
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ questionnaires }) {
  return questionnaires
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(QuestionnaireDetails)
