import React, { Component } from 'react'
import { connect } from 'react-redux'
import getCarManufacturersActions from '../../CarManufacturers/actions/carManufacturerActions'
import getFuelTypesActions from '../../FuelTypes/actions/fuelTypeActions'
import getCarsActions from '../../Cars/actions/carsActions'
import getRegulationsActions from '../../Regulations/actions/regulationsActions'
import { Grid, Row, Col } from 'react-bootstrap'
import { Route } from 'react-router-dom'
import TransferDetails from './TransferDetails'
import CarBriefInfo from './CarBriefInfo'
import api from '../../../api'
import LoadingIcon from '../../../components/common/LoadingIcon'

class Transfer extends Component {
  componentWillMount () {
    this.setLoadingStateForAllDataSources(true)

    // download a car and other dependent data
    const { id } = this.props.match.params
    api.getCarByTransfersHistoryId(id)
      .then((response) => {
        const car = response.data

        Promise
          .all([
            api.getFuelTypeById(car.fuelTypeId),
            api.getCarManufacturerById(car.manufacturerId),
            api.getRegulationById(car.regulationId)
          ])
          .then(
            (
              responses
            ) => {
              this.props.setCars([car])
              this.props.setFuelTypes([responses[0].data])
              this.props.setCarManufacturers([responses[1].data])
              this.props.setRegulations([responses[2].data])

              this.setLoadingStateForAllDataSources(false)
            })
      })
  }

  setLoadingStateForAllDataSources = (isLoading) => {
    if (isLoading) { this.setState({ isLoading }) }

    this.props.setCarsLoading(isLoading)
    this.props.setFuelTypesLoading(isLoading)
    this.props.setCarManufacturersLoading(isLoading)
    this.props.setRegulationsLoading(isLoading)

    if (!isLoading) { this.setState({ isLoading }) }
  }

  findCarById = (id) => {
    return this.props.cars.filter((car) => car.transfersHistoryId === id)[0]
  }

  findManufacturerById = (id) => {
    return this.props.carManufacturers.filter((manufacturer) => manufacturer._id === id)[0]
  }

  findRegulationById = (id) => {
    return this.props.regulations.filter((regulation) => regulation._id === id)[0]
  }

  findFuelTypeById = (id) => {
    return this.props.fuelTypes.filter((fuelType) => fuelType._id === id)[0]
  }

  render () {
    let component = (
      <Row>
        <h1>Car transfers history {' '}<LoadingIcon isLoading /></h1>
      </Row>
    )
    const car = this.findCarById(this.props.match.params.id)

    if (!this.props.isLoading && car) {
      const manufacturer = this.findManufacturerById(car.manufacturerId)
      const fuelType = this.findFuelTypeById(car.fuelTypeId)
      const regulation = this.findRegulationById(car.regulationId)

      component = (
        (
          <Grid>
            <Row>
              <h1>{manufacturer.name} {car.model} {car.common && car.common.nick ? <i>aka {car.common.nick}</i> : ''} transfers history</h1>
            </Row>
            <Row>
              <Col md={4}>
                <CarBriefInfo car={car} manufacturer={manufacturer} fuelType={fuelType} regulation={regulation} />
              </Col>
            </Row>
          </Grid>
        )
      )
    }

    return (
      <Grid>
        {component}
        <Row>
          <Route path='/dashboard/transfer/:id/:entryNumber/carModelSide/:carModelSideNumber' component={TransferDetails} />
        </Row>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ cars, carManufacturers, fuelTypes, regulations }) {
  return {
    ...cars,
    ...carManufacturers,
    ...fuelTypes,
    ...regulations,
    isLoading: cars.isLoading ||
    carManufacturers.isLoading || fuelTypes.isLoading ||
    regulations.isLoading
  }
}

function mapDispatchToProps (dispatch) {
  return {
    ...getCarsActions(dispatch),
    ...getCarManufacturersActions(dispatch),
    ...getFuelTypesActions(dispatch),
    ...getRegulationsActions(dispatch),
    setCarsLoading: (isLoading) => getCarsActions(dispatch).updateLoadingState(isLoading),
    setCarManufacturersLoading: (isLoading) => getCarManufacturersActions(dispatch).updateLoadingState(isLoading),
    setFuelTypesLoading: (isLoading) => getFuelTypesActions(dispatch).updateLoadingState(isLoading),
    setRegulationsLoading: (isLoading) => getRegulationsActions(dispatch).updateLoadingState(isLoading)
  }
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(Transfer)
