import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import getActions from '../actions/actions'
import { MdDelete as DeleteIcon, MdUnarchive as RevertIcon } from 'react-icons/md'
import { Modal, Button } from 'react-bootstrap'
import { createPortal } from 'react-dom'

const ArchiveToggleButton = styled.a`
  cursor: pointer;
  float: right;
  color: inherit;
  transition: .3s;

  &:hover {
    transform: scale(1.1);
    color: inherit;
  }
`

function UserArchiveToggle({ user, toggleUserArchiveState }) {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const handleArchiveToggle = useCallback(() => {
    toggleUserArchiveState(user)
    setConfirmModalVisible(false)
  }, [user.id, user.archived, setConfirmModalVisible])

  const handleArchiveClick = useCallback(() => {
    setConfirmModalVisible(true)
  }, [confirmModalVisible, setConfirmModalVisible])

  return (<>
    {user.archived ?
      <ArchiveToggleButton onClick={handleArchiveToggle} title='Make user active'><RevertIcon /></ArchiveToggleButton>
      : <ArchiveToggleButton onClick={handleArchiveClick} title='Archive user'><DeleteIcon /></ArchiveToggleButton>
    }
    {
      confirmModalVisible && (
        createPortal(
          <Modal style={{ marginTop: '5em' }} show>
            <Modal.Header>
              <Modal.Title>Confirm user archive</Modal.Title>
            </Modal.Header>

            <Modal.Body>Are you sure to archive <i>{user.username}</i>? All user-related data WILL NOT be removed.</Modal.Body>

            <Modal.Footer>
              <Button onClick={() => setConfirmModalVisible(false)}>Cancel</Button>
              <Button onClick={handleArchiveToggle} bsStyle="primary">Yes, archive</Button>
            </Modal.Footer>
          </Modal>,
          document.body
        )
      )
    }

  </>)
}


function mapStoreStateToProps({ users, loginPage }) {
  return { ...users }
}

function mapDispatchToProps(dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(UserArchiveToggle)
