const initState = {
  isLoading: true,
  carManufacturers: []
}

const reducer = (state = initState, action) => {
  if (action.type === 'CAR_MANUFACTURERS.SET_LOADING') {
    return {
      ...state,
      isLoading: action.isLoading
    }
  }

  if (action.type === 'CAR_MANUFACTURERS.SET_CAR_MANUFACTURERS') {
    return {
      ...state,
      carManufacturers: action.carManufacturers
    }
  }

  return state
}

export default reducer
