import React from 'react'
import o2d from 'date-from-object-id'
import { ListGroup, ListGroupItem, Badge } from 'react-bootstrap'

export default ({ questionnaires, onQuestionnaireClicked }) => {
  return (
    <ListGroup>
      {
        questionnaires.map((questionnaire) => {
          questionnaire.revisions.sort((a, b) => a.version - b.version)
          const newestRevision = questionnaire.revisions[questionnaire.revisions.length - 1]

          return (
            <ListGroupItem
              key={questionnaire._id}
              header={questionnaire.title}
              onClick={() => onQuestionnaireClicked(questionnaire._id)}>
              { newestRevision.questions.length } question(s) | Added {o2d(questionnaire._id).toLocaleDateString()}
              <Badge className='pull-right'>Newest revision: { newestRevision.version } </Badge>
            </ListGroupItem>
          )
        })
      }
    </ListGroup>
  )
}
