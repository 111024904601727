import React from 'react'
import { Button, ButtonGroup, Glyphicon } from 'react-bootstrap'

export default (props) => {
  return (
    <ButtonGroup
      style={{ ...props.style }}>
      <Button disabled={!props.canGoUp} onClick={props.onUpClick}>
        <Glyphicon glyph='triangle-top' />
      </Button>
      <Button disabled={!props.canGoDown} onClick={props.onDownClick}>
        <Glyphicon glyph='triangle-bottom' />
      </Button>
    </ButtonGroup>
  )
}
