import httpClient from 'axios'
export const apiServerUrl = process.env.REACT_APP_API_SERVER_URL || [window.location.protocol, window.location.host].join('//')
export const apiBaseUrl = `${apiServerUrl}/api`

const apiClient = httpClient.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

export async function login(username, password, newPassword) {
  try {
    const authCombo = Buffer.from(`${username}:${password}`).toString('base64')
    const response = apiClient({
      method: 'post',
      url: '/auth/basic',
      headers: {
        Authorization: `Basic ${authCombo}`
      },
      data: {
        newPassword: newPassword && newPassword.length !== 0 ? newPassword : undefined
      }
    })
    return response
  } catch (exception) {
    return exception
  }
}

export async function logout() {
  try {
    const response = await apiClient.post('/auth/logout')
    return response
  } catch (exception) {
    return exception
  }
}

export async function me() {
  try {
    const response = await apiClient.get('/me')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getQuestionnaires() {
  try {
    const response = await apiClient.get('/questionnaires')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getQuestionnaireById(id) {
  try {
    const response = await apiClient.get(`/questionnaire/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postQuestionnaire(questionnaire) {
  try {
    const response = await apiClient.post('/questionnaire', {
      ...questionnaire
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putQuestionnaire(id, questionnaire) {
  try {
    const response = await apiClient.put(`/questionnaire/${id}`, {
      ...questionnaire
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getRegulations() {
  try {
    const response = await apiClient.get('/regulations')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getRegulationById(id) {
  try {
    const response = await apiClient.get(`/regulation/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postRegulation(regulation) {
  try {
    const response = await apiClient.post('/regulation', {
      ...regulation
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putRegulation(id, regulation) {
  try {
    const response = await apiClient.put(`/regulation/${id}`, {
      ...regulation
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarModels() {
  try {
    const response = await apiClient.get('/carModels')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarModelById(id) {
  try {
    const response = await apiClient.get(`/carModel/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postCarModel(carModel) {
  try {
    const response = await apiClient.post('/carModel', {
      ...carModel
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putCarModel(id, carModel) {
  try {
    const response = await apiClient.put(`/carModel/${id}`, {
      ...carModel
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getUsers() {
  try {
    const response = await apiClient.get('/users')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getUserById(id) {
  try {
    const response = await apiClient.get(`/user/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getFullUserById(id) {
  try {
    const response = await apiClient.get(`/user/${id}/full`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postUser(user) {
  try {
    const response = await apiClient.post('/user/new', {
      ...user
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putUser(id, user) {
  try {
    const response = await apiClient.put(`/user/${id}`, {
      ...user
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}


export async function getGroups() {
  try {
    const response = await apiClient.get('/groups')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getGroupById(id) {
  try {
    const response = await apiClient.get(`/group/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postGroup(group) {
  try {
    const response = await apiClient.post('/group/new', {
      ...group
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putGroup(id, group) {
  try {
    const response = await apiClient.put(`/group/${id}`, {
      ...group
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarManufacturers() {
  try {
    const response = await apiClient.get('/carManufacturers')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarManufacturerById(id) {
  try {
    const response = await apiClient.get(`/carManufacturer/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getFuelTypes() {
  try {
    const response = await apiClient.get('/fuelTypes')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getFuelTypeById(id) {
  try {
    const response = await apiClient.get(`/fuelType/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getTransferById(id) {
  try {
    const response = await apiClient.get(`/transfer/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getTransferByIdAndEntryNumber(id, entryNumber) {
  try {
    const response = await apiClient.get(`/transfer/${id}?skip=${entryNumber}&limit=1`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getLatestTransferById(id) {
  try {
    const response = await apiClient.get(`/transfer/${id}/latest`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCars() {
  try {
    const response = await apiClient.get('/cars?withOwners=true')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getOnlyOutsideCars() {
  try {
    const response = await apiClient.get('/cars?outsideOnly=true')
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarById(id) {
  try {
    const response = await apiClient.get(`/car/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function updateCarState(id, data) {
  try {
    const response = await apiClient.put(`/car/${id}/state`, { ...data })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getCarByTransfersHistoryId(id) {
  try {
    const response = await apiClient.get(`/car/by-transfers-history-id/${id}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getAlertsForCars(query = { carIds: [], groupId: '', date: new Date() }) {
  try {
    const params = new URLSearchParams()
    params.set('date', (query.date || new Date()).toISOString())
    params.set('carIds', query.carIds.join(','))
    if (query.groupId) {
      params.set('groupId', query.groupId)
    }
    const response = await apiClient.get(`/car/alerts?${params.toString()}`)
    return response
  } catch (exception) {
    console.error(exception)
    return { error: true, exception }
  }
}

export async function postCar(car) {
  try {
    const response = await apiClient.post('/car', {
      ...car
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function postOwnerChange(transfersHistoryId, nextUserId) {
  try {
    const response = await apiClient.post(`/transfer/${transfersHistoryId}/ownerChange`, {
      nextUserId
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putClearIssues(transfersHistoryId) {
  try {
    const response = await apiClient.put(`/transfer/${transfersHistoryId}/clearIssues`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putCar(id, car) {
  try {
    const response = await apiClient.put(`/car/${id}`, {
      ...car
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function getAlertTemplates(language = 'en_GB') {
  try {
    const response = await apiClient.get(`/car/alerts/templates?language=${language}`)
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export async function putAlertTemplates(language = 'en_GB', templates) {
  try {
    const response = await apiClient.put(`/car/alerts/templates?language=${language}`, {
      ...templates
    })
    return response
  } catch (exception) {
    return { error: true, exception }
  }
}

export default {
  login,
  logout,
  me,
  getQuestionnaires,
  getQuestionnaireById,
  postQuestionnaire,
  putQuestionnaire,
  getRegulations,
  getRegulationById,
  postRegulation,
  putRegulation,
  getCarModels,
  getCarModelById,
  postCarModel,
  putCarModel,
  getUsers,
  getUserById,
  postUser,
  putUser,
  getGroups,
  getGroupById,
  postGroup,
  putGroup,
  getCarManufacturers,
  getCarManufacturerById,
  getFuelTypes,
  getFuelTypeById,
  getTransferById,
  getTransferByIdAndEntryNumber,
  getLatestTransferById,
  getCars,
  getOnlyOutsideCars,
  getCarById,
  updateCarState,
  getCarByTransfersHistoryId,
  getAlertsForCars,
  postCar,
  putCar,
  putClearIssues,
  getAlertTemplates,
  putAlertTemplates,
}
