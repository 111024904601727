const initState =
{
  isLoading: true,
  carModels: [],
  newCarModel: {
    id: 'new',
    carBodyType: 'hatchback',
    carName: '',
    elements: [
      {
        order: 1,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 2,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 3,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      {
        order: 4,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      }
    ]
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'CAR_MODELS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'CAR_MODELS.ADD_CAR_MODEL':
      return {
        ...state,
        carModels: [ ...state.carModels, action.carModel ]
      }
    case 'CAR_MODELS.SET_CAR_MODELS':
      return {
        ...state,
        carModels: action.carModels
      }
    case 'CAR_MODELS.SET_NEW_CAR_MODEL':
      return {
        ...state,
        newCarModel: action.newCarModel
      }
    default: return state
  }
}

export default reducer
