import React, { Component } from 'react'
import { Grid, Col, Row } from 'react-bootstrap'
import LoadingIcon from '../../../../components/common/LoadingIcon'
import CarModelGrid from '../../CarModelDetails/components/CarModelGrid'
import FieldGroup from '../../../../components/common/FieldGroup'
import withApiUrl from '../../../../components/common/hoc/withApiUrl'

class CarModelElement extends Component {
  constructor (props) {
    super(props)
    this.imageElementRef = React.createRef()
    this.state = { imageElementHeight: 0, gridRefreshInterval: null, isLoading: true }
  }

  componentDidMount () {
    const imageElementHeight = this.imageElementRef.clientHeight
    this.setState({ imageElementHeight, gridRefreshInterval: setInterval(this.updateImageElementHeight, 30) })

    window.addEventListener('resize', this.updateImageElementHeight)
  }

  componentWillUnmount () {
    clearInterval(this.state.gridRefreshInterval)
    window.removeEventListener('resize', this.updateImageElementHeight)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if (prevProps.carModelElement.imagePath !== this.props.carModelElement.imagePath) {
      this.setState({
        isLoading: true
      })
    }
  }

  componentDidUpdate () {
    if (this.state.isLoading) return

    const imageHeight = this.imageElementRef.current.clientHeight
    setTimeout(() => {
      if (this.state.imageElementHeight !== imageHeight) { this.setState({ imageElementHeight: imageHeight }) }
      // timeout is for giving time for the browser to calculate values
      // maybe there's a better way to do this, but this works and it's quick to do
    }, 250)
  }

  updateImageElementHeight = () => {
    if (!this.imageElementRef || !this.imageElementRef.current) return

    const imageHeight = this.imageElementRef.current.clientHeight
    if (this.state.imageElementHeight !== imageHeight) this.setState({ imageElementHeight: imageHeight })
  }

  handleGridColumnsCountChange = (event) => {
    this.props.onGridChanged({ columns: parseInt(event.target.value), rows: this.props.carModelElement.grid.rows })
  }

  handleGridRowsCountChange = (event) => {
    this.props.onGridChanged({ columns: this.props.carModelElement.grid.columns, rows: parseInt(event.target.value) })
  }

  render () {
    const { carModelElement, apiUrl } = this.props

    return (
      <Grid style={{ width: '100%' }}>
        <Row>
          <Col md={10}>
            <LoadingIcon isLoading={this.state.isLoading} style={{ margin: '24px auto', display: 'block' }} />
            <img
              style={{ width: '100%', display: this.state.isLoading ? 'none' : 'block' }}
              src={`${apiUrl}${carModelElement.imagePath}?width=2000`}
              ref={this.imageElementRef}
              alt='Car model grid'
              onLoad={() => this.setState({ isLoading: false })}
            />
            <CarModelGrid
              style={{ width: '100%', height: `${this.state.imageElementHeight}px`, marginTop: `-${this.state.imageElementHeight}px` }}
              height={this.state.imageElementHeight}
              grid={carModelElement.grid}
            />
          </Col>
          <Col md={2}>
            <FieldGroup
              label='Columns'
              type='number'
              value={carModelElement.grid.columns}
              onChange={this.handleGridColumnsCountChange} />
            <FieldGroup
              label='Rows'
              type='number'
              value={carModelElement.grid.rows}
              onChange={this.handleGridRowsCountChange} />
          </Col>
        </Row>
      </Grid>
    )
  }
}

export default withApiUrl(CarModelElement)
