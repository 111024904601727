import React from 'react'
import styled from 'styled-components'
import DashboardNavBar from './DashboardNavBar'
import DashboardMenu from './DashboardMenu'
import DashboardErrorBoundary from './DashboardErrorBoundary'
import FuelTypes from '../FuelTypes/components/FuelTypes'
import CarManufacturers from '../CarManufacturers/components/CarManufacturers'
import Questionnaires from '../Questionnaires/components/Questionnaires'
import QuestionnaireDetails from '../Questionnaires/QuestionnaireDetails/components/QuestionnaireDetails'
import CreateQuestionnaire from '../Questionnaires/CreateQuestionnaire/components/CreateQuestionnaire'
import Regulations from '../Regulations/components/Regulations'
import RegulationDetails from '../Regulations/RegulationDetails/components/RegulationDetails'
import CreateRegulation from '../Regulations/CreateRegulation/components/CreateRegulation'
import CarModels from '../CarModels/components/CarModels'
import CarModelDetails from '../CarModels/CarModelDetails/components/CarModelDetails'
import CreateCarModel from '../CarModels/CreateCarModel/components/CreateCarModel'
import Cars from '../Cars/components/Cars'
import Transfer from '../Transfers/components/Transfer'
import CarDetails from '../Cars/CarDetails/components/CarDetails'
import CarOwnerChange from '../Cars/OwnerChange/components/OwnerChange'
import CreateCar from '../Cars/CreateCar/components/CreateCar'
import Alerts from '../Alerts/components/Alerts'
import Users from '../Users/components/Users'
import CreateUser from '../Users/CreateUser/components/CreateUser'
import EditAlertTemplates from '../AlertTemplates/EditAlertTemplates/components/EditAlertTemplates'
import { Switch, Route } from 'react-router-dom'
import AuthChecker from '../../components/common/AuthChecker'
import Groups from '../Groups/components/Groups'
import CreateGroup from '../Groups/CreateGroup/components/CreateGroup'

const DashboardBody = styled.div`
    display: flex;
    width: 100%;
    padding-left: 4px;
    padding-top: 60px;
    height: calc(100vh - 72px);
    box-sizing: border-box;
`

const DashboardSiteContainer = styled.div`
    display: flex;
    background: #f5f5f5;
`

export default class Dashboard extends React.Component {
  onNoAuth = () => {
    this.props.history.push('/login')
  }

  render() {
    return (
      <div >
        <AuthChecker onNoAuth={this.onNoAuth} />
        <DashboardNavBar style={{ position: 'fixed' }} />
        <DashboardSiteContainer>
          <DashboardMenu />
          <DashboardBody>
            <DashboardErrorBoundary>
              <Switch>
                <Route exact path='/dashboard/fuelTypes' component={FuelTypes} />
                <Route exact path='/dashboard/carManufacturers' component={CarManufacturers} />
                <Route exact path='/dashboard/questionnaires' component={Questionnaires} />
                <Route path='/dashboard/questionnaire/add' component={CreateQuestionnaire} />
                <Route path='/dashboard/questionnaire/:id/:revision/edit' component={CreateQuestionnaire} />
                <Route path='/dashboard/questionnaire/:id/:revision' component={QuestionnaireDetails} />
                <Route exact path='/dashboard/regulations' component={Regulations} />
                <Route path='/dashboard/regulation/add' component={CreateRegulation} />
                <Route path='/dashboard/regulation/:id/:revision/edit' component={CreateRegulation} />
                <Route path='/dashboard/regulation/:id/:revision' component={RegulationDetails} />
                <Route exact path='/dashboard/carModels' component={CarModels} />
                <Route path='/dashboard/carModel/add/element/:element' component={CreateCarModel} />
                <Route path='/dashboard/carModel/:id/:revision/edit/element/:element' component={CreateCarModel} />
                <Route path='/dashboard/carModel/:id/:revision/element/:element' component={CarModelDetails} />
                <Route path='/dashboard/cars/alerts' component={Alerts} />
                <Route exact path='/dashboard/cars' component={Cars} />
                <Route path='/dashboard/car/add' component={CreateCar} />
                <Route path='/dashboard/car/:id/ownerChange' component={CarOwnerChange} />
                <Route path='/dashboard/car/:id/edit' component={CreateCar} />
                <Route path='/dashboard/car/:id' component={CarDetails} />
                <Route path='/dashboard/transfer/:id' component={Transfer} />
                <Route exact path='/dashboard/users' component={Users} />
                <Route path='/dashboard/user/add' component={CreateUser} />
                <Route path='/dashboard/user/:id/edit' component={CreateUser} />
                <Route exact path='/dashboard/groups' component={Groups} />
                <Route path='/dashboard/group/add' component={CreateGroup} />
                <Route path='/dashboard/group/:id/edit' component={CreateGroup} />
                <Route path='/dashboard/alertTemplates/:language' component={EditAlertTemplates} />
              </Switch>
            </DashboardErrorBoundary>
          </DashboardBody>
        </DashboardSiteContainer>
      </div>
    )
  }
}
