import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Row, Col, Alert } from 'react-bootstrap'
import LoadingIcon from '../../../components/common/LoadingIcon'
import FAB from '../../../components/common/FAB'
import { MdAdd as AddIcon } from 'react-icons/md'
import QuestionnairesList from './QuestionnairesList'
import getActions from '../actions/questionnairesActions'

class Questionnaires extends Component {
  handleQuestionnaireClick = (id) => {
    this.props.history.push(`/dashboard/questionnaire/${id}/latest`)
  }

  openQuestionnaireEditor = () => {
    this.props.history.push('/dashboard/questionnaire/add')
  }

  componentDidMount () {
    this.props.downloadQuestionnaires()
  }

  render () {
    const { questionnaires, isLoading } = this.props

    let componentToRender
    if (!questionnaires || questionnaires.length === 0) {
      componentToRender = (
        <Alert bsStyle='warning'>
        No questionnaires found.
        </Alert>
      )
    } else {
      componentToRender = (
        <QuestionnairesList
          questionnaires={questionnaires}
          onQuestionnaireClicked={this.handleQuestionnaireClick} />
      )
    }

    return (
      <Grid>
        <Row>
          <h1>
          Questionnaires {' '}
            <LoadingIcon isLoading={isLoading} />
          </h1>
        </Row>

        <Row>
          <Col md={6}>
            { componentToRender }
          </Col>
        </Row>

        <FAB
          style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
          onClick={this.openQuestionnaireEditor}
          title='Create new questionnaire'>
          <AddIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
        </FAB>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ questionnaires }) {
  return questionnaires
}

function mapActionsToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapActionsToProps)(Questionnaires)
