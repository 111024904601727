import React, { Component } from 'react'
import LoadingIcon from '../../../components/common/LoadingIcon'
import CarModelGrid from '../../CarModels/CarModelDetails/components/CarModelGrid'
import withApiUrl from '../../../components/common/hoc/withApiUrl'
import styled from 'styled-components'

const Box4By3 = styled.div`
  width: 100%;
  height: 0;
  padding-top: 75%;
`

class CarModelElement extends Component {
  constructor (props) {
    super(props)
    this.imageElementRef = React.createRef()
    this.state = { imageElementHeight: 0, gridRefreshInterval: null, isLoading: true }
  }

  componentDidMount () {
    const imageElementHeight = this.imageElementRef.clientHeight
    this.setState({ imageElementHeight, gridRefreshInterval: setInterval(this.updateImageElementHeight, 30) })

    window.addEventListener('resize', this.updateImageElementHeight)
  }

  componentWillUnmount () {
    clearInterval(this.state.gridRefreshInterval)
    window.removeEventListener('resize', this.updateImageElementHeight)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if (prevProps.carModelElement.imagePath !== this.props.carModelElement.imagePath) {
      this.setState({
        isLoading: true
      })
    }
    return null
  }

  componentDidUpdate () {
    if (this.state.isLoading) return

    const imageHeight = this.imageElementRef.current.clientHeight
    setTimeout(() => {
      if (this.state.imageElementHeight !== imageHeight) { this.setState({ imageElementHeight: imageHeight }) }
    // timeout is for giving time for the browser to calculate values
    // maybe there's a better way to do this, but this works and it's quick to do
    }, 250)
  }

  updateImageElementHeight = () => {
    if (!this.imageElementRef || !this.imageElementRef.current) return

    const imageHeight = this.imageElementRef.current.clientHeight
    if (this.state.imageElementHeight !== imageHeight) this.setState({ imageElementHeight: imageHeight })
  }

  render () {
    const { carModelElement, apiUrl } = this.props

    return (
      <>
        <LoadingIcon isLoading={this.state.isLoading} style={{ margin: '24px auto', display: 'block' }} />
        {
          this.state.isLoading ? <Box4By3 /> : null
        }
        <img
          style={{ width: '100%', display: this.state.isLoading ? 'none' : 'block' }}
          src={`${apiUrl}${carModelElement.imagePath}?width=2000`}
          ref={this.imageElementRef}
          alt='Car model grid'
          onLoad={() => this.setState({ isLoading: false })}
        />
        <CarModelGrid
          style={{ width: '100%', height: `${this.state.imageElementHeight}px`, marginTop: `-${this.state.imageElementHeight}px` }}
          height={this.state.imageElementHeight}
          grid={carModelElement.grid}
        />
      </>
    )
  }
}
export default withApiUrl(CarModelElement)
