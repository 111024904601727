import React from 'react'
import { Fade, Glyphicon } from 'react-bootstrap'
import styled from 'styled-components'
import OutsideClickHandler from 'react-outside-click-handler'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import LogoSmall from '../../components/common/LogoSmall'
import getActions from '../actions/dashboardActions'
import getAuthActions from '../../LoginPage/actions/loginPageActions'
import { downloadAlerts } from '../Alerts/actions/actions'

const MainMenuContainer = styled.div`
  height: 60px;
  background-color: #193f77;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: row;
  color: #fff;
  user-select: none;
  position: fixed;
  width: 100%;
  z-index: 10000;
`

const LogoContainer = styled.div`
  display: flex;
`

const MenuContainer = styled.div`
  display: flex;
`

const LeftMenuContainer = styled.div`
  flex-basic: 120px;
  display: flex;
`

const UserMenuContainer = styled.div`
  margin-left: auto;
  margin-right: 30px;
`

const UserNameContainer = styled.div`
  margin-left: 15px;
  z-index: 0;
`

const UserMenuContentContainer = styled.div`
  position: absolute;
  margin-right: 25px;
  background-color: #fff;
  z-index: 1;
  border-radius: 4px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
`

const UserMenuListItem = styled.li`
  color: rgba(0, 0, 0, 0.86);
  list-style-type: none;
  font-size: 14px;
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 12px;
  white-space: nowrap;
  transition: background-color 300ms ease-in-out;
  &:hover {
    background-color: #e1e1e1;
    cursor: pointer;
  }
`

const UserMenuListItemIcon = styled(Glyphicon)`
  padding-right: 10px;
  margin-left: 16px;
`

const LineSeperator = styled.div`
  border-top: solid 1px ${props => (props.black ? 'rgba(51,51,51,.3)' : '#fff')};
  margin: 1px;
`
const Button = styled.div`
  padding-left: 21px;
  padding-right: 20px;
  height: 60px;
  display: flex;
  justify-content: center;
  transition: background-color 400ms ease-in-out;
  &:hover {
    background-color: #0e2444;
  }
`

const ButtonAttentionFocuser = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background: red;
  position: relative;
  bottom: .2em;
`

const ButtonIcon = styled.div`
  align-self: center;
  font-size: 1.9rem;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`

class DashboardNavBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = { wereAlertDownloaded: false }
  }

  hideUserMenu = () => {
    this.props.hideUserMenu()
    this.runUserMenuAnimatingStateActions()
  }

  showUserMenu = () => {
    this.props.toggleUserMenu(false)
    this.runUserMenuAnimatingStateActions()
  }

  runUserMenuAnimatingStateActions = () => {
    this.props.changeUserMenuAnimatingState(true)
    setTimeout(() => {
      this.props.changeUserMenuAnimatingState(false)
    }, 300 /* this is how long animation occurs */)
  }

  logOut = () => {
    this.props.doLogout()
  }

  downloadAlerts = () => {
    this.setState({ wereAlertDownloaded: true })
    this.props.downloadAlerts()
  }

  pushToAlerts = () => {
    this.props.history.push('/dashboard/cars/alerts')
  }

  render() {
    if (!this.state.wereAlertDownloaded) this.downloadAlerts()

    const { user } = this.props
    const username = user.auth ? user.auth.username : 'Unauthorized'

    return (
      <MainMenuContainer>

        <LogoContainer showMainMenu={this.props.showMainMenu}>
          <LogoSmall />
        </LogoContainer>

        <MenuContainer>
          <LeftMenuContainer>
            <Button onClick={this.props.toggleMainMenu}>
              <ButtonIcon>
                <Glyphicon glyph='menu-hamburger' />
              </ButtonIcon>
            </Button>
            <Button>
              <ButtonIcon>
                <Glyphicon glyph='home' onClick={() => { this.props.history.push('/dashboard') }} />
              </ButtonIcon>
            </Button>
          </LeftMenuContainer>
          <Button onClick={this.pushToAlerts}>
            <ButtonIcon >
              <Glyphicon glyph='bell' />
              {
                this.props.alerts.alerts.length > 0
                  ? <ButtonAttentionFocuser /> : null
              }
            </ButtonIcon>
          </Button>
        </MenuContainer>
        <UserMenuContainer>
          <Button onClick={this.props.isUserMenuAnimating ? null : this.showUserMenu}>
            <ButtonIcon style={{ marginTop: '7px' }}>
              <Glyphicon glyph='user' />
              <UserNameContainer>{username}</UserNameContainer>
            </ButtonIcon>
          </Button>

          <OutsideClickHandler onOutsideClick={this.hideUserMenu} disabled={!this.props.showUserMenu}>
            <Fade in={this.props.showUserMenu}>
              <UserMenuContentContainer>
                <LineSeperator />
                <UserMenuListItem>
                  <UserMenuListItemIcon glyph='info-sign' /> Need help?
                </UserMenuListItem>
                <LineSeperator black />
                <UserMenuListItem onClick={this.logOut}>
                  <UserMenuListItemIcon glyph='log-out' /> Logout
                </UserMenuListItem>
                <LineSeperator />
              </UserMenuContentContainer>
            </Fade>
          </OutsideClickHandler>
        </UserMenuContainer>
      </MainMenuContainer>
    )
  }
}

const mapStoreStateToProps = ({ dashboard, loginPage, alerts }) => {
  return {
    ...dashboard,
    user: { ...loginPage },
    alerts: { ...alerts }
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...getActions(dispatch),
    ...getAuthActions(dispatch),
    downloadAlerts: () => dispatch(downloadAlerts())
  }
}

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(withRouter(DashboardNavBar))
