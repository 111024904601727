import React from 'react'
import { Grid, Row } from 'react-bootstrap'
import styled from 'styled-components'

const GridCol = styled.div`
  border: 1px solid rgba(25, 63, 119, 0.8);
  display: inline-block;
`

const CarModelGrid = ({ grid, height, ...props }) => {
  if (!grid.rows || !grid.columns || !height) return null

  const rows = []
  for (let i = 0; i < grid.rows; i++) {
    const columns = []
    const colSize = 100 / grid.columns
    for (let j = 0; j < grid.columns; j++) {
      columns.push(
        <GridCol
          key={`col-${i}-${j}`}
          xs={colSize}
          style={{ height: '100%', width: `${colSize}%` }}
          className='grid-col'
        />
      )
    }
    rows.push(
      <Row
        key={`row-${i}`}
        style={{ height: parseInt(height) / grid.rows }}
      >
        {columns}
      </Row>
    )
  }

  return (
    <Grid style={{ display: 'flex' }} {...props}>
      {rows}
    </Grid>
  )
}

export default CarModelGrid
