import React from 'react'
import { Col, Panel, Button } from 'react-bootstrap'
import OwnedCars from './OwnedCars'
import styled from 'styled-components'

const Username = styled.b`
  overflow: hidden;
  display: block;
  max-width: 100%;
  text-overflow: ellipsis;
`

export default function ({
  users,
  currentUser,
  onCarClicked,
  onEditClicked
}) {
  return (
    <React.Fragment>
      {
        users.sort((a, b) => a.role.localeCompare(b.role)).map((user) =>
        (
          <Col xs={12} sm={6} md={4} lg={3} key={user._id}>
            <Panel bsStyle={user.role === 'admin' ? 'primary' : undefined}>
              <Panel.Heading>
                <Username>{user.username}</Username>
                {' '} {user.role} {' '}
                {
                  user._id === currentUser._id
                    ? <b><i>You</i></b>
                    : <>
                      <Button onClick={() => onEditClicked(user._id)}>Edit</Button>
                    </>
                }
              </Panel.Heading>
              <Panel.Body>
                <OwnedCars cars={user.cars} onCarClicked={onCarClicked} />
              </Panel.Body>
            </Panel>
          </Col>
        )
        )
      }
    </React.Fragment>
  )
}
