export default function getActions (dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    setFuelTypes: (fuelTypes) => {
      dispatch(setFuelTypes(fuelTypes))
    },
    downloadFuelTypes: () => {
      dispatch(downloadFuelTypes())
    },
    downloadFuelType: (id) => {
      dispatch(downloadFuelType(id))
    }
  }
}

export function updateLoadingState (isLoading) {
  return { type: 'FUEL_TYPES.SET_LOADING', isLoading }
}

export function setFuelTypes (fuelTypes) {
  return {
    type: 'FUEL_TYPES.SET_FUEL_TYPES',
    fuelTypes
  }
}

export function downloadFuelTypes () {
  return (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    return api.getFuelTypes()
      .then((response) => {
        if (!response.error) dispatch(setFuelTypes(response.data.fuelTypes))
        dispatch(updateLoadingState(false))
      })
  }
}

export function downloadFuelType (id) {
  return (dispatch, getState, api) => {
    const { fuelTypes } = getState()
    dispatch(updateLoadingState(true))
    return api.getFuelTypeById(id)
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        let wasFuelTypeFound = false
        const fuelTypesList = fuelTypes.fuelTypes
        // replace existing fuelType
        for (let i = 0; i < fuelTypesList.length; i++) {
          if (fuelTypesList[i]._id === id) {
            fuelTypesList[i] = response.data
            wasFuelTypeFound = true
          }
        }

        // or add new if not existent
        if (!wasFuelTypeFound) fuelTypesList.push(response.data)

        dispatch(setFuelTypes(fuelTypesList))
        dispatch(updateLoadingState(false))
      })
  }
}
