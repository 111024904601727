import React, { Component } from 'react'
import { Grid, Row, Col, Fade, Alert } from 'react-bootstrap'
import { MdArrowForward as ArrowIconRight, MdArrowDownward as ArrowIconDown, MdSave as SaveIcon } from 'react-icons/md'
import { getCarById, getLatestTransferById, getUserById, getUsers, postOwnerChange } from '../../../../api'
import FAB from '../../../../components/common/FAB'
import User from './User'
import UsersList from './UsersList'

const isNotArchived = ({ archived }) => !archived;

class OwnerChange extends Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      currentUser: {},
      nextUser: {},
      transfersHistoryId: '',
      isLoading: true
    }
  }

  componentDidMount() {
    this.downloadData()
  }

  downloadData = async () => {
    this.setState({ isLoading: true })

    const responses = await Promise.all([getUsers(), getCarById(this.props.match.params.id)])
    const users = [...responses[0].data.filter(isNotArchived)]
    const carResponse = responses[1]
    if (carResponse.error) throw carResponse.error.exception

    const transfersHistoryId = carResponse.data.transfersHistoryId
    const latestTransferResponse = await getLatestTransferById(transfersHistoryId)
    const transferDetails = latestTransferResponse.data.transferDetails

    const currentUser = {}
    if (transferDetails.nextUserId) {
      currentUser.isOutside = false
      currentUser._id = transferDetails.nextUserId
      const user = await getUserById(currentUser._id)
      currentUser.details = { ...user.data }
    } else if (transferDetails.nextUserDetails) {
      currentUser.isOutside = true
      currentUser.details = { ...transferDetails.nextUserDetails }
    }

    this.setState({ transfersHistoryId, currentUser, users, isLoading: false })
  }

  handleNextUserSelect = (user) => {
    this.setState({ nextUser: user })
  }

  changeOwner = async () => {
    const nextUserId = this.state.nextUser._id
    await postOwnerChange(this.state.transfersHistoryId, nextUserId)
    this.props.history.push(`/dashboard/car/${this.props.match.params.id}`)
  }

  render() {
    return (
      <Grid>
        <Row>
          <Col md={6}><h1>Change car owner</h1></Col>
        </Row>
        <Row>
          <Col md={6}>
            <Alert bsStyle={'info'}>
              By changing current owner of the car, you will create a new transfer
              that will contain information from prevoius one. New transfer will
              be marked as created by admin.
            </Alert>
          </Col>
        </Row>
        {
          this.state.isLoading ? <p>Loading...</p>
            : <Row style={{ marginTop: 64 }}>
              <Col md={2}>
                <User style={{ display: 'inline' }} name={this.state.currentUser.details.username} isOutside={this.state.currentUser.isOutside} />
              </Col>
              <Col md={1} smHidden>
                <ArrowIconRight style={{ fontSize: '2.5em', marginTop: '1.7em' }} />
              </Col>
              <Col md={1} mdHidden lgHidden>
                <ArrowIconDown style={{ fontSize: '2.5em', margin: '0.5em auto', display: 'block' }} />
              </Col>
              <Col md={3}>
                {
                  this.state.nextUser.username
                    ? <User style={{ display: 'inline' }} name={this.state.nextUser.username} />
                    : <User style={{ display: 'inline' }} name={'Not selected'} />
                }
                <UsersList users={this.state.users} onSelect={this.handleNextUserSelect} />
              </Col>
            </Row>
        }
        <Fade in={!!this.state.nextUser.username}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.changeOwner}
            title='Change owner'>
            <SaveIcon style={{ display: 'inline-block', verticalAlign: 'middle', lineHeight: 'normal', marginTop: '-7px' }} />
          </FAB>
        </Fade>
      </Grid>
    )
  }
}
export default OwnerChange
