import React, { Component } from 'react'
import { connect } from 'react-redux'
import withApiUrl from '../../../../components/common/hoc/withApiUrl'
import getActions from '../../actions/carModelsActions'
import { cloneDeep } from 'lodash'
import { Grid, Row, Col, Panel, FormGroup, FormControl, ControlLabel, Fade, Alert } from 'react-bootstrap'
import ElementChooser from './ElementChooser'
import ImageUpload from '../../../../components/common/ImageUpload'
import FieldGroup from '../../../../components/common/FieldGroup'
import FAB from '../../../../components/common/FAB'
import FabLoadableIcon from '../../../../components/common/FabLoadableIcon'
import CarModelElementGridEditor from './CarModelElementGridEditor'
import OrderChanger from './OrderChanger'
import DeleteButton from './DeleteButton'
import UploadPhotoButton from './UploadNewPhotoButton'
import { getCarModelById } from '../../../../api'

class CreateCarModel extends Component {
  componentWillMount () {
    // set questionnaires.newQuestionnaire state
    let isThisNewCarModel = !this.props.match.params.id
    if (isThisNewCarModel) {
      // reset questionnaire only if previous id is not equal to 'cloned'
      // If it is equal, that means a clone option is being used so no reset is required
      if (this.props.newCarModel.id !== 'cloned') this.props.resetNewCarModel()
    } else {
      const { id, revision } = this.props.match.params
      getCarModelById(id)
        .then((response) => {
          const { _id, carName, carBodyType, elements } = this.findRequestedCarModelRevision(response.data, parseInt(revision))
          // set requested revision as new questionnaire
          this.props.setNewCarModel(_id, carBodyType, carName, elements)
        })
    }
  }

  findRequestedCarModelRevision = (carModel, requestedRevision) => {
    // find and clone the object to make sure that this component will not mutate a in-store variable!
    const requestedCarModelRevision = cloneDeep(
      carModel.revisions
        .filter((revision) => revision.version === requestedRevision)[0]
    )

    return {
      ...requestedCarModelRevision,
      _id: carModel._id,
      carName: carModel.carName,
      carBodyType: carModel.carBodyType
    }
  }

  getRequestedElement (revision, elementOrder) {
    return revision.elements.filter((element) => element.order === elementOrder)[0]
  }

  onImageUploaded = (imagePath) => {
    const { element } = this.props.match.params
    const newCarModel = cloneDeep(this.props.newCarModel)
    const requestedElement = cloneDeep(this.getRequestedElement(newCarModel, parseInt(element)))
    requestedElement.imagePath = imagePath
    this.props.updateElementOfNewCarModel(requestedElement, newCarModel)
    this.forceUpdate()
  }

  handleGridChanged = (grid) => {
    const { element } = this.props.match.params
    const newCarModel = cloneDeep(this.props.newCarModel)
    const requestedElement = cloneDeep(this.getRequestedElement(newCarModel, parseInt(element)))
    requestedElement.grid = grid
    this.props.updateElementOfNewCarModel(requestedElement, newCarModel)
  }

  handleAddElementClick = () => {
    // the check below is here, because of Pagination.Item
    // bug that causes disabled elements react to clicks.
    if (!this.isCarModelValid()) return

    this.props.addElementToNewCarModel(
      {
        order: this.props.newCarModel.elements.length + 1,
        imagePath: '',
        grid: {
          columns: 4,
          rows: 3
        }
      },
      this.props.newCarModel
    )
  }

  handleElementOrderClick = (elementOrder) => {
    const { id } = this.props.newCarModel
    const { revision } = this.props.match.params
    if (id === 'new' || id === 'cloned') {
      this.props.history.push(`/dashboard/carModel/add/element/${elementOrder}`)
    } else {
      this.props.history.push(`/dashboard/carModel/${id}/${revision}/edit/element/${elementOrder}`)
    }
  }

  handleElementOrderChange = (orderChange) => {
    const { id } = this.props.newCarModel
    const { element, revision } = this.props.match.params
    if (id === 'new' || id === 'cloned') {
      this.props.history.push(`/dashboard/carModel/add/element/${parseInt(element) + orderChange}`)
    } else {
      this.props.history.push(`/dashboard/carModel/${id}/${revision}/edit/element/${parseInt(element) + orderChange}`)
    }
    this.props.changeElementsOrderInNewCarModel(parseInt(element), orderChange, this.props.newCarModel)
  }

  handleElementDelete = () => {
    const { id } = this.props.newCarModel
    const { element, revision } = this.props.match.params
    if (id === 'new' || id === 'cloned') {
      this.props.history.push(`/dashboard/carModel/add/element/${parseInt(element) - 1}`)
    } else {
      this.props.history.push(`/dashboard/carModel/${id}/${revision}/edit/element/${parseInt(element) - 1}`)
    }
    this.props.deleteElementInNewCarModel(parseInt(element), this.props.newCarModel)
  }

  handleElementClearImagePath = () => {
    const { element } = this.props.match.params
    const newCarModel = cloneDeep(this.props.newCarModel)
    const requestedElement = cloneDeep(this.getRequestedElement(newCarModel, parseInt(element)))
    requestedElement.imagePath = ''
    this.props.updateElementOfNewCarModel(requestedElement, newCarModel)
  }

  handleCarNameChange = (event) => {
    const { id, carBodyType, elements } = this.props.newCarModel
    const carName = event.target.value
    this.props.setNewCarModel(id, carBodyType, carName, elements)
  }

  handleCarBodyTypeChange = (event) => {
    const { id, carName, elements } = this.props.newCarModel
    const carBodyType = event.target.value
    this.props.setNewCarModel(id, carBodyType, carName, elements)
  }

  isCarModelValid = () => {
    const { newCarModel } = this.props

    if (newCarModel.elements.length < 4) return false

    for (let i = 0; i < newCarModel.elements.length; i++) {
      const element = newCarModel.elements[i]
      if (!element.imagePath) return false
      if (!element.grid.columns || !element.grid.rows) return false
    }

    return true
  }

  isThisElementFirst = () => {
    const { element } = this.props.match.params
    const { newCarModel } = this.props
    const requestedElement = this.getRequestedElement(newCarModel, parseInt(element))
    return requestedElement.order === 1
  }

  isThisElementLast = () => {
    const { element } = this.props.match.params
    const { newCarModel } = this.props
    const requestedElement = this.getRequestedElement(newCarModel, parseInt(element))
    return requestedElement.order === newCarModel.elements.length
  }

  saveCarModel = () => {
    const { id } = this.props.newCarModel
    if (id === 'new' || id === 'cloned') {
      this.saveNewCarModel()
    } else this.addRevisionToCarModel()

    this.props.history.push('/dashboard/carModels')
  }

  saveNewCarModel = () => {
    this.props.postCarModel()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewCarModel()
  }

  addRevisionToCarModel = () => {
    this.props.putCarModel()
    // changes are saved! now we can flush them to make sure that
    // old content will be not preserved in store.
    this.props.resetNewCarModel()
  }

  getNewestCarModelRevision (carModel) {
    carModel.revisions.sort((a, b) => a.version - b.version)
    return carModel.revisions[carModel.revisions.length - 1].version
  }

  render () {
    const isThisNewCarModel = !this.props.match.params.id
    const { element } = this.props.match.params
    const { newCarModel } = this.props
    const { carName, carBodyType } = newCarModel
    const requestedElement = this.getRequestedElement(newCarModel, parseInt(element))

    let componentToRender
    if (requestedElement.imagePath) {
      componentToRender = <CarModelElementGridEditor
        src={`${this.props.apiUrl}${requestedElement.imagePath}`}
        carModelElement={requestedElement}
        onGridChanged={this.handleGridChanged} />
    } else {
      componentToRender = <ImageUpload
        uploadEndpoint={`${this.props.apiUrl}/api/upload`}
        onUploadFinished={this.onImageUploaded} />
    }

    return (
      <Grid>
        <Row>
          <h1>Create car model</h1>
        </Row>
        <Row>
          <Col md={3}>
            <FieldGroup
              label='Car name'
              type='text'
              placeholder='eg. Škoda Octavia'
              value={newCarModel.carName}
              onChange={this.handleCarNameChange}
            />
          </Col>
          <Col md={3}>
            <FormGroup>
              <ControlLabel>Car body type</ControlLabel>
              <FormControl
                componentClass='select'
                value={newCarModel.carBodyType}
                onChange={this.handleCarBodyTypeChange}>
                <option>Hatchback</option>
                <option>Kombi</option>
                <option>Sedan</option>
                <option>Bus</option>
                <option>Van</option>
              </FormControl>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Col md={6}>
            <Panel>
              <Panel.Body>
                {componentToRender}
              </Panel.Body>
              <Panel.Footer>
                <OrderChanger
                  backActive={!this.isThisElementFirst()}
                  onBackPressed={() => this.handleElementOrderChange(-1)}
                  nextActive={!this.isThisElementLast()}
                  onNextPressed={() => this.handleElementOrderChange(1)}
                />
                <DeleteButton
                  disabled={requestedElement.order < 5}
                  onDelete={this.handleElementDelete}
                />
                <UploadPhotoButton
                  disabled={!requestedElement.imagePath || requestedElement.imagePath.length === 0}
                  onClick={this.handleElementClearImagePath}
                />
              </Panel.Footer>
            </Panel>
          </Col>
          <Col md={4}>
            <h4>Elements: </h4>
            <ElementChooser
              elements={newCarModel.elements}
              activeItem={requestedElement.order}
              addingAvailable={this.isCarModelValid()}
              onElementClicked={this.handleElementOrderClick}
              onAddElementClicked={this.handleAddElementClick}
            />
            {
              !isThisNewCarModel &&
                <Alert bsStyle='warning'>
                  Any changes to grid of the car model will result in clearing known issues for modified car model element. Clearing will occur at next user transfer.
                </Alert>
            }
          </Col>
        </Row>

        <Fade in={this.isCarModelValid() && carName.length > 0 && carBodyType.length > 0}>
          <FAB
            style={{ 'position': 'fixed', right: '24px', bottom: '24px' }}
            onClick={this.saveCarModel}
            title='Save car model'>
            <FabLoadableIcon isLoading={this.props.isLoading} />
          </FAB>
        </Fade>
      </Grid>
    )
  }
}

function mapStoreStateToProps ({ carModels }) {
  return carModels
}

function mapDispatchToProps (dispatch) {
  return getActions(dispatch)
}

export default connect(mapStoreStateToProps, mapDispatchToProps)(withApiUrl(CreateCarModel))
