const initState = {
  isLoading: true,
  users: [],
  outsideUsers: [],
  newUser: {
    username: '',
    role: 'user',
    password: '',
    passwordChangeAtNextLogin: false,
    meta: {
      groupId: ''
    },
    created: false,
    archived: false,
    error: ''
  },
  userDataBeforeEdit: {
    username: '',
    role: '',
    meta: {
      groupId: ''
    },
    passwordChangeAtNextLogin: false
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'USERS.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'USERS.ADD_USER':
      return {
        ...state,
        users: [...state.users, action.user]
      }
    case 'USERS.SET_USERS':
      return {
        ...state,
        users: action.users
      }
    case 'USERS.SET_OUTSIDE_USERS':
      return {
        ...state,
        outsideUsers: action.outsideUsers
      }
    case 'USERS.SET_NEW_USER':
      return {
        ...state,
        newUser: action.newUser
      }
    case 'USERS.SET_USER_DATA_BEFORE_EDIT':
      return {
        ...state,
        userDataBeforeEdit: action.userData
      }
    default: return state
  }
}

export default reducer
