export default function getActions (dispatch) {
  return {
    toggleMainMenu: () => {
      dispatch({ type: 'DASHBOARD.TOGGLE_MAIN_MENU' })
    },
    toggleUserMenu: (isShown) => {
      dispatch({
        type: 'DASHBOARD.CHANGE_USER_MENU_STATE',
        shouldBeOpened: !isShown
      })
    },
    hideUserMenu: () => {
      dispatch({
        type: 'DASHBOARD.CHANGE_USER_MENU_STATE',
        shouldBeOpened: false
      })
    },
    changeUserMenuAnimatingState: (isAnimating) => {
      dispatch({
        type: 'DASHBOARD.CHANGE_USER_MENU_ANIMATION_STATE',
        isAnimating
      })
    }
  }
}
