export default function getActions(dispatch) {
  return {
    updateLoadingState: (isLoading) => {
      dispatch(updateLoadingState(isLoading))
    },
    setAlerts: (alerts) => {
      dispatch(setAlerts(alerts))
    }

  }
}

export function updateLoadingState(isLoading) {
  return { type: 'ALERTS.SET_LOADING', isLoading }
}

export function setAlerts(alerts) {
  return {
    type: 'ALERTS.SET_ALERTS',
    alerts
  }
}

export function downloadAlerts() {
  return async (dispatch, getState, api) => {
    dispatch(updateLoadingState(true))
    const userResponse = await api.me()
    const groupId = userResponse.data.user.meta.groupId
    const userCars = userResponse.data.cars.map((car) => car._id)
    const carIds = userResponse.data.user.role === 'admin'
      ? [] // no car ids will make the backend to return all known alerts for all cars
      : (userCars.length > 0 ? userCars : ['-' /* '-' in the list will filter out all cars */])
    return api.getAlertsForCars({ carIds, groupId, date: new Date() })
      .then((response) => {
        // if not found or any other error occurred:
        if (response.error) {
          dispatch(updateLoadingState(false))
          return
        }

        dispatch(setAlerts(response.data))
        dispatch(updateLoadingState(false))
      })
  }
}
