import React from 'react'
import { ListGroup, ListGroupItem } from 'react-bootstrap'

export default function ({ cars, onCarClicked }) {
  if (!cars) return null

  return (
    cars.length > 0
      ? <ListGroup>
        {
          cars.map((car) =>
            (
              <ListGroupItem
                md={3}
                key={`${car.licensePlate}-${car._id}`}
                onClick={() => onCarClicked(car._id)}>
                { car.common && car.common.nick ? <i>{car.common.nick}</i> : car.model } {' '}
                <b>{ car.licensePlate }</b>
              </ListGroupItem>
            )
          )
        }
      </ListGroup>
      : <i>No cars owned</i>
  )
}
