const initState = {
  isLoading: true,
  questionnaires: [],
  newQuestionnaire: {
    // if id is 'new' a new questionnaire will be created
    // else it will become a new revision for questionnaire of given id
    id: 'new',
    title: '',
    questions: [{
      order: 1,
      content: '',
      answerType: 'string'
    }]
  }
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'QUESTIONNAIRES.SET_LOADING':
      return {
        ...state,
        isLoading: action.isLoading
      }
    case 'QUESTIONNAIRES.ADD_QUESTIONNAIRE':
      return {
        ...state,
        questionnaires: [...state.questionnaires, action.questionnaire]
      }
    case 'QUESTIONNAIRES.SET_QUESTIONNAIRES':
      return {
        ...state,
        questionnaires: action.questionnaires
      }
    case 'QUESTIONNAIRES.SET_NEW_QUESTIONNAIRE':
      return {
        ...state,
        newQuestionnaire: action.newQuestionnaire
      }
    default:
      return state
  }
}

export default reducer
