import React from 'react'
import { Button, Glyphicon } from 'react-bootstrap'

export default function (props) {
  const { disabled, onDelete } = props

  return (
    <Button
      title='Delete element'
      disabled={disabled}
      onClick={onDelete}
      className='pull-right'
      style={{ marginLeft: 8 }}>
      <Glyphicon glyph='trash' />
    </Button>
  )
}
